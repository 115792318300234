export const MAB_VHC_TRANSLATIONS_HR_HR = {
	MAB_VHC_ContactUs_Headline: "Kontaktirajte nas",
	MAB_VHC_ContactUs_HowCanWeHelp: "Kako vam možemo pomoći?",
	MAB_VHC_ContactUs_Email_Headline: "Pošalji e-mail!",
	MAB_VHC_ContactUs_Email_Description: "Pošaljite nam svoju poruku ispunjavanjem donjeg obrasca. Jedan od naših savjetnika rado će stupiti u kontakt s vama.",
	MAB_VHC_ContactUs_Email_InputName: "Ime*",
	MAB_VHC_ContactUs_Email_InputName_Required_Message: "Ime je obavezno.",
	MAB_VHC_ContactUs_Email_InputName_MaxLength_Message: "Naziv premašuje dopušteno ograničenje od 100 znakova.",
	MAB_VHC_ContactUs_Email_InputEmail: "E-mail adresa*",
	MAB_VHC_ContactUs_Email_InputEmail_Required_Message: "E-mail adresa je obavezna.",
	MAB_VHC_ContactUs_Email_InputEmail_MaxLength_Message: "Adresa e-pošte premašuje dopušteno ograničenje od 100 znakova.",
	MAB_VHC_ContactUs_Email_InputEmail_Invalid_Message: "Nevažeći format e-pošte.",
	MAB_VHC_ContactUs_Email_InputCompany: "Podaci o tvrtki*",
	MAB_VHC_ContactUs_Email_InputCompany_Required_Message: "Podaci o tvrtki su obavezni.",
	MAB_VHC_ContactUs_Email_InputCompany_MaxLength_Message: "Podaci o tvrtki premašuju dopušteno ograničenje od 100 znakova.",
	MAB_VHC_ContactUs_Email_InputAddress: "Adresa*",
	MAB_VHC_ContactUs_Email_InputAddress_Required_Message: "Adresa je obavezna.",
	MAB_VHC_ContactUs_Email_InputAddress_MaxLength_Message: "Adresa premašuje dopušteno ograničenje od 250 znakova.",
	MAB_VHC_ContactUs_Email_InputZipCode: "Poštanski broj*",
	MAB_VHC_ContactUs_Email_InputZipCode_Required_Message: "Poštanski broj je obavezan.",
	MAB_VHC_ContactUs_Email_InputZipCode_MaxLength_Message: "Poštanski broj premašuje dopušteno ograničenje od 25 znakova.",
	MAB_VHC_ContactUs_Email_InputCity: "Grad*",
	MAB_VHC_ContactUs_Email_InputCity_Required_Message: "Grad je obavezan.",
	MAB_VHC_ContactUs_Email_InputCity_MaxLength_Message: "Grad premašuje dopušteno ograničenje od 100 znakova.",
	MAB_VHC_ContactUs_Email_InputCountry: "Zemlja*",
	MAB_VHC_ContactUs_Email_InputCountry_Required_Message: "Država je obavezna.",
	MAB_VHC_ContactUs_Email_InputCountry_MaxLength_Message: "Država premašuje dopušteno ograničenje od 100 znakova.",
	MAB_VHC_ContactUs_Email_InputCertNo: "Certifikat / faktura br.*",
	MAB_VHC_ContactUs_Email_InputCertNo_Required_Message: "Certifikat / broj računa je obavezan.",
	MAB_VHC_ContactUs_Email_InputCertNo_MaxLength_Message: "Certifikat / Račun br. premašuje dopušteno ograničenje od 50 znakova.",
	MAB_VHC_ContactUs_Email_InputReason: "Razlog da nas kontaktirate*",
	MAB_VHC_ContactUs_Email_InputShortDescription: "Kratki opis*",
	MAB_VHC_ContactUs_Email_InputShortDescription_Required_Message: "Potreban je kratak opis.",
	MAB_VHC_ContactUs_Email_InputShortDescription_MaxLength_Message: "Kratki opis premašuje dopušteno ograničenje od 100 znakova.",
	MAB_VHC_ContactUs_Email_InputDescription: "Opis*",
	MAB_VHC_ContactUs_Email_InputDescription_Required_Message: "Potreban je opis.",
	MAB_VHC_ContactUs_Email_InputDescription_MaxLength_Message: "Opis premašuje dopušteno ograničenje od 1000 znakova.",
	MAB_VHC_ContactUs_Email_InputQuestion: "Pitanje*",
	MAB_VHC_ContactUs_Email_InputQuestion_Required_Message: "Pitanje je obavezno.",
	MAB_VHC_ContactUs_Email_InputQuestion_MaxLength_Message: "Pitanje premašuje dopušteno ograničenje od 100 znakova.",
	MAB_VHC_ContactUs_Email_Urgency: "Hitnost",
	MAB_VHC_ContactUs_Email_Urgency_Message: "Ne mogu nastaviti raditi.",
	MAB_VHC_ContactUs_Email_DataProtection: "Obavijest o zaštiti podataka*",
	MAB_VHC_ContactUs_Email_DataProtection_Description: "Pročitao sam <a href='{{data_protection_link}}' target='_blank'>Pravila o privatnosti.*</a>",
	MAB_VHC_ContactUs_Email_SecurityCheck: "Sigurnosna provjera",
	MAB_VHC_ContactUs_Email_SecurityCodeInvalid: "Captcha tekstualni odgovor koji ste unijeli nije točan.",
	MAB_VHC_ContactUs_Email_SecurityCodeConfirm: "Unesite ispravne znakove koje vidite u gornjem okviru.",
	MAB_VHC_ContactUs_Email_SuccessMessage: "Hvala što ste nas kontaktirali! Vaša poruka je poslana i mi ćemo vam se ubrzo javiti.",
	MAB_VHC_ContactUs_Submit_Error_Message: "Nismo mogli poslati e-poštu zbog problema. Provjerite jesu li pojedinosti točne i pokušajte ponovno.",
	MAB_VHC_ContactUs_Reasons_Incident: "Incident",
	MAB_VHC_ContactUs_Reasons_Bug: "Greška",
	MAB_VHC_ContactUs_Reasons_Question: "Pitanje",
	MAB_VHC_ContactUs_Reasons_FeedbackFeatureRequest: "Povratne informacije i zahtjev za značajku",
	MAB_VHC_ContactUs_Reasons_Incident_Form_Text: "Imate problema? Ili vas nešto sprječava da nastavite s radom? Molimo nemojte se ustručavati kontaktirati nas.",
	MAB_VHC_ContactUs_Reason_Incident_Topic: "Korak procesa na koji je utjecao incident*",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleIdentification: "Identifikacija vozila",
	VHC_CONTACT_INCIDENT_TOPIC_AccessToTheService: "Pristup usluzi",
	VHC_CONTACT_INCIDENT_TOPIC_StartingTheService: "Pokretanje usluge",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleDataReadout: "Očitavanje podataka o vozilu",
	VHC_CONTACT_INCIDENT_TOPIC_CheckoutAndInvoicing: "Blagajna i fakturiranje",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentPreview: "Pregled dokumenta",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentDelivery: "Dostava dokumenata",
	VHC_CONTACT_INCIDENT_TOPIC_Others: "Ostalo",
	MAB_VHC_ContactUs_Reasons_Bug_Form_Text: "Pronašli ste grešku? Slobodno je podijelite s nama.",
	MAB_VHC_ContactUs_Reason_Bug_Topic: "Korak procesa na koji je utjecao incident*",
	MAB_VHC_ContactUs_Reasons_Question_Form_Text: "Imate pitanje u vezi naše usluge? Vaše pitanje nije obuhvaćeno našim <a href='{{faq_link}}' target='_blank'>često postavljanim pitanjima</a>? Zatražite našu podršku.",
	MAB_VHC_ContactUs_Reason_Question_Topic: "Tema*",
	MAB_VHC_ContactUs_Reason_Question_Topic_ServiceContent: "Sadržaj usluge",
	MAB_VHC_ContactUs_Reason_Question_Topic_VehicleCoverage: "Pokrivenost vozila",
	MAB_VHC_ContactUs_Reason_Question_Topic_CountryCoverage: "Pokrivenost zemlje",
	MAB_VHC_ContactUs_Reason_Question_Topic_CheckoutProcess: "Proces naplate",
	MAB_VHC_ContactUs_Reason_Question_Topic_Pricing: "Cijene",
	MAB_VHC_ContactUs_Reason_Question_Topic_HardwareSoftware: "Hardver/softver",
	MAB_VHC_ContactUs_Reason_Question_Topic_Others: "Ostalo",
	MAB_VHC_ContactUs_Reasons_Feature_Form_Text: "Nedostaje vam određena značajka? Ili želite dati izravnu povratnu informaciju? Slobodno se obratite.",
	MAB_VHC_SubmitButton: "Pošalji",
	MAB_VHC_CloseButton: "Zatvori",
};
