import {
  ChatBubble,
  ChatBubbleContainer,
  formatDate,
  InfiniteList,
} from "amazon-chime-sdk-component-library-react";
import { useState } from "react";
import { createMemberArn, listChannelMessages, MessageType } from "../helpers/ChimeAPI";
import {
  ChannelMessagesState,
  channelSetIsTranslate,
  channelSetMessage,
  MessageState,
  selectApiKey,
  selectIsTranslate,
} from "../../redux/chatWidget";
import { useAppDispatch } from "../../redux/store";
import insertDateHeaders from "./insertDateHeaders";
import Attachment from "./Attachment";
import "./styles/message.css";
import { liveTranslateMessage } from "../../services/Chime";
import { useSelector } from "react-redux";
import { CommonService } from "../../services/Common";
import { useTranslation } from "@translationsContexts/i18nContext";
import { ChannelMessageSummary } from "aws-sdk/clients/chimesdkmessaging";

interface MessagesProps {
  messages: MessageState;
  channelArn: string;
  userId: string;
  translateModeData: any;
}

const Messages = ({
  messages,
  channelArn,
  userId,
  translateModeData,
}: MessagesProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const isTranslate = useSelector(selectIsTranslate);
  const dispatchReduxToolkit = useAppDispatch();
  const apiKey = useSelector(selectApiKey);
  const onTranslate = async (message: string) => {
    if (isTranslate.status) {
      dispatchReduxToolkit(
        channelSetIsTranslate({
          status: false,
          langSource: "auto",
          langTarget: "en",
          languagebox: false,
        })
      );
    } else {
      let messageDetectLanguage = await CommonService.detectLanguage(
        { text: message },
        apiKey
      );
      dispatchReduxToolkit(
        channelSetIsTranslate({
          status: true,
          langSource: messageDetectLanguage,
          langTarget: "en",
          languagebox: true,
        })
      );
    }
  };

  const handleScrollTop = async () => {
    setIsLoading(true);
    if (!messages?.nextToken) {
      setIsLoading(false);
      return;
    }

    const oldMessages = await listChannelMessages(
      channelArn,
      userId,
      messages.nextToken
    );
    if (oldMessages && oldMessages.Messages) {
      const message_translate = await Promise.all(
        oldMessages.Messages.map(async (message: any) => {
          message.CreatedTimestamp = message.CreatedTimestamp.toISOString();
          message.LastUpdatedTimestamp = message.LastUpdatedTimestamp.toISOString();
          if (
            createMemberArn(userId) !== message.Sender.Arn &&
            translateModeData.isTranslate
          ) {
            const translateMessage = await liveTranslateMessage(
              message.Content,
              translateModeData.langSource,
              translateModeData.langTarget,
              apiKey
            );
            return { ...message, Content: translateMessage };
          }
          return message;
        })
      );

      const newMessages = [
        ...message_translate,
        ...(messages?.channelMessages ? messages.channelMessages : []),
      ];

      dispatchReduxToolkit(
        channelSetMessage({
          channelMessages: newMessages,
          nextToken: oldMessages.NextToken,
        })
      );
    }
    setIsLoading(false);
  };

  const resize = (size: string) => {
    let size_num = Number(size);

    if (size_num < 1024) return `${size} bytes`;

    return size_num < 1024 * 1024
      ? `${String((size_num / 1024).toFixed(2))} KB`
      : `${String((size_num / 1024 / 1024).toFixed(2))} MB`;
  };

  const formatTime = (time: string) => {
    const t = new Date(time).toLocaleTimeString('en-US', { hour12: true });
    return t.slice(0, t.length - 6) + t.slice(t.length - 3, t.length);
  };

  const flattenedMessages = messages.channelMessages.filter((m: ChannelMessageSummary) => {
    return m.Type !== MessageType.CONTROL;
  }).map(
    (m: ChannelMessagesState) => {
      const content = !m.Content || m.Redacted ? "(Deleted)" : m.Content;
      let editedNote;
      if (m.LastEditedTimestamp && !m.Redacted) {
        const time = formatTime(m.LastEditedTimestamp);
        const date = formatDate(
          m.LastEditedTimestamp,
          undefined,
          undefined,
          "today",
          "yesterday"
        );
        editedNote = (
          <i
            style={{ fontStyle: "italic" }}
          >{` (edited ${date} at ${time})`}</i>
        );
      }

      const messageStatus = m.Status.Value == null ? "SENT" : m.Status.Value;
      let statusNote;
      if (messageStatus !== "SENT") {
        statusNote = (
          <i style={{ fontStyle: "italic" }}>{`     (${messageStatus})`}</i>
        );
      }

      return {
        content: content,
        editedNote: editedNote,
        messageId: m.MessageId,
        createdTimestamp: m.CreatedTimestamp,
        redacted: m.Redacted,
        senderName: m.Sender.Name,
        senderId: m.Sender.Arn,
        metadata: m.Metadata,
        status: m.Status.Value,
        statusNote: statusNote,
      };
    }
  );

  const listItems = insertDateHeaders(flattenedMessages);

  const messageList = listItems.map((m, i, self) => {
    if (!m.content) {
      return m;
    }

    let hasAttachment = false;
    let attachmentIsImage = false;

    let metadata: {
      isMeetingInfo: boolean;
      attachment: {
        fileKey: string;
        type: string;
        size: string;
        name: string;
      };
      status: string;
      meeting: {
        meetingId: string;
        pathRoute: string;
      };
      onStartVoiceCall: boolean;
    } = {
      isMeetingInfo: false,
      attachment: {
        fileKey: "",
        type: "",
        size: "",
        name: "",
      },
      status: "",
      meeting: {
        meetingId: "",
        pathRoute: "",
      },
      onStartVoiceCall: false,
    };

    if (m.metadata) {
      metadata = JSON.parse(m.metadata);
      if (metadata.isMeetingInfo) {
        return m;
      }
      if (metadata?.attachment) {
        hasAttachment = true;
        if (metadata?.attachment?.type.includes("image"))
          attachmentIsImage = true;
      }
    }

    const variant =
      createMemberArn(userId) === m.senderId ? "outgoing" : "incoming";

    const prevMessage = self[i - 1];
    const nextMessage = self[i + 1];

    let showName = true;
    let createdTimestamp = null;
    if (
      m.senderId &&
      prevMessage?.senderId &&
      m.senderId === prevMessage?.senderId
    ) {
      showName = false;
    }
    createdTimestamp = formatTime(m.createdTimestamp);
    if (
      m.senderId &&
      nextMessage?.senderId &&
      m.senderId === nextMessage?.senderId
    ) {
      if (
        Math.abs(
          new Date(nextMessage.createdTimestamp).getTime() -
          new Date(m.createdTimestamp).getTime()
        ) /
        1000 /
        60 >
        1
      ) {
        createdTimestamp = formatTime(m.createdTimestamp);
      } else {
        createdTimestamp = null;
      }
    }

    return (
      <div className="messages sc-message">
        {variant === "outgoing" ? (
          <>
            <ChatBubbleContainer
              timestamp={createdTimestamp}
              key={`message${i.toString()}`}
              css="
					  background-color: #fff;
					  margin: 0;
					  display: flex;
					  align-items: end;
					  flex-direction: column;
					  "
            >
              <ChatBubble
                variant={variant}
                css="border-radius: 0px;
						  background-color: var(--boschBlue50);
						  color: #fff;
						  "
              >
                <div>
                  {hasAttachment && (
                    <div style={{ display: "flex" }}>
                      <Attachment
                        fileKey={metadata?.attachment?.fileKey}
                        name={metadata?.attachment?.name}
                        size={resize(metadata?.attachment?.size)}
                        isImage={attachmentIsImage}
                      />
                    </div>
                  )}
                  {m.content}
                </div>
              </ChatBubble>
            </ChatBubbleContainer>
          </>
        ) : (
          <ChatBubbleContainer
            timestamp={<div className="message-time">{createdTimestamp}</div>}
            key={`message${i.toString()}`}
            css="margin: 1rem; 
						  background-color: #fff;
						  margin: 0;
						  display: flex;
						  align-items: start;
						  flex-direction: column;
						  "
          >
            {showName ? (
              <ChatBubble
                variant={variant}
                senderName={m.senderName}
                css="border-radius: 0px;
						  background-color: #e0e2e5;
						  color: rgba(0,0,0,.87);
						  "
              >
                <div>
                  {hasAttachment && (
                    <div style={{ display: "flex" }}>
                      <Attachment
                        fileKey={metadata?.attachment?.fileKey}
                        name={metadata?.attachment?.name}
                        size={resize(metadata?.attachment?.size)}
                        isImage={attachmentIsImage}
                      />
                    </div>
                  )}
                  {m.content}
                  {metadata?.meeting?.meetingId && (
                    <a
                      style={{
                        cursor: "pointer",
                        fontStyle: "italic",
                        textDecorationLine: "underline",
                      }}
                      href={
                        metadata?.meeting?.pathRoute +
                        metadata?.meeting.meetingId +
                        `/?user_id=${userId}` +
                        `${metadata?.onStartVoiceCall ? "&mtype=voice" : ""}`
                      }
                      target={"_blank"}
                    >
                      Link
                    </a>
                  )}
                  {
                    <>
                      <br />
                      <br />
                      <a
                        style={{
                          cursor: "pointer",
                          textDecorationLine: "none",
                          marginTop: "5px",
                        }}
                        target={"_blank"}
                        onClick={() => onTranslate(m.content)}
                      >
                        {isTranslate.status
                          ? t("Chat_dis_translation")
                          : t("Chat_en_translation")}
                      </a>
                    </>
                  }
                </div>
              </ChatBubble>
            ) : (
              <ChatBubble
                variant={variant}
                css="border-radius: 0px;
						  background-color: #e0e2e5;
						  color: rgba(0,0,0,.87);
						  "
              >
                <div>
                  {hasAttachment && (
                    <div style={{ display: "flex" }}>
                      <Attachment
                        fileKey={metadata?.attachment?.fileKey}
                        name={metadata?.attachment?.name}
                        size={resize(metadata?.attachment?.size)}
                        isImage={attachmentIsImage}
                      />
                    </div>
                  )}
                  {m.content}
                  {metadata?.meeting?.meetingId && (
                    <a
                      style={{
                        cursor: "pointer",
                        fontStyle: "italic",
                        textDecorationLine: "underline",
                      }}
                      href={
                        metadata?.meeting?.pathRoute +
                        metadata?.meeting.meetingId +
                        `/?user_id=${userId}` +
                        `${metadata?.onStartVoiceCall ? "&mtype=voice" : ""}`
                      }
                      target={"_blank"}
                    >
                      Link
                    </a>
                  )}
                  {
                    <>
                      <br />
                      <br />
                      <a
                        style={{
                          cursor: "pointer",
                          textDecorationLine: "none",
                          marginTop: "5px",
                        }}
                        target={"_blank"}
                        onClick={() => onTranslate(m.content)}
                      >
                        {isTranslate.status
                          ? t("Chat_dis_translation")
                          : t("Chat_en_translation")}
                      </a>
                    </>
                  }
                </div>
              </ChatBubble>
            )}
          </ChatBubbleContainer>
        )}
      </div>
    );
  });

  return (
    <div className="sc-message-list">
      {messages.channelMessages.length > 0 && (
        <InfiniteList
          style={{ overflow: "hidden", backgroundColor: "#fff", margin: "0px" }}
          items={messageList}
          onLoad={handleScrollTop}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default Messages;
