import { FC, Fragment } from "react";
import { useTranslation } from "@translationsContexts/i18nContext";
import { Grid, Modal } from "semantic-ui-react";

import "./BrowserVersionNotSupport.css";

interface BrowserVersionNotSupportProps {
  //isDisplayModal: boolean;
}

const BrowserVersionNotSupport: FC<BrowserVersionNotSupportProps> = (
  props: BrowserVersionNotSupportProps
) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Modal
        dimmer={{ className: `incompatiblebrowser-modal error` }}
        open
        size={"tiny"}
      >
        {/* <Modal.Header>
          <Boschicon name="bosch-ic-alert-error" />
          {t("BrowserVersionNotSupport_PrimaryMessage")}
        </Modal.Header> */}
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <p>
                <strong>{t("BrowserVersionNotSupport_PrimaryMessage")}</strong>
              </p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t(
                    "BrowserVersionNotSupport_SecondaryMessage"
                  ).replace(
                    "www.microsoft.com",
                    "<a href='https://www.microsoft.com/' target='_blank'>www.microsoft.com</a>"
                  ),
                }}
              ></p>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        {/* <Modal.Actions>
          <Button basic color="blue"
              onClick={closeBrowserVersionNotSupport}>
              {t("CommonButton_Close")}
            </Button>
        </Modal.Actions> */}
      </Modal>
    </Fragment>
  );
};

export default BrowserVersionNotSupport;
