import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "@translationsContexts/i18nContext";
import { Link } from "react-router-dom";
import { Grid, Image, Input } from "semantic-ui-react";
import Styles from "./ShoppingCartDetailCheckoutInfo.module.css";
import { useCartSubscription } from "../../../../../hooks/useCartSubscription";
import { useSelector } from "react-redux";
import {
  addAddOnToCart,
  executeCheckVoucher,
  removeAddOnFromCart,
  selectCartQuantity,
  selectIsRefillingCredits,
  selectPaymentFrequencySelected,
  selectShoppingCart,
  selectShoppingCartItems,
  setRefillingCredits,
} from "../../../../../redux/shoppingCartV3Slice";
import { selectCountryCode } from "../../../../../redux/auth";
import { useAppDispatch } from "../../../../../redux/hooks";
import { useShoppingCartItemPrice } from "../../../../../hooks/useShoppingCartItemPrice";
import { useCurrencyFormat } from "../../../../../hooks/useCurrencyFormat";
import Boschicon from "../../../../BoschIcon/Boschicon";
import {
  CartItemPrice,
  ShoppingCartItem,
} from "../../../../../models/ShoppingCart/ShoppingCartItem";
import { useIsMonthlyPayment } from "../../../../../hooks/useIsMonthlyPayment";
import { GetPaymentMethodsForCountryAsync } from "../../../../../redux/paymentAdyenSlide";
import CustomLoader from "../../../../loading/CustomLoader";
import CheckoutContentTotalPrice from "./CheckoutContentTotalPrice";
import CheckoutContentVoucherInfo from "./CheckoutContentVoucherInfo";
import CheckoutContentPaymentMethods from "./CheckoutContentPaymentMethods";
import { useProceedNegativeCreditBalance } from "../../../../../hooks/useProceedNegativeCreditBalance";

interface ShoppingCartDetailCheckoutContentProps {
  isEditable: boolean;
  showEditShoppingCartLink: boolean;
  hidePaymentMethods?: boolean;
  hideApplyVoucher?: boolean;
  hideRemoveButton?: boolean;
}

const ShoppingCartDetailCheckoutContent: FC<
  ShoppingCartDetailCheckoutContentProps
> = ({
  isEditable,
  showEditShoppingCartLink,
  hidePaymentMethods,
  hideApplyVoucher,
  hideRemoveButton,
}) => {
  const { t } = useTranslation();
  const dispatchReduxToolkit = useAppDispatch();
  const countryCode = useSelector(selectCountryCode);
  const cartQuantity = useSelector(selectCartQuantity);
  const shoppingCartItems = useSelector(selectShoppingCartItems);
  const {
    cartSubscription,
    mandatoryAddOns,
    subscriptionShoppingCartItem,
    isPrepaidVoucher,
  } = useCartSubscription();
  const shoppingCart = useSelector(selectShoppingCart);
  const paymentFrequencySelected = useSelector(selectPaymentFrequencySelected);
  const format = useCurrencyFormat();
  const {
    getSubscriptionPrice,
    getSubscriptionMonthlyPrice,
    getSubscriptionOriginalPrice,
    getSubscriptionOriginalMonthlyPrice,
    calculatePriceByQuantity,
  } = useShoppingCartItemPrice();
  const isMonthlyPayment = useIsMonthlyPayment();
  const { isNegativeBalance } = useProceedNegativeCreditBalance();
  const [showLoader, setShowLoader] = useState(false);
  const isRefillingCredits = useSelector(selectIsRefillingCredits);

  const onRemoveItem = (cartItem: ShoppingCartItem) => {
    dispatchReduxToolkit(setRefillingCredits(false));
    dispatchReduxToolkit(removeAddOnFromCart(cartItem.addOnId || 0));
    if (!!shoppingCart.voucherCode) {
      dispatchReduxToolkit(executeCheckVoucher());
    }
  };

  const onQuantityChanged = (item: ShoppingCartItem, quantity: number) => {
    if (!item.addOnItem?.isMultitplyByWorkShop && quantity > 0) {
      let originalPrice = item.originalPrice;
      if (!!originalPrice) {
        originalPrice = {
          mPrice: calculatePriceByQuantity(originalPrice.mPrice, quantity),
          yPrice: calculatePriceByQuantity(originalPrice.yPrice, quantity),
        };
      }
      dispatchReduxToolkit(
        addAddOnToCart({
          ...item,
          quantity: quantity,
          netPrice: {
            mPrice: calculatePriceByQuantity(item.netPrice.mPrice, quantity),
            yPrice: calculatePriceByQuantity(item.netPrice.yPrice, quantity),
          },
          originalPrice: originalPrice,
        })
      );

      if (!!item.voucherCode) {
        dispatchReduxToolkit(executeCheckVoucher());
      }
    }
  };

  const getCartPrice = (cartItem: ShoppingCartItem) => {
    if (cartItem) {
      return getYearlyOrMonthlyTag(cartItem);
    }
    return <></>;
  };

  const getYearlyOrMonthlyTag = (cartItem: ShoppingCartItem) => {
    const voucherCode = cartItem.voucherCode || "";
    const netPrice = cartItem.netPrice;
    const originalPrice = cartItem.originalPrice;
    const isAddOn = !cartItem?.subscriptionId;

    if (isMonthlyPayment(paymentFrequencySelected)) {
      if (isAddOn) {
        if (cartItem?.addOnItem?.allowMonthlyPayment) {
          return getPriceTag(
            voucherCode,
            netPrice.mPrice,
            originalPrice.mPrice,
            isAddOn
          );
        }
        return getPriceTag(
          voucherCode,
          netPrice.yPrice,
          originalPrice.yPrice,
          isAddOn
        );
      }
      return getPriceTag(
        voucherCode,
        netPrice.mPrice,
        originalPrice.mPrice,
        isAddOn
      );
    }
    return getPriceTag(
      voucherCode,
      netPrice.yPrice,
      originalPrice.yPrice,
      isAddOn
    );
  };

  const getPriceTag = (
    voucherCode: string,
    netPrice: CartItemPrice,
    originalPrice: CartItemPrice | null,
    isAddOn: boolean = false
  ) => {
    return (
      <>
        {!!voucherCode &&
        (!!shoppingCart?.validVoucher?.isCashRebate || isAddOn) ? (
          <>
            {format(
              netPrice.netPrice,
              netPrice.currency?.symbol,
              countryCode
            ) !==
              format(
                netPrice.voucherAppliedPrice,
                netPrice.currency?.symbol || "",
                countryCode
              ) && (
              <span className={Styles.discount_price}>
                {format(
                  netPrice.netPrice,
                  netPrice.currency?.symbol,
                  countryCode
                )}
              </span>
            )}
            <span>
              {format(
                netPrice.voucherAppliedPrice,
                netPrice.currency?.symbol || "",
                countryCode
              )}
            </span>
          </>
        ) : (
          <>
            {originalPrice && originalPrice.netPrice !== netPrice.netPrice && (
              <span className={Styles.discount_price}>
                {format(
                  originalPrice.netPrice,
                  originalPrice.currency?.symbol,
                  countryCode
                )}
              </span>
            )}
            <span>
              {format(
                netPrice.netPrice,
                netPrice.currency?.symbol || "",
                countryCode
              )}
            </span>
          </>
        )}
      </>
    );
  };

  const getSubscriptionPriceMonthlyYearlyTag = (
    subscriptionCartItem: ShoppingCartItem
  ) => {
    if (!subscriptionCartItem) {
      return <></>;
    }

    if (isMonthlyPayment(paymentFrequencySelected)) {
      return getSubscriptionPriceMonthlyTag(
        subscriptionCartItem.voucherCode || "",
        subscriptionCartItem.netPrice.mPrice,
        subscriptionCartItem.originalPrice.mPrice
      );
    }

    return getSubscriptionPriceYearlyTag(
      subscriptionCartItem.netPrice.yPrice,
      subscriptionCartItem.originalPrice.yPrice
    );
  };

  const getSubscriptionPriceMonthlyTag = (
    voucherCode: string,
    netPrice: CartItemPrice,
    originalPrice?: CartItemPrice
  ) => {
    return (
      <>
        {voucherCode && (
          <>
            <span className={Styles.discount_price}>
              {getSubscriptionMonthlyPrice(netPrice, "")}
            </span>
            <span>{getSubscriptionMonthlyPrice(netPrice, voucherCode)}</span>
          </>
        )}

        {!voucherCode && (
          <>
            {originalPrice &&
              getSubscriptionMonthlyPrice(netPrice, "") !==
                getSubscriptionOriginalMonthlyPrice(originalPrice) && (
                <span className={Styles.discount_price}>
                  {getSubscriptionMonthlyPrice(originalPrice, "")}
                </span>
              )}

            <span>{getSubscriptionMonthlyPrice(netPrice, "")}</span>
          </>
        )}
      </>
    );
  };

  const getSubscriptionPriceYearlyTag = (
    netPrice: CartItemPrice,
    originalPrice?: CartItemPrice
  ) => {
    const netPriceText = getSubscriptionPrice(netPrice, "");
    const originalPriceText = !!originalPrice
      ? getSubscriptionOriginalPrice(originalPrice)
      : "";
    return (
      <>
        <>
          {originalPrice && netPriceText !== originalPriceText && (
            <span className={Styles.discount_price}>{originalPriceText}</span>
          )}

          <span>{netPriceText}</span>
        </>
      </>
    );
  };

  const OrderedAddOns = shoppingCartItems
    .filter((x) => !!x.addOnId && x.addOnItem && !x.addOnItem.isMandatory)
    .map((item) => {
      const addOn = item.addOnItem;
      if (!!addOn) {
        return (
          <div className={Styles.content_row}>
            <div className={Styles.order_item}>
              <div className={Styles.order_item_name}>
                {addOn.image && (
                  <Image
                    src={
                      require(`../../../../../assets/icon/subscription_addon_icons/${addOn.image}`)
                        .default
                    }
                    className={Styles.addon_icon}
                  />
                )}
                <div className={Styles.product_name}>{addOn.title}</div>
              </div>
              <Input
                className={Styles.quantity_input}
                value={item.quantity}
                size="mini"
                type="text"
                readOnly={
                  isRefillingCredits ||
                  !isEditable ||
                  !!item.addOnItem?.isMultitplyByWorkShop
                }
                onChange={(e, data) => {
                  onQuantityChanged(item, +data.value);
                }}
              />
            </div>
            <div className={Styles.field_value}>
              {getCartPrice(item)}
              {isEditable && !hideRemoveButton && !item.voucherCode && (
                <Link
                  to="#"
                  className={Styles.remove_button}
                  onClick={(e) => {
                    e.preventDefault();
                    onRemoveItem(item);
                  }}
                >
                  <Boschicon name="bosch-delete" />
                </Link>
              )}
            </div>
          </div>
        );
      }
      return <></>;
    });

  const OrderedSingleCredit = shoppingCartItems
    .filter((x) => !!x.voucherServiceCreditItem)
    .map((item) => {
      const voucherServiceCreditItem = item.voucherServiceCreditItem;
      if (!!voucherServiceCreditItem) {
        return (
          <div className={Styles.content_row}>
            <div className={Styles.order_item}>
              <div className={Styles.order_item_name}>
                <Boschicon name="bosch-ic-coin" />
                <div className={Styles.product_name}>
                  {`${voucherServiceCreditItem.title}`}
                  <ul className={Styles.add_on_list}>
                    <li>{`${voucherServiceCreditItem.partNumber}`}</li>
                  </ul>
                </div>
              </div>
              <Input
                className={Styles.quantity_input}
                value={item.quantity}
                size="mini"
                type="text"
                readOnly
                onChange={(e, data) => {
                  onQuantityChanged(item, +data.value);
                }}
              />
            </div>
            <div className={Styles.field_value}>
              {getCartPrice(item)}
              {isEditable && !hideRemoveButton && !item.voucherCode && (
                <Link
                  to="#"
                  className={Styles.remove_button}
                  onClick={(e) => {
                    e.preventDefault();
                    onRemoveItem(item);
                  }}
                >
                  <Boschicon name="bosch-delete" />
                </Link>
              )}
            </div>
          </div>
        );
      }
      return <></>;
    });

  const OrderedServiceType = shoppingCartItems
    .filter((x) => !!x.serviceTypeId && !!x.serviceTypeObject)
    .map((item) => {
      const serviceType = item.serviceTypeObject;
      if (!!serviceType) {
        return (
          <div className={Styles.content_row}>
            <div className={Styles.order_item}>
              <div className={Styles.order_item_name}>
                <Boschicon name="bosch-ic-car-connected" />
                <div className={Styles.product_name}>
                  {`${serviceType.serviceTypeLocalized}`}
                  <ul className={Styles.add_on_list}>
                    <li>{`${serviceType.partNumber}`}</li>
                  </ul>
                </div>
              </div>
              <Input
                className={Styles.quantity_input}
                value={item.quantity}
                size="mini"
                type="text"
                readOnly={
                  isRefillingCredits ||
                  !isEditable ||
                  !!item.addOnItem?.isMultitplyByWorkShop
                }
                onChange={(e, data) => {
                  onQuantityChanged(item, +data.value);
                }}
              />
            </div>
            <div className={Styles.field_value}>
              {getCartPrice(item)}
              {isEditable && !hideRemoveButton && (
                <Link
                  to="#"
                  className={Styles.remove_button}
                  onClick={(e) => {
                    e.preventDefault();
                    onRemoveItem(item);
                  }}
                >
                  <Boschicon name="bosch-delete" />
                </Link>
              )}
            </div>
          </div>
        );
      }
      return <></>;
    });

  useEffect(() => {
    setShowLoader(true);
    dispatchReduxToolkit(GetPaymentMethodsForCountryAsync()).finally(() => {
      setShowLoader(false);
    });
  }, []);

  return (
    <Fragment>
      <Grid.Row className={Styles.row}>
        <Grid.Column className={Styles.column} width={16}>
          <div className={Styles.order_summary_contents}>
            <div className={`${Styles.content_row} ${Styles.no_border}`}>
              <span className={Styles.title}>
                {t("ShoppingCartDetailCheckoutInfo_OrderSummary_Title")}
              </span>
              {showEditShoppingCartLink && !isNegativeBalance() && (
                <div className={Styles.field_value}>
                  <Link to="/v3/shopping-cart">
                    {t("CheckOutCDM3RightPanel_Edit_Shopping_Cart")}​
                  </Link>
                </div>
              )}
            </div>
            {cartSubscription && (
              <div className={`${Styles.content_row} ${Styles.no_border}`}>
                <div className={Styles.order_item}>
                  <div className={Styles.order_item_name}>
                    {cartSubscription.logo && (
                      <Image
                        src={
                          require(`../../../../../assets/icon/subscription_addon_icons/${cartSubscription.logo}`)
                            .default
                        }
                        className={Styles.addon_icon}
                      />
                    )}
                    <div className={Styles.product_name}>
                      {cartSubscription?.title}
                      <ul className={Styles.add_on_list}>
                        {mandatoryAddOns
                          ?.filter(
                            (x) =>
                              !shoppingCart.voucherCode ||
                              !!shoppingCartItems?.find(
                                (y) => x.id === y.addOnId
                              )
                          )
                          .map((addOn) => {
                            let addOnQuantity = 1;
                            if (addOn.isMultitplyByWorkShop) {
                              addOnQuantity = cartQuantity;
                            }
                            return (
                              <li>{`${addOnQuantity} x ${addOn.title}`}</li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
                {subscriptionShoppingCartItem && (
                  <div className={Styles.field_value}>
                    {getSubscriptionPriceMonthlyYearlyTag(
                      subscriptionShoppingCartItem
                    )}
                  </div>
                )}
              </div>
            )}
            {OrderedAddOns}
            {OrderedSingleCredit}
            {OrderedServiceType}
            <CheckoutContentTotalPrice />
          </div>
        </Grid.Column>
      </Grid.Row>
      <CheckoutContentVoucherInfo
        isEditable={isEditable}
        hideApplyVoucher={hideApplyVoucher}
      />
      {!isPrepaidVoucher && (
        <CheckoutContentPaymentMethods
          hidePaymentMethods={hidePaymentMethods}
        />
      )}
      {showLoader && <CustomLoader enable />}
    </Fragment>
  );
};

export default ShoppingCartDetailCheckoutContent;
