export const MAB_VHC_TRANSLATIONS_PT_PT = {
	MAB_VHC_ContactUs_Headline: "Contate-nos",
	MAB_VHC_ContactUs_HowCanWeHelp: "Como podemos ajudá-lo?",
	MAB_VHC_ContactUs_Email_Headline: "Envie um e-mail!",
	MAB_VHC_ContactUs_Email_Description: "Envie-nos a sua mensagem preenchendo o formulário abaixo. Um de nossos consultores terá prazer em entrar em contato com você.",
	MAB_VHC_ContactUs_Email_InputName: "Nome*",
	MAB_VHC_ContactUs_Email_InputName_Required_Message: "O nome é obrigatório.",
	MAB_VHC_ContactUs_Email_InputName_MaxLength_Message: "O nome excede o limite permitido de 100 caracteres.",
	MAB_VHC_ContactUs_Email_InputEmail: "Endereço de e-mail*",
	MAB_VHC_ContactUs_Email_InputEmail_Required_Message: "O endereço de e-mail é obrigatório.",
	MAB_VHC_ContactUs_Email_InputEmail_MaxLength_Message: "O endereço de e-mail excede o limite permitido de 100 caracteres.",
	MAB_VHC_ContactUs_Email_InputEmail_Invalid_Message: "Formato de e-mail inválido.",
	MAB_VHC_ContactUs_Email_InputCompany: "Informações da empresa*",
	MAB_VHC_ContactUs_Email_InputCompany_Required_Message: "São necessárias informações da empresa.",
	MAB_VHC_ContactUs_Email_InputCompany_MaxLength_Message: "As informações da empresa excedem o limite permitido de 100 caracteres.",
	MAB_VHC_ContactUs_Email_InputAddress: "Morada*",
	MAB_VHC_ContactUs_Email_InputAddress_Required_Message: "A morada é obrigatória.",
	MAB_VHC_ContactUs_Email_InputAddress_MaxLength_Message: "A morada excede o limite permitido de 250 caracteres.",
	MAB_VHC_ContactUs_Email_InputZipCode: "Código postal*",
	MAB_VHC_ContactUs_Email_InputZipCode_Required_Message: "O código postal é obrigatório.",
	MAB_VHC_ContactUs_Email_InputZipCode_MaxLength_Message: "O código postal excede o limite permitido de 25 caracteres.",
	MAB_VHC_ContactUs_Email_InputCity: "Cidade*",
	MAB_VHC_ContactUs_Email_InputCity_Required_Message: "A cidade é obrigatória.",
	MAB_VHC_ContactUs_Email_InputCity_MaxLength_Message: "A cidade excede o limite permitido de 100 caracteres.",
	MAB_VHC_ContactUs_Email_InputCountry: "País*",
	MAB_VHC_ContactUs_Email_InputCountry_Required_Message: "O país é obrigatório.",
	MAB_VHC_ContactUs_Email_InputCountry_MaxLength_Message: "O país excede o limite permitido de 100 caracteres.",
	MAB_VHC_ContactUs_Email_InputCertNo: "Número de Certificado / Fatura*",
	MAB_VHC_ContactUs_Email_InputCertNo_Required_Message: "Número de Certificado / Fatura é necessário.",
	MAB_VHC_ContactUs_Email_InputCertNo_MaxLength_Message: "Número de Certificado / Fatura excede o limite permitido de 50 caracteres.",
	MAB_VHC_ContactUs_Email_InputReason: "Motivo do contato conosco*",
	MAB_VHC_ContactUs_Email_InputShortDescription: "Breve descrição*",
	MAB_VHC_ContactUs_Email_InputShortDescription_Required_Message: "É necessária uma breve descrição.",
	MAB_VHC_ContactUs_Email_InputShortDescription_MaxLength_Message: "A breve descrição excede o limite permitido de 100 caracteres.",
	MAB_VHC_ContactUs_Email_InputDescription: "Descrição*",
	MAB_VHC_ContactUs_Email_InputDescription_Required_Message: "A descrição é obrigatória.",
	MAB_VHC_ContactUs_Email_InputDescription_MaxLength_Message: "A descrição excede o limite permitido de 1.000 caracteres.",
	MAB_VHC_ContactUs_Email_InputQuestion: "Pergunta*",
	MAB_VHC_ContactUs_Email_InputQuestion_Required_Message: "A pergunta é obrigatória.",
	MAB_VHC_ContactUs_Email_InputQuestion_MaxLength_Message: "A pergunta excede o limite permitido de 100 caracteres.",
	MAB_VHC_ContactUs_Email_Urgency: "Urgência",
	MAB_VHC_ContactUs_Email_Urgency_Message: "Não posso continuar trabalhando.",
	MAB_VHC_ContactUs_Email_DataProtection: "Aviso de proteção de dados*",
	MAB_VHC_ContactUs_Email_DataProtection_Description: "Li a <a href='{{data_protection_link}}' target='_blank'>Política de Privacidade.*</a>",
	MAB_VHC_ContactUs_Email_SecurityCheck: "Verificação de segurança",
	MAB_VHC_ContactUs_Email_SecurityCodeInvalid: "A resposta de texto captcha que você digitou está incorreta.",
	MAB_VHC_ContactUs_Email_SecurityCodeConfirm: "Por favor, insira os caracteres corretos que você vê na caixa acima.",
	MAB_VHC_ContactUs_Email_SuccessMessage: "Obrigado por nos contactar! A sua mensagem foi enviada e entraremos em contacto consigo em breve.",
	MAB_VHC_ContactUs_Submit_Error_Message: "Não foi possível enviar o e-mail devido a um problema. Certifique-se de que os detalhes estão corretos e tente novamente.",
	MAB_VHC_ContactUs_Reasons_Incident: "Incidente",
	MAB_VHC_ContactUs_Reasons_Bug: "Erro de software",
	MAB_VHC_ContactUs_Reasons_Question: "Pergunta",
	MAB_VHC_ContactUs_Reasons_FeedbackFeatureRequest: "Feedback e solicitação de recursos",
	MAB_VHC_ContactUs_Reasons_Incident_Form_Text: "Está a ter problemas? Ou há algo que o está a impedir de continuar a trabalhar? Por favor, não hesite em contatar-nos.",
	MAB_VHC_ContactUs_Reason_Incident_Topic: "Etapa do processo afetada pelo incidente*",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleIdentification: "Identificação do veículo",
	VHC_CONTACT_INCIDENT_TOPIC_AccessToTheService: "Acesso ao serviço",
	VHC_CONTACT_INCIDENT_TOPIC_StartingTheService: "Iniciando o serviço",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleDataReadout: "Leitura de dados do veículo",
	VHC_CONTACT_INCIDENT_TOPIC_CheckoutAndInvoicing: "Pagamento e faturação",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentPreview: "Visualização do documento",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentDelivery: "Entrega do documento",
	VHC_CONTACT_INCIDENT_TOPIC_Others: "Outros",
	MAB_VHC_ContactUs_Reasons_Bug_Form_Text: "Encontrou um erro? Fique à vontade para compartilhar conosco.",
	MAB_VHC_ContactUs_Reason_Bug_Topic: "Etapa do processo afetada pelo incidente*",
	MAB_VHC_ContactUs_Reasons_Question_Form_Text: "Tem alguma dúvida sobre o nosso serviço? A sua pergunta não foi abordada nas nossas <a href='{{faq_link}}' target='_blank'>Perguntas frequentes</a>? Peça o nosso suporte.",
	MAB_VHC_ContactUs_Reason_Question_Topic: "Tópico*",
	MAB_VHC_ContactUs_Reason_Question_Topic_ServiceContent: "Conteúdo do serviço",
	MAB_VHC_ContactUs_Reason_Question_Topic_VehicleCoverage: "Cobertura de veículos",
	MAB_VHC_ContactUs_Reason_Question_Topic_CountryCoverage: "Cobertura de países",
	MAB_VHC_ContactUs_Reason_Question_Topic_CheckoutProcess: "Processo de pagamento",
	MAB_VHC_ContactUs_Reason_Question_Topic_Pricing: "Preços",
	MAB_VHC_ContactUs_Reason_Question_Topic_HardwareSoftware: "Hardware / software",
	MAB_VHC_ContactUs_Reason_Question_Topic_Others: "Outros",
	MAB_VHC_ContactUs_Reasons_Feature_Form_Text: "Falta-lhe uma determinada funcionalidade? Ou quer dar um feedback direto? Não hesite em contatar-nos.",
	MAB_VHC_SubmitButton: "Enviar",
	MAB_VHC_CloseButton: "Fechar",
};
