import { FC } from "react";
import { useTranslation } from "@translationsContexts/i18nContext";

const LaunchLinkRedirect: FC<any> = (props) => {
  const { t } = useTranslation();

  return (
    <div style={{ paddingTop: "15%", textAlign: "center" }}>
      {t("MyELearning_VideoPlayerClosingMessage")}
    </div>
  );
};

export default LaunchLinkRedirect;
