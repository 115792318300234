import { useState } from "react";
import "../../pages/SettingsMyHardware.css";
import { Button, Form, Modal } from "semantic-ui-react";
import { useTranslation } from "@translationsContexts/i18nContext";
import { useAppSelector } from "../../redux/hooks";
import { selectAccount } from "../../redux/account";
import { selectCountryCode, selectLanguage } from "../../redux/auth";
import { useSelector } from "react-redux";

type Props = {
  selectedHardwareId: number | undefined;
  selectedReason: number | undefined;
  setSelectedReason: React.Dispatch<React.SetStateAction<number | undefined>>;
  setClaimModalStatus: React.Dispatch<any>;
  isOpenClaimModalStatus: boolean;
  reasonList: any;
};

const ClaimModal = ({
  selectedHardwareId,
  selectedReason,
  setSelectedReason,
  setClaimModalStatus,
  isOpenClaimModalStatus,
  reasonList,
}: Props) => {
  const { t } = useTranslation();
  const currentAccount = useAppSelector(selectAccount);
  const countryCode = useSelector(selectCountryCode);
  const language = useSelector(selectLanguage);
  const [description, setDescription] = useState<string>("");

  const handleReasonSelection = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: any
  ) => {
    setSelectedReason(data.value);
  };

  const handleDescriptionChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: any
  ) => {
    setDescription(data.value);
  };

  const formsAreValid = () => {
    if (!selectedReason) {
      return false;
    }

    return true;
  };

  const handleClaimSubmission = () => {
    const claimInfo = {
      ciamId: currentAccount?.ciamId,
      countryCode: countryCode,
      language: language,
      hardWareId: selectedHardwareId,
      faultCodeId: selectedReason,
      repairNote: description,
    };

    setClaimModalStatus({
      status: false,
      data: claimInfo,
    });
  };

  return (
    <Modal
      // onUnmount={() => { }}
      // onMount={() => { }}
      open={isOpenClaimModalStatus}
      dimmer={{ className: "test-dimmer myhardware-modal" }}
      size={"mini"}
      //closeOnDimmerClick={false}
      onClose={() =>
        setClaimModalStatus({
          isOpened: false,
        })
      }
    >
      {/* <div className="common-modal-header">
          <div className="common-modal-header-text"></div>
          <div className="close-button-container">
            <Close
              className="close-button"
              onClick={() =>
                setClaimModalStatus({
                  isOpened: false,
                })
              }
            />
          </div>
        </div> */}

      <div className="common-mini-modal-content">
        <div className="common-modal-content-section">
          <Form>
            <Form.Dropdown
              placeholder={
                reasonList
                  ? t("SettingsMyHardware_ClaimModal_ReasonDropdownPlaceholder")
                  : ""
              }
              loading={!reasonList ? true : false}
              fluid
              scrolling
              selection
              options={reasonList ? reasonList : []}
              onChange={handleReasonSelection}
              value={selectedReason}
              error={
                !selectedReason &&
                t("SettingsMyHardware_ClaimModal_ReasonValidation")
              }
            />
          </Form>
        </div>
        <div className="common-modal-content-section">
          <Form>
            <Form.TextArea
              placeholder={t(
                "SettingsMyHardware_ClaimModal_DescriptionPlaceholder"
              )}
              rows={5}
              onChange={handleDescriptionChange}
              value={description}
            />
          </Form>
        </div>
      </div>

      <div className="common-modal-single-action">
        <Button
          className="primary-button"
          onClick={handleClaimSubmission}
          disabled={!formsAreValid()}
        >
          {t("SettingsMyHardware_ClaimModal_SubmitButton")}
        </Button>
      </div>
    </Modal>
  );
};

export default ClaimModal;
