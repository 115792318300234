export const MAB_VHC_TRANSLATIONS_EN = {
	MAB_VHC_ContactUs_Headline: "Contact us",
	MAB_VHC_ContactUs_HowCanWeHelp: "How can we help you?",
	MAB_VHC_ContactUs_Email_Headline: "Send an e-mail!",
	MAB_VHC_ContactUs_Email_Description: "Send us your message by filling out the form below. One of our advisors will be happy to get in touch with you.",
	MAB_VHC_ContactUs_Email_InputName: "Name*",
	MAB_VHC_ContactUs_Email_InputName_Required_Message: "Name is required.",
	MAB_VHC_ContactUs_Email_InputName_MaxLength_Message: "Name exceeds the allowed limit of 100 characters.",
	MAB_VHC_ContactUs_Email_InputEmail: "E-Mail address*",
	MAB_VHC_ContactUs_Email_InputEmail_Required_Message: "E-Mail address is required.",
	MAB_VHC_ContactUs_Email_InputEmail_MaxLength_Message: "E-Mail address exceeds the allowed limit of 100 characters.",
	MAB_VHC_ContactUs_Email_InputEmail_Invalid_Message: "Invalid email format.",
	MAB_VHC_ContactUs_Email_InputCompany: "Company information*",
	MAB_VHC_ContactUs_Email_InputCompany_Required_Message: "Company information is required.",
	MAB_VHC_ContactUs_Email_InputCompany_MaxLength_Message: "Company information exceeds the allowed limit of 100 characters.",
	MAB_VHC_ContactUs_Email_InputAddress: "Address*",
	MAB_VHC_ContactUs_Email_InputAddress_Required_Message: "Address is required.",
	MAB_VHC_ContactUs_Email_InputAddress_MaxLength_Message: "Address exceeds the allowed limit of 250 characters.",
	MAB_VHC_ContactUs_Email_InputZipCode: "Zip code*",
	MAB_VHC_ContactUs_Email_InputZipCode_Required_Message: "Zip code is required.",
	MAB_VHC_ContactUs_Email_InputZipCode_MaxLength_Message: "Zip code exceeds the allowed limit of 25 characters.",
	MAB_VHC_ContactUs_Email_InputCity: "City*",
	MAB_VHC_ContactUs_Email_InputCity_Required_Message: "City is required.",
	MAB_VHC_ContactUs_Email_InputCity_MaxLength_Message: "City exceeds the allowed limit of 100 characters.",
	MAB_VHC_ContactUs_Email_InputCountry: "Country*",
	MAB_VHC_ContactUs_Email_InputCountry_Required_Message: "Country is required.",
	MAB_VHC_ContactUs_Email_InputCountry_MaxLength_Message: "Country exceeds the allowed limit of 100 characters.",
	MAB_VHC_ContactUs_Email_InputCertNo: "Certificate / Invoice no.*",
	MAB_VHC_ContactUs_Email_InputCertNo_Required_Message: "Certificate / Invoice no. is required.",
	MAB_VHC_ContactUs_Email_InputCertNo_MaxLength_Message: "Certificate / Invoice no. exceeds the allowed limit of 50 characters.",
	MAB_VHC_ContactUs_Email_InputReason: "Reason for contacting us*",
	MAB_VHC_ContactUs_Email_InputShortDescription: "Short description*",
	MAB_VHC_ContactUs_Email_InputShortDescription_Required_Message: "Short description is required.",
	MAB_VHC_ContactUs_Email_InputShortDescription_MaxLength_Message: "Short description exceeds the allowed limit of 100 characters.",
	MAB_VHC_ContactUs_Email_InputDescription: "Description*",
	MAB_VHC_ContactUs_Email_InputDescription_Required_Message: "Description is required.",
	MAB_VHC_ContactUs_Email_InputDescription_MaxLength_Message: "Description exceeds the allowed limit of 1000 characters.",
	MAB_VHC_ContactUs_Email_InputQuestion: "Question*",
	MAB_VHC_ContactUs_Email_InputQuestion_Required_Message: "Question is required.",
	MAB_VHC_ContactUs_Email_InputQuestion_MaxLength_Message: "Question exceeds the allowed limit of 100 characters.",
	MAB_VHC_ContactUs_Email_Urgency: "Urgency",
	MAB_VHC_ContactUs_Email_Urgency_Message: "I cannot continue to work.",
	MAB_VHC_ContactUs_Email_DataProtection: "Data protection notice*",
	MAB_VHC_ContactUs_Email_DataProtection_Description: "I have read the <a href='{{data_protection_link}}' target='_blank'>Privacy Policy.*</a>",
	MAB_VHC_ContactUs_Email_SecurityCheck: "Security check",
	MAB_VHC_ContactUs_Email_SecurityCodeInvalid: "The captcha text answer you entered is incorrect.",
	MAB_VHC_ContactUs_Email_SecurityCodeConfirm: "Please enter the correct characters you see in the box above.",
	MAB_VHC_ContactUs_Email_SuccessMessage: "Thank you for contacting us! Your message has been sent, and we will get back to you shortly.",
	MAB_VHC_ContactUs_Submit_Error_Message: "We couldn't send the email due to an issue. Please ensure the details are correct and try again.",
	MAB_VHC_ContactUs_Reasons_Incident: "Incident",
	MAB_VHC_ContactUs_Reasons_Bug: "Bug",
	MAB_VHC_ContactUs_Reasons_Question: "Question",
	MAB_VHC_ContactUs_Reasons_FeedbackFeatureRequest: "Feedback & feature request",
	MAB_VHC_ContactUs_Reasons_Incident_Form_Text: "Are you having trouble? Or is something blocking you from continuing to work? Please do not hesitate to reach out.",
	MAB_VHC_ContactUs_Reason_Incident_Topic: "Process step effected by the incident*",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleIdentification: "Vehicle identification",
	VHC_CONTACT_INCIDENT_TOPIC_AccessToTheService: "Access to the service",
	VHC_CONTACT_INCIDENT_TOPIC_StartingTheService: "Starting the service",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleDataReadout: "Vehicle data readout",
	VHC_CONTACT_INCIDENT_TOPIC_CheckoutAndInvoicing: "Checkout and invoicing",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentPreview: "Document preview",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentDelivery: "Document delivery",
	VHC_CONTACT_INCIDENT_TOPIC_Others: "Others",
	MAB_VHC_ContactUs_Reasons_Bug_Form_Text: "You have found a bug? Please feel free to share it with us.",
	MAB_VHC_ContactUs_Reason_Bug_Topic: "Process step effected by the incident*",
	MAB_VHC_ContactUs_Reasons_Question_Form_Text: "Do you have a question regarding our service? Your question was not covered by our <a href='{{faq_link}}' target='_blank'>FAQs</a>? Ask our support.",
	MAB_VHC_ContactUs_Reason_Question_Topic: "Topic*",
	MAB_VHC_ContactUs_Reason_Question_Topic_ServiceContent: "Service content",
	MAB_VHC_ContactUs_Reason_Question_Topic_VehicleCoverage: "Vehicle coverage",
	MAB_VHC_ContactUs_Reason_Question_Topic_CountryCoverage: "Country coverage",
	MAB_VHC_ContactUs_Reason_Question_Topic_CheckoutProcess: "Checkout process",
	MAB_VHC_ContactUs_Reason_Question_Topic_Pricing: "Pricing",
	MAB_VHC_ContactUs_Reason_Question_Topic_HardwareSoftware: "Hardware / software",
	MAB_VHC_ContactUs_Reason_Question_Topic_Others: "Others",
	MAB_VHC_ContactUs_Reasons_Feature_Form_Text: "You are missing a certain feature? Or want to provide direct feedback? Feel free to reach out.",
	MAB_VHC_SubmitButton: "Submit",
	MAB_VHC_CloseButton: "Close",
};
