import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "@translationsContexts/i18nContext";
import { useSelector } from "react-redux";
import { Grid, Button, Modal } from "semantic-ui-react";
import Boschicon from "../BoschIcon/Boschicon";
import { useDateFormat } from "../../hooks/useDateFormat";
import { selectAccountSubscription } from "../../redux/account";

import Styles from "./InactiveSubscriptionCDM3Modal.module.css";

interface InactiveSubscriptionCDM3ModalProps {
  isDisplayModal: boolean;
  setCloseModalTrigger: Function;
}

const InactiveSubscriptionCDM3Modal: FC<InactiveSubscriptionCDM3ModalProps> = (
  props: InactiveSubscriptionCDM3ModalProps
) => {
  const { t } = useTranslation();
  const userSubscription = useSelector(selectAccountSubscription);
  const formatDate = useDateFormat();
  const [openInformationModal, setOpenInformationModal] = useState(false);

  useEffect(() => {
    setOpenInformationModal(props.isDisplayModal);
  }, [props]);

  const closeInformationModal = () => {
    setOpenInformationModal(false);
    props.setCloseModalTrigger(false);
  };

  return (
    <Fragment>
      <Modal
        dimmer={{
          className: `${Styles.inactive_subscription_cmd3_modal} ${Styles.error}`,
        }}
        className={Styles.modal}
        onClose={closeInformationModal}
        open={openInformationModal}
        size={"tiny"}
      >
        <Modal.Header className={Styles.header}>
          <Boschicon name="bosch-ic-alert-warning" />
          {t("InactiveSubscriptionCDM3Modal_Title")}
        </Modal.Header>
        <Modal.Content className={Styles.content}>
          <Grid className={`${Styles.ui} ${Styles.grid}`}>
            <Grid.Row className={Styles.row}>
              <p
                className={Styles.description_text}
                dangerouslySetInnerHTML={{
                  __html: t(
                    "InactiveSubscriptionCDM3Modal_Description_Line1"
                  ).replace(
                    "[renewal_date]",
                    `<strong>${formatDate(
                      userSubscription?.renewalDate || ""
                    )}</strong>`
                  ),
                }}
              ></p>
              <p className={Styles.description_text}>
                {t("InactiveSubscriptionCDM3Modal_Description_Line2")}
              </p>
              <p className={Styles.description_text}>
                {t("InactiveSubscriptionCDM3Modal_Description_Line3")}
              </p>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions className={Styles.actions}>
          <Button primary onClick={closeInformationModal}>
            {t("ContinueButton")}
          </Button>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

export default InactiveSubscriptionCDM3Modal;
