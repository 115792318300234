export const MAB_VHC_TRANSLATIONS_NB_NO = {
	MAB_VHC_ContactUs_Headline: "Kontakt oss",
	MAB_VHC_ContactUs_HowCanWeHelp: "Hvordan kan vi hjelpe deg?",
	MAB_VHC_ContactUs_Email_Headline: "Send en e-post!",
	MAB_VHC_ContactUs_Email_Description: "Send oss ​​din melding ved å fylle ut skjemaet nedenfor. En av våre rådgivere tar gjerne kontakt med deg.",
	MAB_VHC_ContactUs_Email_InputName: "Navn*",
	MAB_VHC_ContactUs_Email_InputName_Required_Message: "Navn er påkrevd.",
	MAB_VHC_ContactUs_Email_InputName_MaxLength_Message: "Navnet overskrider den tillatte grensen på 100 tegn.",
	MAB_VHC_ContactUs_Email_InputEmail: "E-postadresse*",
	MAB_VHC_ContactUs_Email_InputEmail_Required_Message: "E-postadresse er påkrevd.",
	MAB_VHC_ContactUs_Email_InputEmail_MaxLength_Message: "E-postadressen overskrider den tillatte grensen på 100 tegn.",
	MAB_VHC_ContactUs_Email_InputEmail_Invalid_Message: "Ugyldig e-postformat.",
	MAB_VHC_ContactUs_Email_InputCompany: "Bedriftsinformasjon*",
	MAB_VHC_ContactUs_Email_InputCompany_Required_Message: "Firmainformasjon er nødvendig.",
	MAB_VHC_ContactUs_Email_InputCompany_MaxLength_Message: "Firmainformasjon overskrider den tillatte grensen på 100 tegn.",
	MAB_VHC_ContactUs_Email_InputAddress: "Adresse*",
	MAB_VHC_ContactUs_Email_InputAddress_Required_Message: "Adresse er påkrevd.",
	MAB_VHC_ContactUs_Email_InputAddress_MaxLength_Message: "Adressen overskrider den tillatte grensen på 250 tegn.",
	MAB_VHC_ContactUs_Email_InputZipCode: "Postnummer*",
	MAB_VHC_ContactUs_Email_InputZipCode_Required_Message: "Postnummer kreves.",
	MAB_VHC_ContactUs_Email_InputZipCode_MaxLength_Message: "Postnummeret overskrider den tillatte grensen på 25 tegn.",
	MAB_VHC_ContactUs_Email_InputCity: "By*",
	MAB_VHC_ContactUs_Email_InputCity_Required_Message: "By er påkrevd.",
	MAB_VHC_ContactUs_Email_InputCity_MaxLength_Message: "Byen overskrider den tillatte grensen på 100 tegn.",
	MAB_VHC_ContactUs_Email_InputCountry: "Land*",
	MAB_VHC_ContactUs_Email_InputCountry_Required_Message: "Land er obligatorisk.",
	MAB_VHC_ContactUs_Email_InputCountry_MaxLength_Message: "Landet overskrider den tillatte grensen på 100 tegn.",
	MAB_VHC_ContactUs_Email_InputCertNo: "Attest / Faktura nr.*",
	MAB_VHC_ContactUs_Email_InputCertNo_Required_Message: "Attest / Faktura nr. er nødvendig.",
	MAB_VHC_ContactUs_Email_InputCertNo_MaxLength_Message: "Attest / Faktura nr. overskrider den tillatte grensen på 50 tegn.",
	MAB_VHC_ContactUs_Email_InputReason: "Grunn til å kontakte oss*",
	MAB_VHC_ContactUs_Email_InputShortDescription: "Kort beskrivelse*",
	MAB_VHC_ContactUs_Email_InputShortDescription_Required_Message: "Kort beskrivelse er nødvendig.",
	MAB_VHC_ContactUs_Email_InputShortDescription_MaxLength_Message: "Kort beskrivelse overskrider den tillatte grensen på 100 tegn.",
	MAB_VHC_ContactUs_Email_InputDescription: "Beskrivelse*",
	MAB_VHC_ContactUs_Email_InputDescription_Required_Message: "Beskrivelse er nødvendig.",
	MAB_VHC_ContactUs_Email_InputDescription_MaxLength_Message: "Beskrivelsen overskrider den tillatte grensen på 1000 tegn.",
	MAB_VHC_ContactUs_Email_InputQuestion: "Spørsmål*",
	MAB_VHC_ContactUs_Email_InputQuestion_Required_Message: "Spørsmål er påkrevd.",
	MAB_VHC_ContactUs_Email_InputQuestion_MaxLength_Message: "Spørsmålet overskrider den tillatte grensen på 100 tegn.",
	MAB_VHC_ContactUs_Email_Urgency: "Haster",
	MAB_VHC_ContactUs_Email_Urgency_Message: "Jeg kan ikke fortsette å jobbe.",
	MAB_VHC_ContactUs_Email_DataProtection: "Merknad om databeskyttelse*",
	MAB_VHC_ContactUs_Email_DataProtection_Description: "Jeg har lest <a href='{{data_protection_link}}' target='_blank'>personvernreglene.*</a>",
	MAB_VHC_ContactUs_Email_SecurityCheck: "Sikkerhetssjekk",
	MAB_VHC_ContactUs_Email_SecurityCodeInvalid: "Captcha-tekstsvaret du skrev inn er feil.",
	MAB_VHC_ContactUs_Email_SecurityCodeConfirm: "Vennligst skriv inn de riktige tegnene du ser i boksen ovenfor.",
	MAB_VHC_ContactUs_Email_SuccessMessage: "Takk for at du kontakter oss! Meldingen din er sendt, og vi vil komme tilbake til deg innen kort tid.",
	MAB_VHC_ContactUs_Submit_Error_Message: "Vi kunne ikke sende e-posten på grunn av et problem. Kontroller at detaljene er korrekte og prøv igjen.",
	MAB_VHC_ContactUs_Reasons_Incident: "Hendelse",
	MAB_VHC_ContactUs_Reasons_Bug: "Bug",
	MAB_VHC_ContactUs_Reasons_Question: "Spørsmål",
	MAB_VHC_ContactUs_Reasons_FeedbackFeatureRequest: "Tilbakemelding og funksjonsforespørsel",
	MAB_VHC_ContactUs_Reasons_Incident_Form_Text: "Har du problemer? Eller er det noe som hindrer deg i å fortsette å jobbe? Ikke nøl med å ta kontakt.",
	MAB_VHC_ContactUs_Reason_Incident_Topic: "Prosesstrinn utført av hendelsen*",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleIdentification: "Identifikasjon av kjøretøy",
	VHC_CONTACT_INCIDENT_TOPIC_AccessToTheService: "Tilgang til tjenesten",
	VHC_CONTACT_INCIDENT_TOPIC_StartingTheService: "Starter tjenesten",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleDataReadout: "Avlesning av kjøretøydata",
	VHC_CONTACT_INCIDENT_TOPIC_CheckoutAndInvoicing: "Kasse og fakturering",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentPreview: "Forhåndsvisning av dokument",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentDelivery: "Dokumentlevering",
	VHC_CONTACT_INCIDENT_TOPIC_Others: "Andre",
	MAB_VHC_ContactUs_Reasons_Bug_Form_Text: "Har du funnet en feil? Del den gjerne med oss.",
	MAB_VHC_ContactUs_Reason_Bug_Topic: "Prosesstrinn utført av hendelsen*",
	MAB_VHC_ContactUs_Reasons_Question_Form_Text: "Har du spørsmål angående tjenesten vår? Spørsmålet ditt ble ikke dekket av våre <a href='{{faq_link}}' target='_blank'>vanlige spørsmål</a>? Spør vår støtte.",
	MAB_VHC_ContactUs_Reason_Question_Topic: "Emne*",
	MAB_VHC_ContactUs_Reason_Question_Topic_ServiceContent: "Tjenesteinnhold",
	MAB_VHC_ContactUs_Reason_Question_Topic_VehicleCoverage: "Kjøretøydekning",
	MAB_VHC_ContactUs_Reason_Question_Topic_CountryCoverage: "Landsdekning",
	MAB_VHC_ContactUs_Reason_Question_Topic_CheckoutProcess: "Kasseprosess",
	MAB_VHC_ContactUs_Reason_Question_Topic_Pricing: "Prissetting",
	MAB_VHC_ContactUs_Reason_Question_Topic_HardwareSoftware: "Maskinvare / programvare",
	MAB_VHC_ContactUs_Reason_Question_Topic_Others: "Andre",
	MAB_VHC_ContactUs_Reasons_Feature_Form_Text: "Mangler du en bestemt funksjon? Eller ønsker du å gi direkte tilbakemelding? Ta gjerne kontakt.",
	MAB_VHC_SubmitButton: "Send inn",
	MAB_VHC_CloseButton: "Lukke",
};
