import { useAdsNewRequest } from "@ads/hooks/useAdsNewRequest";
import {
  AdsCamera,
  AdsCameraCapability,
  AdsCameraMode,
} from "@ads/types/adsOcr.type";
import { ProgressIndicator } from "@bosch/react-frok";
import { BrowserMultiFormatReader } from "@zxing/browser";
import { useEffect, useMemo, useRef, useState } from "react";
import { createScheduler, createWorker } from "tesseract.js";
import style from "./AdsOcrScanModal.module.scss";
import { useTranslation } from "@translationsContexts/i18nContext";
import RDCommonErrorModal from "@components/RDComponents/RDCommonErrorModal/RDCommonErrorModal";
import { handleClickedOutOfComponent } from "@utils/dom.util";
import { DropdownItemProps } from "semantic-ui-react";
import RDDropdown from "@components/RDComponents/RDDropdown";
import { useFieldValidation } from "@hooks/useFieldValidation";

type Props = {
  onSelect: (scannedText: string) => void;
  onClose: () => void;
};

const AdsOcrScanModal = ({ onSelect, onClose }: Props) => {
  const { t } = useTranslation();
  const { validateVinNumber } = useAdsNewRequest();
  const { specialCharRegex } = useFieldValidation();
  const [isLoadingWorker, setLoadingWorker] = useState(true);
  const [cameraList, setCameraList] = useState<AdsCamera[]>([]);
  const [selectedCameraId, setSelectedCameraId] = useState<string>("");
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isDetectingCameraList, setDetectingCameraList] = useState(true);
  const capturedImgRef = useRef<HTMLCanvasElement | null>(null);
  const ocrScheduler = useMemo(() => createScheduler(), []);
  const codeReader = useMemo(() => new BrowserMultiFormatReader(), []);
  let scanTimeoutId = useMemo(() => 0, []);

  useEffect(() => {
    initSchedulerWorker();

    navigator.mediaDevices.enumerateDevices().then((deviceList) => {
      const allCameras = deviceList.filter(
        (device) => device.kind === "videoinput"
      );

      if (allCameras.length == 0) {
        setDetectingCameraList(false);
        return;
      }

      let rearCameraList: AdsCamera[] = [];

      for (const camera of allCameras) {
        const parsedCamera = camera as any;

        if (!parsedCamera.getCapabilities) continue;

        const capability =
          parsedCamera.getCapabilities() as AdsCameraCapability;

        const cameraInfo: AdsCamera = {
          deviceId: camera.deviceId,
          label: camera.label,
          capability,
        };

        if (isRearCamera(capability)) rearCameraList.push(cameraInfo);
      }

      setDetectingCameraList(false);
      setCameraList([...rearCameraList]);

      if (!!rearCameraList[0]) setSelectedCameraId(rearCameraList[0].deviceId);
    });
  }, []);

  useEffect(() => {
    const checkIfClickOutWrapper = (e: MouseEvent) =>
      handleClickedOutOfComponent(e, wrapperRef, () => {
        onClose();
        terminateOcrJob();
      });

    document.addEventListener("mousedown", checkIfClickOutWrapper);

    return () => {
      document.removeEventListener("mousedown", checkIfClickOutWrapper);
    };
  }, []);

  useEffect(() => {
    if (!selectedCameraId || isLoadingWorker || isDetectingCameraList) return;

    navigator.mediaDevices
      .getUserMedia({
        video: {
          deviceId: selectedCameraId,
        },
      })
      .then((stream) => {
        if (!videoRef.current) return;

        videoRef.current.srcObject = stream;
        videoRef.current.play();
      });
  }, [selectedCameraId, isLoadingWorker, isDetectingCameraList]);

  useEffect(() => {
    if (!videoRef.current || !capturedImgRef.current || isLoadingWorker) return;

    doOcr(200);

    return () => terminateOcrJob();
  }, [videoRef.current, capturedImgRef.current, isLoadingWorker]);

  const isRearCamera = (capability: AdsCameraCapability) => {
    return !!capability.facingMode.includes(AdsCameraMode.REAR);
  };

  const initSchedulerWorker = async () => {
    const numOfWorker = 3;

    for (let i = 0; i < numOfWorker; ++i) {
      const ocrWorker = await createWorker();
      ocrWorker.load();

      ocrScheduler.addWorker(ocrWorker);

      if (i === numOfWorker - 1) setLoadingWorker(false);
    }
  };

  const doOcr = (timeout: number) => {
    window.clearTimeout(scanTimeoutId);

    scanTimeoutId = window.setTimeout(() => {
      detectVinUsingOcr();

      doOcr(timeout);
    }, timeout);
  };

  const detectVinUsingOcr = async () => {
    if (!videoRef.current || !capturedImgRef.current) return;

    const width = videoRef.current.videoWidth;
    const height = videoRef.current.videoHeight;

    if (width === 0 || height === 0) return;

    capturedImgRef.current.width = width;
    capturedImgRef.current.height = height;

    const context = capturedImgRef.current.getContext("2d");

    context?.drawImage(videoRef.current, 0, 0, width, height);

    const imageData = context?.getImageData(
      0,
      0,
      videoRef.current.videoWidth,
      videoRef.current.videoHeight
    );

    if (!imageData) return;

    const pixels = imageData.data;

    for (let i = 0; i < pixels.length; i += 4) {
      const red = pixels[i];
      const green = pixels[i + 1];
      const blue = pixels[i + 2];

      const grayscale = convertRgbToGrayScale(red, green, blue);

      pixels[i] = grayscale;
      pixels[i + 1] = grayscale;
      pixels[i + 2] = grayscale;
    }

    context?.putImageData(imageData, 0, 0);

    const capturedImage = capturedImgRef.current.toDataURL();

    codeReader
      .decodeFromImageUrl(capturedImage)
      .then((response) => {
        const scannedText = response.getText().trim();

        autoDetectVinNumber(scannedText);
      })
      .catch(async () => {
        if (!capturedImgRef.current) return;

        const {
          data: { text },
        } = await ocrScheduler.addJob("recognize", capturedImgRef.current);

        autoDetectVinNumber(text.trim());
      });
  };

  const autoDetectVinNumber = (input: string) => {
    const normalizedInput = input
      .replaceAll(specialCharRegex, "")
      .replaceAll(/[a-z\n]/g, "")
      .trim();

    if (!normalizedInput) return;

    const splitInput = normalizedInput.split(" ");

    for (const text of splitInput) {
      if (!text) continue;

      const isIncludedDotChar = text.includes(".");

      if (!!validateVinNumber(text)) {
        if (!isIncludedDotChar) continue;

        const splitTextByDotChar = text.split(".");

        for (const subText of splitTextByDotChar) {
          if (!subText || !!validateVinNumber(subText)) continue;

          terminateOcrJob();
          onSelect(subText);
          return;
        }
      }

      if (!text || !!validateVinNumber(text)) continue;

      terminateOcrJob();
      onSelect(text);
      return;
    }
  };

  const terminateOcrJob = () => {
    window.clearTimeout(scanTimeoutId);
    ocrScheduler.terminate();
  };

  const convertRgbToGrayScale = (red: number, green: number, blue: number) => {
    return (red * 6966 + green * 23436 + blue * 2366) >> 15;
  };

  const getCameraOptions = (): DropdownItemProps[] => {
    return cameraList.map((camera) => ({
      text: `${t("ADS_OCRModal_Rear")} - ${camera.label}`,
      value: camera.deviceId,
    }));
  };

  const onCameraChange = (_: any, { value }: any) => {
    setSelectedCameraId(value);
  };

  if (!isDetectingCameraList && cameraList.length === 0)
    return (
      <RDCommonErrorModal
        onClose={() => onClose()}
        messages={[t("ADS_OCRModal_NoRearCameraError")]}
      />
    );

  return (
    <div className={`a-box--modal -show ${style.container}`}>
      <div ref={wrapperRef} className={style.wrapper}>
        {!isDetectingCameraList && !isLoadingWorker ? (
          <>
            <div className={style.title}>
              <p>{t("ADS_OCRModal_Title")}</p>
              <p>{t("ADS_OCRModal_Desc")}</p>
            </div>
            <div className={style.main_content}>
              <div className={style.camera_input_panel}>
                {cameraList.length > 1 ? (
                  <div className={style.camera_selection}>
                    <RDDropdown
                      title={t("ADS_OCRModal_SelectCamera")}
                      options={getCameraOptions()}
                      search
                      selectOnBlur={false}
                      placeholder="-"
                      value={selectedCameraId}
                      onChange={onCameraChange}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {!!selectedCameraId ? (
                  <video
                    ref={videoRef}
                    className={style.camera_viewport}
                  ></video>
                ) : (
                  <p>{t("ADS_OCRModal_ConnectingCamera")}</p>
                )}
                <canvas
                  ref={capturedImgRef}
                  style={{
                    display: "none",
                  }}
                ></canvas>
              </div>
            </div>
          </>
        ) : (
          <div className={style.camera_detecting}>
            <ProgressIndicator />
            <p>{t("ADS_OCRModal_DetectingCamera")}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdsOcrScanModal;
