import { FC } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  selectAccount,
  selectAccountSubscription,
} from "../../../../redux/account";
import { useTranslation } from "@translationsContexts/i18nContext";
import RDButton from "../../../RDComponents/RDButton";
import RDText from "../../../RDComponents/RDText";

const NewRequestButton: FC = () => {
  const { t } = useTranslation();
  const currentAccount = useSelector(selectAccount);
  const accountSubscription = useSelector(selectAccountSubscription);

  return !!currentAccount?.cdbId &&
    currentAccount?.cdbId > 0 &&
    accountSubscription &&
    accountSubscription.isActive ? (
    <Link to="/my-requests">
      <RDButton small minWidth="maxContent">
        <RDText>{t("MenuHeaderPopup_MyRequests_NewRequest")}</RDText>
      </RDButton>
    </Link>
  ) : (
    <></>
  );
};

export default NewRequestButton;
