import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "@translationsContexts/i18nContext";
import { Button, Grid, Modal } from "semantic-ui-react";

const FileSizeWarningModal: FC<any> = (props: any) => {
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const { t } = useTranslation();
  const maxSizeInMB = Math.round(
    parseInt(`${process.env.REACT_APP_AWS_S3_MAX_SIZE}`) / 1024 / 1024
  );

  useEffect(() => {
    if (!!props.enabled) {
      setOpenErrorModal(true);
    }
  }, [props.enabled]);

  const closeErrorModal = () => {
    setOpenErrorModal(false);
    props.setErrorTrigger();
  };

  return (
    <Fragment>
      <Modal
        onClose={closeErrorModal}
        open={openErrorModal}
        dimmer={{ className: "test-dimmer" }}
        size={"mini"}
        closeIcon
      >
        <Modal.Content>
          <Grid divided="vertically">
            <Grid.Row>
              <label
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  fontWeight: "bold",
                }}
              >
                {`${t(
                  "ChatStoppedModal_ConversationStoppedByExpertSide_Title"
                )} ${props.title}`}
              </label>
            </Grid.Row>

            <Grid.Row
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                margin: "10px",
              }}
            >
              {`${t(
                "FileSizeWarningModal_FileSizeLargerThanLimitSize"
              )} ${maxSizeInMB} MB`}
            </Grid.Row>

            <Grid.Row>
              <Button
                className="button-search"
                style={{ width: "100%" }}
                onClick={closeErrorModal}
              >
                {t("CloseButton")}
              </Button>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
};

export default FileSizeWarningModal;
