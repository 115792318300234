export const MAB_VHC_TRANSLATIONS_NL_NL = {
	MAB_VHC_ContactUs_Headline: "Neem contact met ons op",
	MAB_VHC_ContactUs_HowCanWeHelp: "Hoe kunnen wij u helpen?",
	MAB_VHC_ContactUs_Email_Headline: "Stuur een e-mail!",
	MAB_VHC_ContactUs_Email_Description: "Stuur ons uw bericht door het onderstaande formulier in te vullen. Eén van onze adviseurs komt graag met u in contact.",
	MAB_VHC_ContactUs_Email_InputName: "Naam*",
	MAB_VHC_ContactUs_Email_InputName_Required_Message: "Naam is vereist.",
	MAB_VHC_ContactUs_Email_InputName_MaxLength_Message: "Naam overschrijdt de toegestane limiet van 100 tekens.",
	MAB_VHC_ContactUs_Email_InputEmail: "E-mailadres*",
	MAB_VHC_ContactUs_Email_InputEmail_Required_Message: "E-mailadres is vereist.",
	MAB_VHC_ContactUs_Email_InputEmail_MaxLength_Message: "E-mailadres overschrijdt de toegestane limiet van 100 tekens.",
	MAB_VHC_ContactUs_Email_InputEmail_Invalid_Message: "Ongeldig e-mailformaat.",
	MAB_VHC_ContactUs_Email_InputCompany: "Bedrijfsinformatie*",
	MAB_VHC_ContactUs_Email_InputCompany_Required_Message: "Bedrijfsinformatie is vereist.",
	MAB_VHC_ContactUs_Email_InputCompany_MaxLength_Message: "Bedrijfsinformatie overschrijdt de toegestane limiet van 100 tekens.",
	MAB_VHC_ContactUs_Email_InputAddress: "Adres*",
	MAB_VHC_ContactUs_Email_InputAddress_Required_Message: "Adres is vereist.",
	MAB_VHC_ContactUs_Email_InputAddress_MaxLength_Message: "Adres overschrijdt de toegestane limiet van 250 tekens.",
	MAB_VHC_ContactUs_Email_InputZipCode: "Postcode*",
	MAB_VHC_ContactUs_Email_InputZipCode_Required_Message: "Postcode is vereist.",
	MAB_VHC_ContactUs_Email_InputZipCode_MaxLength_Message: "De postcode overschrijdt de toegestane limiet van 25 tekens.",
	MAB_VHC_ContactUs_Email_InputCity: "Stad*",
	MAB_VHC_ContactUs_Email_InputCity_Required_Message: "Stad is vereist.",
	MAB_VHC_ContactUs_Email_InputCity_MaxLength_Message: "Plaats overschrijdt de toegestane limiet van 100 tekens.",
	MAB_VHC_ContactUs_Email_InputCountry: "Land*",
	MAB_VHC_ContactUs_Email_InputCountry_Required_Message: "Land is vereist.",
	MAB_VHC_ContactUs_Email_InputCountry_MaxLength_Message: "Land overschrijdt de toegestane limiet van 100 tekens.",
	MAB_VHC_ContactUs_Email_InputCertNo: "Certificaat / Factuurnr.*",
	MAB_VHC_ContactUs_Email_InputCertNo_Required_Message: "Certificaat / Factuurnr. is vereist.",
	MAB_VHC_ContactUs_Email_InputCertNo_MaxLength_Message: "Certificaat / Factuurnr. overschrijdt de toegestane limiet van 50 tekens.",
	MAB_VHC_ContactUs_Email_InputReason: "Reden om contact met ons op te nemen*",
	MAB_VHC_ContactUs_Email_InputShortDescription: "Korte beschrijving*",
	MAB_VHC_ContactUs_Email_InputShortDescription_Required_Message: "Een korte beschrijving is vereist.",
	MAB_VHC_ContactUs_Email_InputShortDescription_MaxLength_Message: "Korte beschrijving overschrijdt de toegestane limiet van 100 tekens.",
	MAB_VHC_ContactUs_Email_InputDescription: "Beschrijving*",
	MAB_VHC_ContactUs_Email_InputDescription_Required_Message: "Beschrijving is vereist.",
	MAB_VHC_ContactUs_Email_InputDescription_MaxLength_Message: "Beschrijving overschrijdt de toegestane limiet van 1000 tekens.",
	MAB_VHC_ContactUs_Email_InputQuestion: "Vraag*",
	MAB_VHC_ContactUs_Email_InputQuestion_Required_Message: "Vraag is vereist.",
	MAB_VHC_ContactUs_Email_InputQuestion_MaxLength_Message: "De vraag overschrijdt de toegestane limiet van 100 tekens.",
	MAB_VHC_ContactUs_Email_Urgency: "Urgentie",
	MAB_VHC_ContactUs_Email_Urgency_Message: "Ik kan niet verder werken.",
	MAB_VHC_ContactUs_Email_DataProtection: "Gegevensbeschermingsverklaring*",
	MAB_VHC_ContactUs_Email_DataProtection_Description: "Ik heb het <a href='{{data_protection_link}}' target='_blank'>Privacybeleid*</a> gelezen",
	MAB_VHC_ContactUs_Email_SecurityCheck: "Veiligheidscontrole",
	MAB_VHC_ContactUs_Email_SecurityCodeInvalid: "Het captcha-tekstantwoord dat u heeft ingevoerd, is onjuist.",
	MAB_VHC_ContactUs_Email_SecurityCodeConfirm: "Voer de juiste tekens in die u in het vak hierboven ziet.",
	MAB_VHC_ContactUs_Email_SuccessMessage: "Bedankt om ons te contacteren! Uw bericht is verzonden en wij nemen zo snel mogelijk contact met u op.",
	MAB_VHC_ContactUs_Submit_Error_Message: "We kunnen de e-mail niet verzenden vanwege een probleem. Zorg ervoor dat de gegevens correct zijn en probeer het opnieuw.",
	MAB_VHC_ContactUs_Reasons_Incident: "Incident",
	MAB_VHC_ContactUs_Reasons_Bug: "Fout",
	MAB_VHC_ContactUs_Reasons_Question: "Vraag",
	MAB_VHC_ContactUs_Reasons_FeedbackFeatureRequest: "Feedback en functieverzoek",
	MAB_VHC_ContactUs_Reasons_Incident_Form_Text: "Ondervindt u problemen? Of verhindert iets u om verder te kunnen werken? Aarzel niet om contact op te nemen.",
	MAB_VHC_ContactUs_Reason_Incident_Topic: "Processtap betroffen door het incident*",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleIdentification: "Voertuigidentificatie",
	VHC_CONTACT_INCIDENT_TOPIC_AccessToTheService: "Toegang tot de dienst",
	VHC_CONTACT_INCIDENT_TOPIC_StartingTheService: "Het starten van de dienst",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleDataReadout: "Uitlezen van voertuiggegevens",
	VHC_CONTACT_INCIDENT_TOPIC_CheckoutAndInvoicing: "Afrekenen en factureren",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentPreview: "Documentvoorbeeld",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentDelivery: "Levering van het document",
	VHC_CONTACT_INCIDENT_TOPIC_Others: "Anderen",
	MAB_VHC_ContactUs_Reasons_Bug_Form_Text: "Heeft u een fout gevonden? Deel het gerust met ons.",
	MAB_VHC_ContactUs_Reason_Bug_Topic: "Processtap betroffen door het incident*",
	MAB_VHC_ContactUs_Reasons_Question_Form_Text: "Heeft u een vraag over onze service? Uw vraag staat niet in onze <a href='{{faq_link}}' target='_blank'>Veelgestelde vragen</a>? Vraag onze ondersteuning.",
	MAB_VHC_ContactUs_Reason_Question_Topic: "Onderwerp*",
	MAB_VHC_ContactUs_Reason_Question_Topic_ServiceContent: "Inhoud van de service",
	MAB_VHC_ContactUs_Reason_Question_Topic_VehicleCoverage: "Voertuigdekking",
	MAB_VHC_ContactUs_Reason_Question_Topic_CountryCoverage: "Landdekking",
	MAB_VHC_ContactUs_Reason_Question_Topic_CheckoutProcess: "Bestelproces",
	MAB_VHC_ContactUs_Reason_Question_Topic_Pricing: "Prijzen",
	MAB_VHC_ContactUs_Reason_Question_Topic_HardwareSoftware: "Hardware/software",
	MAB_VHC_ContactUs_Reason_Question_Topic_Others: "Andere",
	MAB_VHC_ContactUs_Reasons_Feature_Form_Text: "Mis je een bepaalde functie? Of wilt u direct feedback geven? Neem gerust contact op.",
	MAB_VHC_SubmitButton: "Verzenden",
	MAB_VHC_CloseButton: "Afsluiten",
};
