import _ from "lodash";
import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "@translationsContexts/i18nContext";
import { RouteComponentProps } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import AccordionPanel from "../Accordion/AccordionPanel";
import Header from "../Layout/Header";
import RedirectHelper from "../../utils/helpers/RedirectHelper";
import CustomLoader from "../loading/CustomLoader";
import { Language } from "../../models/RegionState";
import { getRegionsAsync } from "../../redux/region";
import { AppDispatch, useAppDispatch } from "../../redux/store";
import { AuthService } from "../../services/AuthService";
import { CommonService } from "../../services/Common";
import style from "./LandingPageCDM3.module.css";
import LandingPageServiceWork from "./LandingPageServiceWork";
import { clearCart } from "../../redux/shoppingCartV3Slice";

const LandingPageCDM3: FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();

  const [regionsData, setRegionsData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    async function performGetRegions() {
      setIsLoading(true);
      const response = await dispatchReduxToolkit(getRegionsAsync());
      if (getRegionsAsync.fulfilled.match(response)) {
        if (!!response.payload && response.payload.success) {
          if (response.payload.dataObject != null) {
            AuthService.setRegionsLanguages(response.payload.dataObject);
            const regions = _.groupBy(response.payload.dataObject, "area");
            setRegionsData(regions);

            CommonService.scrollTo("#countries-selector");
          }
        }
      }
      setIsLoading(false);
    }
    performGetRegions();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const handleSetLanguageClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    countryCode: string,
    language: Language
  ) => {
    event.preventDefault();
    dispatchReduxToolkit(clearCart());
    const url = process.env.REACT_APP_DEFAULT_CLIENTROOT || "";
    if (!url) {
      RedirectHelper.redirectToMainSite();
    }

    const countryCodeCheck = countryCode.trim().toUpperCase();
    switch (countryCodeCheck) {
      case "BA":
        RedirectHelper.redirectToSubDomain(url, "HR", "hr", countryCodeCheck);
        break;
      case "SL":
        RedirectHelper.redirectToSubDomain(url, "HR", "sl", countryCodeCheck);
        break;
      default:
        if (url !== "") {
          RedirectHelper.redirectToSubDomain(
            url,
            countryCode,
            language.lcid,
            countryCodeCheck
          );
        } else {
          window.location.href = "/";
        }
        break;
    }
  };

  const items = Object.entries(regionsData)
    ?.filter(([key, value]: [string, any]) => {
      return !!value?.find((x: any) => x.isActive);
    })
    .map(([key, value]: [string, any]) => {
      const countryContent = value
        ?.filter((x: any) => x.isActive)
        .map((countryItem: any, index: any) => {
          return (
            <Grid.Column
              key={countryItem.country}
              computer={5}
              tablet={8}
              mobile={16}
              style={{ alignItems: "baseline", paddingRight: "0.5rem" }}
            >
              {countryItem.languages.map((language: Language) => {
                if (language.defaultLanguage) {
                  return (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      className="country-label"
                      href="#"
                      onClick={(e) => {
                        handleSetLanguageClick(
                          e,
                          countryItem.country,
                          language
                        );
                      }}
                    >
                      {" "}
                      {countryItem.countryName}
                    </a>
                  );
                }
              })}
            </Grid.Column>
          );
        });

      const alignedContent = (
        <Grid
          className={`${style.countries_content}`}
          container
          style={{
            alignItems: "baseline",
            display: "flex",
            // justifyContent: "space-between",
          }}
        >
          {countryContent}
        </Grid>
      );
      return { title: key, content: alignedContent };
    });
  return (
    <Fragment>
      <Header isInLandingPage={true}></Header>
      <div
        className="grid-responsive-width body-container landing-body-container"
        style={{ paddingBottom: "3rem" }}
      >
        <Grid className={`${style.servicework_section}`}>
          <LandingPageServiceWork />
        </Grid>
        <Grid className={`${style.country_selector}`}>
          <Grid.Row>
            <h3
              id="countries-selector"
              style={{ textAlign: "center", paddingTop: "3rem" }}
              className="accordion-header-text"
            >
              {t("LandingPageCDM3_SubTitle_ChooseCountry")}{" "}
            </h3>
          </Grid.Row>
          <Grid.Row>
            <AccordionPanel
              divider
              items={items}
              activeindex={0}
              titleClass="landing-according-title"
            />
          </Grid.Row>
        </Grid>
      </div>
      <CustomLoader enable={isLoading} />
    </Fragment>
  );
};

export default LandingPageCDM3;
