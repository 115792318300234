import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { Grid, Table, Button, Message, Icon } from "semantic-ui-react";
import "./SettingsMyHardware.css";
import "../assets/css/semantic-bosch/_theme.css";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { selectAccount } from "../redux/account";
import { selectCountryCode, selectLanguage } from "../redux/auth";
import CustomLoader from "../components/loading/CustomLoader";
import { useTranslation } from "@translationsContexts/i18nContext";
import "../translations/i18n";
import { AppDispatch } from "../redux/store";
import {
  RequestFaultCodesModel,
  RequestUserHardwaresModel,
} from "../models/hardware/request.hardware.model";
import {
  getReasonListAsync,
  getUserHardwaresAsync,
  submitHardwareClaim,
} from "../redux/hardwareSlice";
import { VciFavouriteModel } from "../models/vci/vciFavourite.model";
import {
  createVciFavouriteAsync,
  deleteVciFavouriteAsync,
  getVciFavouriteAsync,
} from "../redux/vciSlide";
import ResponseResultBase from "../models/ResponseResultBase";
import ClaimModal from "../components/Modal/ClaimModal";

interface Hardware {
  id: number;
  name: string;
  serialNumber: string;
  isActivated: boolean;
  repairStatus: RepairStatus;
  repairRequest: any[];
}

interface RepairStatus {
  id: number;
  language: string;
  status: string;
  statusLocalized: string;
}

interface Reason {
  id: number;
  language: string;
  fault: string;
  faultLocalized: string;
}

const SettingsMyHardware: FC<any> = () => {
  const { t } = useTranslation();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const currentAccount = useAppSelector(selectAccount);
  const countryCode = useSelector(selectCountryCode);
  const language = useSelector(selectLanguage);

  const [claimModalStatus, setClaimModalStatus] = useState<any>({
    isOpened: false,
    data: null,
  });
  const [hardwares, setHardwares] = useState<Hardware[]>([]);
  const [reasonList, setReasonList] = useState<any>(null);
  const [selectedReason, setSelectedReason] = useState<number>();
  const [selectedItem, setSelectedItem] = useState<Hardware>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [claimResult, setClaimResult] = useState<string>("");

  const [favouriteVci, setFavouriteVci] = useState<VciFavouriteModel | null>(
    null
  );
  const [isUpdatingFavouriteVci, setIsUpdatingFavouriteVci] =
    useState<boolean>(false);
  const [updatingFavouriteVciId, setUpdatingFavouriteVciId] =
    useState<number>(0);

  const getUserHardwares = useCallback(() => {
    const requestModel: RequestUserHardwaresModel = {
      ciamId: currentAccount?.ciamId,
      countryCode: countryCode,
      language: language,
    };

    return dispatchReduxToolkit(getUserHardwaresAsync(requestModel));
  }, [countryCode, currentAccount?.ciamId, dispatchReduxToolkit, language]);

  const getReasonList = useCallback(() => {
    let requestModel: RequestFaultCodesModel = {
      countryCode: countryCode,
      language: language,
    };

    return dispatchReduxToolkit(getReasonListAsync(requestModel));
  }, [countryCode, dispatchReduxToolkit, language]);

  useEffect(() => {
    getUserHardwares().then((response: any) => {
      setHardwares(response.payload ? response.payload : []);

      setIsLoading(false);
    });

    getReasonList().then((response: any) => {
      const reasons: Reason[] = response.payload ? response.payload : [];

      if (reasons.length > 0) {
        setReasonList(
          reasons.map((element: Reason) => {
            return {
              key: element.id,
              text: element.faultLocalized,
              value: element.id,
            };
          })
        );
      }
    });
  }, [getReasonList, getUserHardwares]);

  useEffect(() => {
    if (!claimModalStatus.isOpened && claimModalStatus.data) {
      setIsLoading(true);

      dispatchReduxToolkit(submitHardwareClaim(claimModalStatus.data))
        .then((response: any) => {
          setClaimResult("positive");
        })
        .catch(() => {
          setClaimResult("negative");
        })
        .finally(() => {
          setClaimModalStatus({
            data: null,
          });

          getUserHardwares().then((response: any) => {
            setHardwares(response.payload ? response.payload : []);

            setIsLoading(false);
          });
        });
    }
  }, [
    claimModalStatus.data,
    claimModalStatus.isOpened,
    dispatchReduxToolkit,
    getUserHardwares,
  ]);

  useEffect(() => {
    if (currentAccount && currentAccount?.ciamId) {
      const getVciFavouritePromise = dispatchReduxToolkit(
        getVciFavouriteAsync(currentAccount?.ciamId)
      );
      getVciFavouritePromise.then((result) => {
        if (result && result.payload) {
          const vciResult =
            result.payload as ResponseResultBase<VciFavouriteModel>;
          if (vciResult && vciResult.success) {
            setFavouriteVci(vciResult.dataObject);
          }
        }
      });
    }
  }, [currentAccount]);

  const handleFavoriteButtonSelect = (vciId: number) => {
    setUpdatingFavouriteVciId(vciId);
    if (favouriteVci && favouriteVci.vciId === vciId) {
      deleteVciFavourite();
    } else {
      createVciFavourite(vciId);
    }
  };

  const createVciFavourite = (vciId: number) => {
    if (currentAccount && currentAccount?.ciamId) {
      setIsUpdatingFavouriteVci(true);
      const vciFavourite: VciFavouriteModel = {
        id: favouriteVci ? favouriteVci.id : 0,
        ciamId: currentAccount?.ciamId,
        vciId: vciId,
      };
      const createVciFavouritePromise = dispatchReduxToolkit(
        createVciFavouriteAsync(vciFavourite)
      );
      createVciFavouritePromise
        .then((result) => {
          if (result && result.payload) {
            const teamResult =
              result.payload as ResponseResultBase<VciFavouriteModel>;
            if (teamResult && teamResult.success) {
              setFavouriteVci(teamResult.dataObject);
            }
          }
        })
        .finally(() => {
          setIsUpdatingFavouriteVci(false);
        });
    }
  };

  const deleteVciFavourite = () => {
    if (favouriteVci) {
      setIsUpdatingFavouriteVci(true);
      const createContactPersonFavouritePromise = dispatchReduxToolkit(
        deleteVciFavouriteAsync(favouriteVci.id)
      );
      createContactPersonFavouritePromise
        .then((result) => {
          if (result && result.payload) {
            const teamResult = result.payload as ResponseResultBase<boolean>;
            if (teamResult && teamResult.success) {
              setFavouriteVci(null);
            }
          }
        })
        .finally(() => setIsUpdatingFavouriteVci(false));
    }
  };

  const myHardwareRows =
    hardwares.length > 0 &&
    hardwares.map((hardware) => {
      return (
        <Table.Row key={hardware.id}>
          <Table.Cell width={8}>
            <h5>
              {hardware.name}
              <Button
                className="check-button favourite-button"
                basic
                size="small"
                style={{ boxShadow: "none", padding: "none", margin: "none" }}
                onClick={() => handleFavoriteButtonSelect(hardware.id)}
                disabled={
                  isUpdatingFavouriteVci &&
                  updatingFavouriteVciId === hardware.id
                }
                loading={
                  isUpdatingFavouriteVci &&
                  updatingFavouriteVciId === hardware.id
                }
              >
                <Icon
                  circular
                  style={{
                    color: "#ffe623",
                    background: "none",
                    alignItems: "center",
                  }}
                  size="large"
                  name={
                    favouriteVci && favouriteVci.vciId === hardware.id
                      ? "star"
                      : "star outline"
                  }
                >
                  {/* <Close style={{ fill: "#FFF", padding: "0.2rem" }} /> */}
                </Icon>
              </Button>
            </h5>
          </Table.Cell>
          {/* <Table.Cell width={4}> */}
          {/* <Button style={{width: "100%"}}>Request Return</Button> */}
          {/* <Button fluid className='my-hardware-return-button'>Request Return</Button> */}
          {/* </Table.Cell> */}
          <Table.Cell width={4} style={{ textAlign: "right" }}>
            {hardware.repairStatus.status === "Initiated" ? (
              <Button
                style={{
                  backgroundColor: "#78BE20",
                  color: "#ffffff",
                }}
              >
                {t("SettingsMyHardware_TableData_ClaimStartedButton")}
              </Button>
            ) : (
              <Button
                style={{
                  backgroundColor: "#2185d0",
                  color: "#ffffff",
                }}
                onClick={() => {
                  setSelectedItem(hardware);
                  setClaimModalStatus({
                    isOpened: true,
                  });
                }}
              >
                {t("SettingsMyHardware_TableData_StartClaimButton")}
              </Button>
            )}
            {/* <Button fluid className='my-hardware-repair-button'>Request Repair</Button> */}
          </Table.Cell>
        </Table.Row>
      );
    });

  return (
    <Fragment>
      <Grid container className="grid-padding-side-1rem">
        <Grid.Row style={{ flexDirection: "column" }}>
          <h3 className="headline">
            {t("SettingsMyHardware_Dasboard_Headline")}
          </h3>
        </Grid.Row>
      </Grid>

      <Grid
        container
        className="my-hardware-container my-hardware-page-container grid-padding-side-1rem"
      >
        {/* <Grid.Row></Grid.Row> */}
        <Grid.Row>
          <h5 style={{ textDecoration: "underline" }}>
            {t("SettingsMyHardware_SubHeadline")}
          </h5>
        </Grid.Row>
        {isLoading ? (
          <div>
            <CustomLoader enable={true} />
          </div>
        ) : (
          <Grid.Row className="hardwarelist-container">
            <Table basic="very">
              <Table.Body>{myHardwareRows}</Table.Body>
            </Table>
            <ClaimModal
              isOpenClaimModalStatus={claimModalStatus.isOpened}
              reasonList={reasonList}
              selectedHardwareId={selectedItem?.id}
              selectedReason={selectedReason}
              setClaimModalStatus={setClaimModalStatus}
              setSelectedReason={setSelectedReason}
            />
          </Grid.Row>
        )}
        {/* <Grid.Row>
          <LabelledLink
            to="/settings"
            icon={<BackLeft />}
            content={t("BackButton")}
            iconLeft={true}
            style={{ fontSize: "1.75rem" }}
          ></LabelledLink>
        </Grid.Row> */}

        <Grid.Row>
          {claimResult === "positive" && (
            <Message className="claim-submit-message" positive>
              <Message.Header>
                {t("SettingsMyHardware_Message_Successful_Headline")}
              </Message.Header>
              <p>{t("SettingsMyHardware_Message_Successful_SubHeadline")}</p>
            </Message>
          )}

          {claimResult === "negative" && (
            <Message className="claim-submit-message" negative>
              <Message.Header>
                {t("SettingsMyHardware_Message_Failed_Headline")}
              </Message.Header>
              <p>{t("SettingsMyHardware_Message_Failed_SubHeadline")}</p>
            </Message>
          )}
        </Grid.Row>
      </Grid>
    </Fragment>
  );
};

export default SettingsMyHardware;
