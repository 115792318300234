import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "@translationsContexts/i18nContext";
import { Link } from "react-router-dom";
import { Grid, Button, Modal } from "semantic-ui-react";
import { useSelector } from "react-redux";
import Boschicon from "../BoschIcon/Boschicon";
import Styles from "./WelcomeCDM3Modal.module.scss";
import { selectAccount } from "../../redux/account";

interface WelcomeCDM3ModalProps {
  isDisplayModal: boolean;
  setCloseModalTrigger: Function;
}

const WelcomeCDM3Modal: FC<WelcomeCDM3ModalProps> = (
  props: WelcomeCDM3ModalProps
) => {
  const { t } = useTranslation();
  const account = useSelector(selectAccount);
  const [openInformationModal, setOpenInformationModal] = useState(false);

  useEffect(() => {
    setOpenInformationModal(props.isDisplayModal);
  }, [props]);

  const closeInformationModal = () => {
    setOpenInformationModal(false);
    props.setCloseModalTrigger(false);
  };

  return (
    <Fragment>
      <Modal
        dimmer={{
          className: `${Styles.welcome_cmd3_modal} ${Styles.success}`,
        }}
        className={Styles.modal}
        onClose={closeInformationModal}
        open={openInformationModal}
        size={"tiny"}
      >
        <Modal.Header className={Styles.header}>
          <Boschicon name="bosch-ic-car-connected" />
          {t("AccountInReviewModal_Title")}
        </Modal.Header>
        <Modal.Content className={Styles.content}>
          <Grid className={`${Styles.ui} ${Styles.grid}`}>
            <Grid.Row className={Styles.row}>
              {!account?.cdbId && (
                <p className={Styles.activation_link}>
                  <Link to="dashboard/editmyprofile/activate">
                    {t("WelcomeCDM3Modal_ActivationLink")}
                  </Link>
                  <small className={Styles.activation_link_notes}>
                    {t("WelcomeCDM3Modal_ActivationLinkNotes")}
                  </small>
                </p>
              )}
              <p className={Styles.description_text}>
                {t("AccountInReviewModal_Description_Paragraph1")}
              </p>
              <p className={Styles.description_text}>
                {t("AccountInReviewModal_Description_Paragraph2")}
              </p>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions className={Styles.actions}>
          <Button primary onClick={closeInformationModal}>
            {t("AccountInReviewModal_CloseButton")}
          </Button>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

export default WelcomeCDM3Modal;
