export const MAB_VHC_TRANSLATIONS_FR_FR = {
	MAB_VHC_ContactUs_Headline: "Contactez-nous",
	MAB_VHC_ContactUs_HowCanWeHelp: "Comment pouvons-nous vous aider ?",
	MAB_VHC_ContactUs_Email_Headline: "Envoyez un e-mail !",
	MAB_VHC_ContactUs_Email_Description: "Envoyez-nous votre message en remplissant le formulaire ci-dessous. Un de nos conseillers se fera un plaisir de prendre contact avec vous.",
	MAB_VHC_ContactUs_Email_InputName: "Nom*",
	MAB_VHC_ContactUs_Email_InputName_Required_Message: "Le nom est requis.",
	MAB_VHC_ContactUs_Email_InputName_MaxLength_Message: "Le nom dépasse la limite autorisée de 100 caractères.",
	MAB_VHC_ContactUs_Email_InputEmail: "Adresse e-mail*",
	MAB_VHC_ContactUs_Email_InputEmail_Required_Message: "Une adresse e-mail est requise.",
	MAB_VHC_ContactUs_Email_InputEmail_MaxLength_Message: "L'adresse e-mail dépasse la limite autorisée de 100 caractères.",
	MAB_VHC_ContactUs_Email_InputEmail_Invalid_Message: "Format d'e-mail invalide.",
	MAB_VHC_ContactUs_Email_InputCompany: "Informations sur l'entreprise*",
	MAB_VHC_ContactUs_Email_InputCompany_Required_Message: "Les informations sur l'entreprise sont requises.",
	MAB_VHC_ContactUs_Email_InputCompany_MaxLength_Message: "Les informations sur l'entreprise dépassent la limite autorisée de 100 caractères.",
	MAB_VHC_ContactUs_Email_InputAddress: "Adresse*",
	MAB_VHC_ContactUs_Email_InputAddress_Required_Message: "L'adresse est requise.",
	MAB_VHC_ContactUs_Email_InputAddress_MaxLength_Message: "L'adresse dépasse la limite autorisée de 250 caractères.",
	MAB_VHC_ContactUs_Email_InputZipCode: "Code postal*",
	MAB_VHC_ContactUs_Email_InputZipCode_Required_Message: "Le code postal est requis.",
	MAB_VHC_ContactUs_Email_InputZipCode_MaxLength_Message: "Le code postal dépasse la limite autorisée de 25 caractères.",
	MAB_VHC_ContactUs_Email_InputCity: "Ville*",
	MAB_VHC_ContactUs_Email_InputCity_Required_Message: "La ville est obligatoire.",
	MAB_VHC_ContactUs_Email_InputCity_MaxLength_Message: "La ville dépasse la limite autorisée de 100 caractères.",
	MAB_VHC_ContactUs_Email_InputCountry: "Pays*",
	MAB_VHC_ContactUs_Email_InputCountry_Required_Message: "Le pays est requis.",
	MAB_VHC_ContactUs_Email_InputCountry_MaxLength_Message: "Le pays dépasse la limite autorisée de 100 caractères.",
	MAB_VHC_ContactUs_Email_InputCertNo: "Certificat / Facture n°*",
	MAB_VHC_ContactUs_Email_InputCertNo_Required_Message: "Certificat / Facture n° est requis.",
	MAB_VHC_ContactUs_Email_InputCertNo_MaxLength_Message: "Certificat / Facture n° dépasse la limite autorisée de 50 caractères.",
	MAB_VHC_ContactUs_Email_InputReason: "Raison de nous contacter*",
	MAB_VHC_ContactUs_Email_InputShortDescription: "Brève description*",
	MAB_VHC_ContactUs_Email_InputShortDescription_Required_Message: "Une brève description est requise.",
	MAB_VHC_ContactUs_Email_InputShortDescription_MaxLength_Message: "La brève description dépasse la limite autorisée de 100 caractères.",
	MAB_VHC_ContactUs_Email_InputDescription: "Description*",
	MAB_VHC_ContactUs_Email_InputDescription_Required_Message: "Une description est requise.",
	MAB_VHC_ContactUs_Email_InputDescription_MaxLength_Message: "La description dépasse la limite autorisée de 1 000 caractères.",
	MAB_VHC_ContactUs_Email_InputQuestion: "Question*",
	MAB_VHC_ContactUs_Email_InputQuestion_Required_Message: "Une question est requise.",
	MAB_VHC_ContactUs_Email_InputQuestion_MaxLength_Message: "La question dépasse la limite autorisée de 100 caractères.",
	MAB_VHC_ContactUs_Email_Urgency: "Urgence",
	MAB_VHC_ContactUs_Email_Urgency_Message: "Je ne peux pas continuer à travailler.",
	MAB_VHC_ContactUs_Email_DataProtection: "Avis de protection des données*",
	MAB_VHC_ContactUs_Email_DataProtection_Description: "J'ai lu la <a href='{{data_protection_link}}' target='_blank'>Politique de confidentialité.*</a>",
	MAB_VHC_ContactUs_Email_SecurityCheck: "Contrôle de sécurité",
	MAB_VHC_ContactUs_Email_SecurityCodeInvalid: "La réponse textuelle captcha que vous avez saisie est incorrecte.",
	MAB_VHC_ContactUs_Email_SecurityCodeConfirm: "Veuillez saisir les caractères corrects que vous voyez dans la case ci-dessus.",
	MAB_VHC_ContactUs_Email_SuccessMessage: "Merci de nous avoir contactés ! Votre message a été envoyé et nous vous répondrons sous peu.",
	MAB_VHC_ContactUs_Submit_Error_Message: "Nous n'avons pas pu envoyer l'e-mail en raison d'un problème. Veuillez vous assurer que les détails sont corrects et réessayer.",
	MAB_VHC_ContactUs_Reasons_Incident: "Incident",
	MAB_VHC_ContactUs_Reasons_Bug: "Bug",
	MAB_VHC_ContactUs_Reasons_Question: "Question",
	MAB_VHC_ContactUs_Reasons_FeedbackFeatureRequest: "Commentaires et demande de fonctionnalité",
	MAB_VHC_ContactUs_Reasons_Incident_Form_Text: "Avez-vous des problèmes ? Ou est-ce que quelque chose vous empêche de continuer à travailler ? N'hésitez pas à nous contacter.",
	MAB_VHC_ContactUs_Reason_Incident_Topic: "Étape du processus affectée par l'incident*",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleIdentification: "Identification du véhicule",
	VHC_CONTACT_INCIDENT_TOPIC_AccessToTheService: "Accès au service",
	VHC_CONTACT_INCIDENT_TOPIC_StartingTheService: "Démarrage du service",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleDataReadout: "Lecture des données du véhicule",
	VHC_CONTACT_INCIDENT_TOPIC_CheckoutAndInvoicing: "Paiement et facturation",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentPreview: "Aperçu du document",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentDelivery: "Remise des documents",
	VHC_CONTACT_INCIDENT_TOPIC_Others: "Autres",
	MAB_VHC_ContactUs_Reasons_Bug_Form_Text: "Vous avez trouvé un bug ? N'hésitez pas à le partager avec nous.",
	MAB_VHC_ContactUs_Reason_Bug_Topic: "Étape du processus affectée par l'incident*",
	MAB_VHC_ContactUs_Reasons_Question_Form_Text: "Vous avez une question concernant notre service ? Votre question n'a pas été couverte par nos <a href='{{faq_link}}' target='_blank'>FAQ</a> ? Demandez notre soutien.",
	MAB_VHC_ContactUs_Reason_Question_Topic: "Sujet*",
	MAB_VHC_ContactUs_Reason_Question_Topic_ServiceContent: "Contenu des services",
	MAB_VHC_ContactUs_Reason_Question_Topic_VehicleCoverage: "Couverture du véhicule",
	MAB_VHC_ContactUs_Reason_Question_Topic_CountryCoverage: "Couverture nationale",
	MAB_VHC_ContactUs_Reason_Question_Topic_CheckoutProcess: "Processus de paiement",
	MAB_VHC_ContactUs_Reason_Question_Topic_Pricing: "Tarifs",
	MAB_VHC_ContactUs_Reason_Question_Topic_HardwareSoftware: "Matériel/logiciel",
	MAB_VHC_ContactUs_Reason_Question_Topic_Others: "Autres",
	MAB_VHC_ContactUs_Reasons_Feature_Form_Text: "Il vous manque une certaine fonctionnalité ? Ou souhaitez-vous fournir des commentaires directs ? N'hésitez pas à nous contacter.",
	MAB_VHC_SubmitButton: "Soumettre",
	MAB_VHC_CloseButton: "Fermer",
};
