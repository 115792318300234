import BrowserVersionNotSupport from "../components/BrowserVersionNotSupport/BrowserVersionNotSupport";
import MaintenancePage from "../components/Maintaining/MaintenancePage";
import style from "./AdsApp.module.scss";
import { useEffect, useState } from "react";
import { AuthService } from "../services/AuthService";
import { useAppDispatch } from "../redux/hooks";
import { getRegionsAsync, setRediRegions } from "../redux/region";
import { AuthState, authOverrideInfo, authSetNewState } from "../redux/auth";
import {
  ADS_COUNTRY_CODE_SEARCH_QUERY_KEY,
  ADS_LANGUAGE_SEARCH_QUERY_KEY,
} from "./constants/adsNewRequest.constant";
import AdsInvalidParamModal from "./components/adsInvalidParamModal/AdsInvalidParamModal";
import { Region } from "../models/RegionState";
import CustomLoader from "../components/loading/CustomLoader";
import AdsRouter from "./AdsRouter";
import { ADS_SIGN_IN_CALLBACK_ROUTE } from "./constants/adsRoutes.constant";
import "@bosch/frontend.kit-npm/dist/frontend-kit.css";
import ct from "countries-and-timezones";
import { findWindows } from "windows-iana";
import useCaseInsensitiveSearchParams from "../hooks/useCaseInsensitiveSearchParams";
import { useTranslation } from "@translationsContexts/i18nContext";

const AdsApp = () => {
  const isIE = !!(document as any).documentMode;
  const isMaintainingMode =
    !!process.env.REACT_APP_ISMAINTAINING &&
    process.env.REACT_APP_ISMAINTAINING.toLowerCase() === "on";
  const dispatch = useAppDispatch();
  const { getParam } = useCaseInsensitiveSearchParams();
  const countryCodeParam = getParam(ADS_COUNTRY_CODE_SEARCH_QUERY_KEY);
  const languageParam = getParam(ADS_LANGUAGE_SEARCH_QUERY_KEY);
  const [isShowInvalidParamModal, setShowInvalidParamModal] = useState(false);
  const [isShowLoader, setShowLoader] = useState(true);
  const { changeLanguage } = useTranslation();

  useEffect(() => {
    if (isIE || isMaintainingMode) return;

    try {
      setUpAuthState();
    } catch {
      setShowLoader(false);
    }
  }, []);

  const setUpAuthState = async () => {
    if (window.location.pathname.includes(ADS_SIGN_IN_CALLBACK_ROUTE.path))
      return;

    const regionsResponse = await dispatch(getRegionsAsync());

    if (!getRegionsAsync.fulfilled.match(regionsResponse)) return;
    if (!regionsResponse.payload || !regionsResponse.payload.dataObject) return;

    const allRegions = regionsResponse.payload.dataObject;

    AuthService.setRegionsLanguages(allRegions);
    dispatch(setRediRegions(allRegions));

    const userRegion = allRegions.find(
      (region) =>
        region.country.trim().toLowerCase() ===
        countryCodeParam?.trim().toLowerCase()
    );

    if (!userRegion) {
      setShowInvalidParamModal(true);
      return;
    }

    const userLanguage = getUserLanguage(userRegion);

    const newAuthState = {
      language: userLanguage,
      countryCode: userRegion.country,
      countryName: userRegion.countryName,
      languages: userRegion.languages,
      timeZoneId: getTimezoneId(),
    } as AuthState;
    AuthService.setAuthState(newAuthState);
    dispatch(authSetNewState(newAuthState));
    changeLanguage(userLanguage);
    dispatch(authOverrideInfo(userRegion));
    setShowInvalidParamModal(false);
    setShowLoader(false);
  };

  const getTimezoneId = (): string => {
    if (!countryCodeParam) return "";

    let timeZoneId = "";

    if (countryCodeParam.trim().toLowerCase() === "uk") {
      const countryName = ct.getCountry("GB");
      const timeZoneName = countryName?.timezones[0];
      if (timeZoneName !== undefined) {
        timeZoneId = findWindows(timeZoneName)[0];
      }
    } else {
      const countryName = ct.getCountry(countryCodeParam);
      const timeZoneName = countryName?.timezones[0];
      if (timeZoneName !== undefined) {
        timeZoneId = findWindows(timeZoneName)[0];
      }
    }

    return timeZoneId;
  };

  const getUserLanguage = (userRegion: Region) => {
    const defaultLanguage = getDefaultLanguage(userRegion);

    if (!languageParam) return defaultLanguage;

    const matchedLanguage = userRegion.languages.find(
      (lang) =>
        lang.lcid.trim().toLowerCase() === languageParam.trim().toLowerCase()
    );

    return matchedLanguage?.lcid || defaultLanguage;
  };

  const getDefaultLanguage = (userRegion: Region | undefined): string => {
    if (!userRegion) return "";

    const language = userRegion.languages.find((l) => l.defaultLanguage);

    return language?.lcid || "";
  };

  const isAbleToShowInvalidParamModal = () => {
    return (
      (!countryCodeParam || isShowInvalidParamModal) &&
      !window.location.pathname.includes(ADS_SIGN_IN_CALLBACK_ROUTE.path)
    );
  };

  if (isIE) return <BrowserVersionNotSupport />;
  if (isMaintainingMode) return <MaintenancePage />;
  if (isAbleToShowInvalidParamModal()) return <AdsInvalidParamModal />;

  return (
    <>
      <div className={style.container}>
        <AdsRouter />
      </div>
      {isShowLoader ? <CustomLoader enable /> : <></>}
    </>
  );
};

export default AdsApp;
