import { useHistory } from "react-router-dom";
import { useTranslation } from "@translationsContexts/i18nContext";
import { Button } from "@bosch/react-frok";

const AddNewEmployeeButton = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onClickNewMemberButton = () => {
    history.push("/dashboard/team/member-creation");
  };

  return (
    <>
      <Button mode="primary" onClick={onClickNewMemberButton}>
        {t("SettingsTeam_AddNewButtonText")}
      </Button>
    </>
  );
};

export default AddNewEmployeeButton;
