import { useTranslation } from "@translationsContexts/i18nContext";
import "../translations/i18n";
import { loginAsync } from "../redux/account";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { authSetSignIn } from "../redux/auth";

const SessionTimeout = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const goToHomePage = () => {
    dispatch(authSetSignIn(false));
    setTimeout(() => {
      history.push("/");
    }, 1000);
  };

  const login = (e: any) => {
    e.preventDefault();
    dispatch(loginAsync());
  };

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <h5>{t("SessionTimeout_Title")}</h5>
      <p>
        {t("SessionTimeout_Message1")}{" "}
        <a href="#" onClick={() => goToHomePage()}>
          {t("SessionTimeout_Message2")}
        </a>{" "}
        {t("SessionTimeout_Message3")}{" "}
        <a href="#" onClick={(e: any) => login(e)}>
          {t("SessionTimeout_Message4")}
        </a>{" "}
        {t("SessionTimeout_Message5")}
      </p>
    </div>
  );
};

export default SessionTimeout;
