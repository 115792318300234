import { useState, Fragment, useEffect, useRef } from "react";
import { useMenuHeaderConfiguration } from "../../../hooks/pageConfiguration/useMenuHeaderConfiguration";
import style from "./NewMenuHeader.module.scss";
import { Button, Divider, Icon, Menu } from "semantic-ui-react";
import { useTranslation } from "@translationsContexts/i18nContext";
import {
  MenuHeaderItem,
  MinimalMenuHeaderItem,
} from "../../../models/pageConfiguration/MenuHeaderModel";
import { ReactComponent as MenuIcon } from "../../../assets/svg/menu.svg";
import { ReactComponent as CloseIcon } from "../../../assets/svg/close.svg";
import { ReactComponent as RegionIcon } from "../../../assets/svg/locator.svg";
import BoschIcon from "../../BoschIcon/Boschicon";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCountryName } from "../../../redux/auth";
import { useMenuItemSelected } from "../../../hooks/useMenuItemSelected";
import RDAlert from "../../RDComponents/RDAlert";

type Props = {
  openConfirmLogoutModal: Function;
};

const NewMenuHeader = ({ openConfirmLogoutModal }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isMenuItemSelected = useMenuItemSelected();
  const { menuHeaderConfig } = useMenuHeaderConfiguration({
    openConfirmLogoutModal,
  });
  const countryName = useSelector(selectCountryName);
  const [isShowFullMenuHeader, setShowFullMenuHeader] = useState(false);
  const [selectedLv1Item, setSelectedLv1Item] =
    useState<MinimalMenuHeaderItem | null>(null);
  const [isShowChangeRegionModal, setShowChangeRegionModal] = useState(false);
  const headerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        headerRef.current &&
        !headerRef.current.contains(event.target as Node)
      ) {
        setSelectedLv1Item(null);
        setShowFullMenuHeader(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (menuHeaderConfig) {
      openSelectedChildMenu();
    }
  }, [window.location.href, menuHeaderConfig]);

  const openSelectedChildMenu = () => {
    const selectedItem = menuHeaderConfig.find((item) =>
      isMenuItemSelected(item)
    );
    if (selectedItem) {
      setSelectedLv1Item(selectedItem);
    }
  };

  const isChildrenOfMenuItemValid = (menuItem: MinimalMenuHeaderItem) => {
    return menuItem.childrenItems && menuItem.childrenItems.length > 0;
  };

  const toggleMenuHeader = (mode?: "hide" | "show") => {
    const defaultSelectedItem: MinimalMenuHeaderItem | null = selectedLv1Item
      ? selectedLv1Item
      : null;

    if (mode) {
      setShowFullMenuHeader(mode === "hide" ? false : true);
      setSelectedLv1Item(mode === "hide" ? null : defaultSelectedItem);
      return;
    }

    setShowFullMenuHeader(!isShowFullMenuHeader);
    setSelectedLv1Item(selectedLv1Item ? null : defaultSelectedItem);
  };

  const handleMenuItemLv2Clicked = (menuItemLv2: MinimalMenuHeaderItem) => {
    if (menuItemLv2.redirectUrl) {
      history.push(menuItemLv2.redirectUrl);
      setShowFullMenuHeader(false);
    }
  };

  const handleMenuItemLv1Clicked = (menuItemLv1: MinimalMenuHeaderItem) => {
    setSelectedLv1Item(menuItemLv1);

    if (menuItemLv1.onClick) {
      menuItemLv1.onClick();
      setSelectedLv1Item(null);
      setShowFullMenuHeader(false);
      return;
    }

    if (menuItemLv1.redirectUrl) {
      history.push(menuItemLv1.redirectUrl);
      setSelectedLv1Item(null);
      setShowFullMenuHeader(false);
      return;
    }

    if (isChildrenOfMenuItemValid(menuItemLv1)) {
      setShowFullMenuHeader(true);
    }
  };

  const renderMinimalMenuItems = () => {
    return menuHeaderConfig.map((menuItem) => {
      if (menuItem.isShowOnMinimalMenu) {
        return (
          <Menu.Item
            key={menuItem.key}
            className={`${style.menu_header_item} ${
              isMenuItemSelected(menuItem)
                ? style.menu_header_item_selected
                : ""
            }`}
            content={t(menuItem.label)}
            onClick={() => handleMenuItemLv1Clicked(menuItem)}
          />
        );
      }

      return <Fragment />;
    });
  };

  const renderAllMenuItems = (
    menuItems: MinimalMenuHeaderItem[],
    isShowArrowIcon: boolean,
    onMenuItemClick: (item: MinimalMenuHeaderItem) => void
  ) => {
    return menuItems.map((menuItem) => {
      const isChildrenValid = isChildrenOfMenuItemValid(menuItem);
      let baseMenuItemClassName = `${style.full_menu_header_col_button}`;

      if (isChildrenValid)
        baseMenuItemClassName += ` ${style.full_menu_header_col_button_has_children}`;

      if (isMenuItemSelected(menuItem))
        baseMenuItemClassName += ` ${style.full_menu_header_col_button_selected}`;

      if (!menuItem.isShowOnMinimalMenu)
        baseMenuItemClassName += ` ${style.full_menu_header_col_button_with_arrow}`;

      return (
        <Fragment key={menuItem.key}>
          <Button
            basic
            className={baseMenuItemClassName}
            content={<span>{t(menuItem.label)}</span>}
            icon={
              (isChildrenValid || !menuItem.isShowOnMinimalMenu) &&
              isShowArrowIcon ? (
                <BoschIcon name="bosch-forward-right" />
              ) : (
                <></>
              )
            }
            onClick={() => onMenuItemClick(menuItem)}
          />
          {menuItem.isTheEndOfBlock ? (
            <Divider className={style.full_menu_header_col_divider} />
          ) : (
            <></>
          )}
        </Fragment>
      );
    });
  };

  const renderAllMenuItemsLv1 = () => {
    return (
      <div
        className={`${style.full_menu_header_col} ${style.full_menu_header_col_with_divider}`}
      >
        {renderAllMenuItems(menuHeaderConfig, true, handleMenuItemLv1Clicked)}
        <Button
          basic
          className={`${style.full_menu_header_col_button} ${style.full_menu_header_region_button}`}
          content={<span>{countryName}</span>}
          icon={
            <Icon>
              <RegionIcon />
            </Icon>
          }
          onClick={() => setShowChangeRegionModal(true)}
        />
      </div>
    );
  };

  const renderAllMenuItemsLv2 = () => {
    if (!selectedLv1Item || !isChildrenOfMenuItemValid(selectedLv1Item))
      return <div className={style.full_menu_header_col}></div>;

    return (
      <div
        className={`${style.full_menu_header_col} ${style.full_menu_header_col_lv_2} ${style.full_menu_header_col_with_divider}`}
      >
        <p className={style.full_menu_header_col_title}>
          {t(selectedLv1Item.label)}
        </p>
        {renderAllMenuItems(
          selectedLv1Item.childrenItems as MenuHeaderItem[],
          false,
          handleMenuItemLv2Clicked
        )}
      </div>
    );
  };

  return (
    <>
      <div ref={headerRef} className={style.menu_header}>
        <Menu
          pointing
          secondary
          stackable
          className={style.minimal_menu_header}
        >
          <Button
            basic
            className={style.toggle_menu_btn}
            onClick={() => toggleMenuHeader()}
            icon={
              <Icon>{isShowFullMenuHeader ? <CloseIcon /> : <MenuIcon />}</Icon>
            }
          />
          {renderMinimalMenuItems()}
        </Menu>
        <div className={style.menu_header_title}>{t("Title_Main")}</div>
        {isShowFullMenuHeader ? (
          <div className={style.full_menu_header}>
            {renderAllMenuItemsLv1()}
            {renderAllMenuItemsLv2()}
            <div className={style.full_menu_header_col}></div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {isShowChangeRegionModal ? (
        <RDAlert
          type="warning"
          isOpen={true}
          // title={t("Popup_Title_Warning")}
          messages={[
            `${t("Header_CurrentRegion")} ${countryName}.&nbsp;`,
            t("Header_ChangeRegions"),
          ]}
          acceptButtonText={t("Header_YesButton")}
          onAccept={() => {
            history.push("/regions");
          }}
          cancelButtonText={t("Header_NoButton")}
          onCancel={() => setShowChangeRegionModal(false)}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default NewMenuHeader;
