export const MAB_VHC_TRANSLATIONS_ES_ES = {
	MAB_VHC_ContactUs_Headline: "Contáctenos",
	MAB_VHC_ContactUs_HowCanWeHelp: "¿Cómo podemos ayudarle?",
	MAB_VHC_ContactUs_Email_Headline: "¡Envíe un correo electrónico!",
	MAB_VHC_ContactUs_Email_Description: "Envíenos su mensaje llenando el siguiente formulario. Uno de nuestros asesores estará encantado de ponerse en contacto con usted.",
	MAB_VHC_ContactUs_Email_InputName: "Nombre*",
	MAB_VHC_ContactUs_Email_InputName_Required_Message: "El nombre es obligatorio.",
	MAB_VHC_ContactUs_Email_InputName_MaxLength_Message: "El nombre supera el límite permitido de 100 caracteres.",
	MAB_VHC_ContactUs_Email_InputEmail: "Dirección de correo electrónico*",
	MAB_VHC_ContactUs_Email_InputEmail_Required_Message: "Se requiere dirección de correo electrónico.",
	MAB_VHC_ContactUs_Email_InputEmail_MaxLength_Message: "La dirección de correo electrónico supera el límite permitido de 100 caracteres.",
	MAB_VHC_ContactUs_Email_InputEmail_Invalid_Message: "Formato de correo electrónico no válido.",
	MAB_VHC_ContactUs_Email_InputCompany: "Información de la empresa*",
	MAB_VHC_ContactUs_Email_InputCompany_Required_Message: "Se requiere información de la empresa.",
	MAB_VHC_ContactUs_Email_InputCompany_MaxLength_Message: "La información de la empresa supera el límite permitido de 100 caracteres.",
	MAB_VHC_ContactUs_Email_InputAddress: "Dirección*",
	MAB_VHC_ContactUs_Email_InputAddress_Required_Message: "Se requiere dirección.",
	MAB_VHC_ContactUs_Email_InputAddress_MaxLength_Message: "La dirección supera el límite permitido de 250 caracteres.",
	MAB_VHC_ContactUs_Email_InputZipCode: "Código postal*",
	MAB_VHC_ContactUs_Email_InputZipCode_Required_Message: "Se requiere código postal.",
	MAB_VHC_ContactUs_Email_InputZipCode_MaxLength_Message: "El código postal excede el límite permitido de 25 caracteres.",
	MAB_VHC_ContactUs_Email_InputCity: "Ciudad*",
	MAB_VHC_ContactUs_Email_InputCity_Required_Message: "Se requiere ciudad.",
	MAB_VHC_ContactUs_Email_InputCity_MaxLength_Message: "La ciudad supera el límite permitido de 100 caracteres.",
	MAB_VHC_ContactUs_Email_InputCountry: "País*",
	MAB_VHC_ContactUs_Email_InputCountry_Required_Message: "Se requiere país.",
	MAB_VHC_ContactUs_Email_InputCountry_MaxLength_Message: "El país supera el límite permitido de 100 caracteres.",
	MAB_VHC_ContactUs_Email_InputCertNo: "Número de Certificado / Factura *",
	MAB_VHC_ContactUs_Email_InputCertNo_Required_Message: "Se requiere Número de Certificado / Factura.",
	MAB_VHC_ContactUs_Email_InputCertNo_MaxLength_Message: "Número de Certificado / Factura excede el límite permitido de 50 caracteres.",
	MAB_VHC_ContactUs_Email_InputReason: "Razón para contactarnos*",
	MAB_VHC_ContactUs_Email_InputShortDescription: "Breve descripción*",
	MAB_VHC_ContactUs_Email_InputShortDescription_Required_Message: "Se requiere una breve descripción.",
	MAB_VHC_ContactUs_Email_InputShortDescription_MaxLength_Message: "La descripción breve supera el límite permitido de 100 caracteres.",
	MAB_VHC_ContactUs_Email_InputDescription: "Descripción*",
	MAB_VHC_ContactUs_Email_InputDescription_Required_Message: "Se requiere descripción.",
	MAB_VHC_ContactUs_Email_InputDescription_MaxLength_Message: "La descripción supera el límite permitido de 1000 caracteres.",
	MAB_VHC_ContactUs_Email_InputQuestion: "Pregunta*",
	MAB_VHC_ContactUs_Email_InputQuestion_Required_Message: "Se requiere pregunta.",
	MAB_VHC_ContactUs_Email_InputQuestion_MaxLength_Message: "La pregunta excede el límite permitido de 100 caracteres.",
	MAB_VHC_ContactUs_Email_Urgency: "Urgencia",
	MAB_VHC_ContactUs_Email_Urgency_Message: "No puedo seguir trabajando.",
	MAB_VHC_ContactUs_Email_DataProtection: "Aviso de protección de datos*",
	MAB_VHC_ContactUs_Email_DataProtection_Description: "He leído la <a href='{{data_protection_link}}' target='_blank'>Política de Privacidad.*</a>",
	MAB_VHC_ContactUs_Email_SecurityCheck: "Control de seguridad",
	MAB_VHC_ContactUs_Email_SecurityCodeInvalid: "La respuesta de texto captcha que ha introducido es incorrecta.",
	MAB_VHC_ContactUs_Email_SecurityCodeConfirm: "Ingrese los caracteres correctos que ve en el cuadro de arriba.",
	MAB_VHC_ContactUs_Email_SuccessMessage: "¡Gracias por contactarnos! Su mensaje ha sido enviado y nos comunicaremos con usted en breve.",
	MAB_VHC_ContactUs_Submit_Error_Message: "No pudimos enviar el correo electrónico debido a un problema. Asegúrese de que los detalles sean correctos y vuelva a intentarlo.",
	MAB_VHC_ContactUs_Reasons_Incident: "Incidente",
	MAB_VHC_ContactUs_Reasons_Bug: "Error de software",
	MAB_VHC_ContactUs_Reasons_Question: "Pregunta",
	MAB_VHC_ContactUs_Reasons_FeedbackFeatureRequest: "Comentarios y solicitud de funciones",
	MAB_VHC_ContactUs_Reasons_Incident_Form_Text: "¿Tiene problemas? ¿O hay algo que le impide seguir trabajando? No dude en ponerse en contacto con nosotros.",
	MAB_VHC_ContactUs_Reason_Incident_Topic: "Paso del proceso afectado por el incidente*",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleIdentification: "Identificación del vehículo",
	VHC_CONTACT_INCIDENT_TOPIC_AccessToTheService: "Acceso al servicio",
	VHC_CONTACT_INCIDENT_TOPIC_StartingTheService: "Iniciando el servicio",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleDataReadout: "Lectura de datos del vehículo",
	VHC_CONTACT_INCIDENT_TOPIC_CheckoutAndInvoicing: "Pago y facturación",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentPreview: "Vista previa del documento",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentDelivery: "Entrega del documento",
	VHC_CONTACT_INCIDENT_TOPIC_Others: "Otros",
	MAB_VHC_ContactUs_Reasons_Bug_Form_Text: "¿Ha encontrado un error? No dude en compartirlo con nosotros.",
	MAB_VHC_ContactUs_Reason_Bug_Topic: "Paso del proceso afectado por el incidente*",
	MAB_VHC_ContactUs_Reasons_Question_Form_Text: "¿Tiene alguna pregunta sobre nuestro servicio? ¿Su pregunta no se responde en nuestras <a href='{{faq_link}}' target='_blank'>Preguntas frecuentes</a>? Pregunte a nuestro soporte.",
	MAB_VHC_ContactUs_Reason_Question_Topic: "Tema*",
	MAB_VHC_ContactUs_Reason_Question_Topic_ServiceContent: "Contenido del servicio",
	MAB_VHC_ContactUs_Reason_Question_Topic_VehicleCoverage: "Cobertura del vehículo",
	MAB_VHC_ContactUs_Reason_Question_Topic_CountryCoverage: "Cobertura de países",
	MAB_VHC_ContactUs_Reason_Question_Topic_CheckoutProcess: "Proceso de pago",
	MAB_VHC_ContactUs_Reason_Question_Topic_Pricing: "Precios",
	MAB_VHC_ContactUs_Reason_Question_Topic_HardwareSoftware: "Hardware / software",
	MAB_VHC_ContactUs_Reason_Question_Topic_Others: "Otros",
	MAB_VHC_ContactUs_Reasons_Feature_Form_Text: "¿Echa en falta alguna función? ¿O quiere hacernos llegar sus comentarios? No dude en ponerse en contacto con nosotros.",
	MAB_VHC_SubmitButton: "Enviar",
	MAB_VHC_CloseButton: "Cerrar",
};
