import { FC, Fragment } from "react";
import { useTranslation } from "@translationsContexts/i18nContext";
import { useSelector } from "react-redux";
import { selectShoppingCart } from "../../../../../redux/shoppingCartV3Slice";
import { selectCountryCode } from "../../../../../redux/auth";
import { useCurrencyFormat } from "../../../../../hooks/useCurrencyFormat";
import { CartItemPrice } from "../../../../../models/ShoppingCart/ShoppingCartItem";
import Styles from "./ShoppingCartDetailCheckoutInfo.module.css";
import { useCartSubscription } from "../../../../../hooks/useCartSubscription";

interface CheckoutContentTotalPriceProps {}

const CheckoutContentTotalPrice: FC<CheckoutContentTotalPriceProps> = () => {
  const { t } = useTranslation();
  const countryCode = useSelector(selectCountryCode);
  const shoppingCart = useSelector(selectShoppingCart);
  const format = useCurrencyFormat();
  const { isPrepaidVoucher } = useCartSubscription();

  const getPriceTag = (
    voucherCode: string,
    netPrice: CartItemPrice,
    originalPrice: CartItemPrice | null,
    isAddOn: boolean = false
  ) => {
    return (
      <>
        {!!voucherCode &&
        (!!shoppingCart?.validVoucher?.isCashRebate || isAddOn) ? (
          <>
            {format(
              netPrice.netPrice,
              netPrice.currency?.symbol,
              countryCode
            ) !==
              format(
                netPrice.voucherAppliedPrice,
                netPrice.currency?.symbol || "",
                countryCode
              ) && (
              <span className={Styles.discount_price}>
                {format(
                  netPrice.netPrice,
                  netPrice.currency?.symbol,
                  countryCode
                )}
              </span>
            )}
            <span>
              {format(
                netPrice.voucherAppliedPrice,
                netPrice.currency?.symbol || "",
                countryCode
              )}
            </span>
          </>
        ) : (
          <>
            {originalPrice && originalPrice.netPrice !== netPrice.netPrice && (
              <span className={Styles.discount_price}>
                {format(
                  originalPrice.netPrice,
                  originalPrice.currency?.symbol,
                  countryCode
                )}
              </span>
            )}
            <span>
              {format(
                netPrice.netPrice,
                netPrice.currency?.symbol || "",
                countryCode
              )}
            </span>
          </>
        )}
      </>
    );
  };

  const getActualPriceTag = (netPrice: CartItemPrice) => {
    return (
      <span>
        {format(
          netPrice.actualPrice,
          netPrice.currency?.symbol || "",
          countryCode
        )}
      </span>
    );
  };

  return (
    <Fragment>
      {!isPrepaidVoucher && (
        <>
          <div className={Styles.content_row}>
            <div className={Styles.field_label}>
              {t("ShoppingCartDetailCheckoutInfo_Subtotal_Label")}
            </div>
            {shoppingCart?.cartPrice && (
              <div className={Styles.field_value}>
                {getPriceTag(
                  shoppingCart.voucherCode,
                  shoppingCart.cartPrice,
                  shoppingCart.originalCartPrice
                )}
              </div>
            )}
          </div>
          <div className={Styles.content_row}>
            <div className={Styles.field_label}>
              {t("ShoppingCartDetailCheckoutInfo_Taxes_Label")}
            </div>
            {shoppingCart?.cartPrice && (
              <div className={Styles.field_value}>
                {format(
                  shoppingCart?.cartPrice?.vatAmmout,
                  shoppingCart?.cartPrice?.currency?.symbol,
                  countryCode
                )}
              </div>
            )}
          </div>
        </>
      )}
      <div className={Styles.content_row}>
        <div
          className={`${Styles.field_label} ${Styles.title} ${Styles.total_amount}`}
        >
          {t("ShoppingCartDetailCheckoutInfo_Total_Label")}
          {shoppingCart?.cartPrice && !isPrepaidVoucher && (
            <span>
              {t("ShoppingCartDetailCheckoutInfo_IncludeVAT_Notes").replace(
                "[taxes_amount]",
                format(
                  shoppingCart?.cartPrice.vatAmmout,
                  shoppingCart?.cartPrice.currency?.symbol,
                  countryCode
                )
              )}
            </span>
          )}
        </div>
        {shoppingCart?.cartPrice && (
          <div className={Styles.field_value}>
            {isPrepaidVoucher
              ? t("CheckoutContentTotalPrice_PrepaidPriceText")
              : getActualPriceTag(shoppingCart.cartPrice)}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default CheckoutContentTotalPrice;
