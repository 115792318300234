import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  authSetLanguage,
  selectAuthState,
  selectLanguage,
  selectMainContentPinned,
  setOpenSideNav,
} from "../../../redux/auth";
import style from "./LangSelection.module.scss";
import { useAppDispatch } from "../../../redux/hooks";
import { Icon } from "@bosch/react-frok";
import RDDropdown from "../../../components/RDComponents/RDDropdown";
import { LanguageOption } from "../../../models/pageConfiguration/LanguageModel";
import { handleClickedOutOfComponent } from "../../../utils/dom.util";
import { STATIC_SIDE_NAV_ITEM } from "@constants/sideNav.constant";
import RDSideNavigationItem from "@components/RDComponents/RDSideNavigationItem/RDSideNavigationItem";
import { useTranslation } from "@translationsContexts/i18nContext";

const LangSelection = () => {
  const dispatch = useAppDispatch();
  const authState = useSelector(selectAuthState);
  const language = useSelector(selectLanguage);
  const isMainContentPinned = useSelector(selectMainContentPinned);
  const langSelectionRef = useRef<HTMLDivElement | null>(null);
  const [currentLanguage, setCurrentLanguage] = useState("");
  const [isShowLangSelection, setShowLangSelection] = useState(false);
  const { changeLanguage, t } = useTranslation();


  useEffect(() => {
    setCurrentLanguage(language);
  }, [language]);

  useEffect(() => {
    const checkFunction = (e: MouseEvent) =>
      handleClickedOutOfComponent(e, langSelectionRef, () =>
        setShowLangSelection(false)
      );

    document.addEventListener("mousedown", checkFunction);

    return () => {
      document.removeEventListener("mousedown", checkFunction);
    };
  }, []);

  const getLangOptions = () => {
    return !!authState.languages && authState.languages.length > 0
      ? authState.languages.map((x) => {
          return {
            key: x.localized,
            text: x.localized,
            value: x.lcid,
          };
        })
      : [
          {
            key: "English",
            text: "English",
            value: "en-US",
          },
        ];
  };

  const switchLanguage = (data: any) => {
    if (!isMainContentPinned) dispatch(setOpenSideNav(false));

    setCurrentLanguage(data);
    changeLanguage(data.trim());
    dispatch(authSetLanguage(data));
    setShowLangSelection(false);
  };

  if (!authState.languages) return <></>;

  return (
    <div className={style.container}>
      <RDSideNavigationItem
        icon="globe-language"
        label={t("MyAccount_Language")}
        title={t("MyAccount_Language")}
        className={style.option}
        onClick={() => setShowLangSelection((prev) => !prev)}
        aria-label={STATIC_SIDE_NAV_ITEM}
      />
      <div
        ref={langSelectionRef}
        className={`${style.lang_selection} ${
          !!isShowLangSelection ? style.show : ""
        }`}
      >
        <div className={style.header}>
          <p className={style.title}>
            {t("LanguageSelection_SelectALanguage")}
          </p>
          <Icon iconName="close" onClick={() => setShowLangSelection(false)} />
        </div>
        <RDDropdown
          value={currentLanguage}
          defaultValue={currentLanguage}
          options={getLangOptions()}
          onChange={(_, data: any) =>
            switchLanguage((data as LanguageOption).value)
          }
        />
      </div>
    </div>
  );
};

export default LangSelection;
