import { ReactNode, useEffect, useState } from "react";
import {
  DEFAULT_TRANSLATIONS,
  I18N_INIT_STATE,
  I18nContext,
  LanguageTranslationType,
} from "../contexts/i18nContext";
import { getLanguageTranslationByLcid } from "@services/TranslationToolService";
import { TRANSLATIONS_APPID } from "@constants/translationTool.constants";
import { useAppDispatch } from "@redux/store";
import { MAB_VHC_TRANSLATIONS_DE_DE } from "../languageKeys/vhc/de-DE/translations";
import { MAB_VHC_TRANSLATIONS_EN } from "../languageKeys/vhc/en/translations";
import { MAB_VHC_TRANSLATIONS_ES_ES } from "../languageKeys/vhc/es-ES/translations";
import { MAB_VHC_TRANSLATIONS_NL_NL } from "../languageKeys/vhc/nl-NL/translations";
import { MAB_VHC_TRANSLATIONS_NB_NO } from "../languageKeys/vhc/nb-NO/translations";
import { MAB_VHC_TRANSLATIONS_SV_SE } from "../languageKeys/vhc/sv-SE/translations";
import { MAB_VHC_TRANSLATIONS_FR_FR } from "../languageKeys/vhc/fr-FR/translations";
import { MAB_VHC_TRANSLATIONS_CS_CZ } from "../languageKeys/vhc/cs-CZ/translations";
import { MAB_VHC_TRANSLATIONS_IT_IT } from "../languageKeys/vhc/it-IT/translations";
import { MAB_VHC_TRANSLATIONS_PL_PL } from "../languageKeys/vhc/pl-PL/translations";
import { MAB_VHC_TRANSLATIONS_PT_PT } from "../languageKeys/vhc/pt-PT/translations";
import { MAB_VHC_TRANSLATIONS_BG_BG } from "../languageKeys/vhc/bg-BG/translations";
import { MAB_VHC_TRANSLATIONS_DA_DK } from "../languageKeys/vhc/da-DK/translations";
import { MAB_VHC_TRANSLATIONS_FI_FI } from "../languageKeys/vhc/fi-FI/translations";
import { MAB_VHC_TRANSLATIONS_HR_HR } from "../languageKeys/vhc/hr-HR/translations";
import { MAB_VHC_TRANSLATIONS_HU_HU } from "../languageKeys/vhc/hu-HU/translations";
import { MAB_VHC_TRANSLATIONS_RO_RO } from "../languageKeys/vhc/ro-RO/translations";
import { MAB_VHC_TRANSLATIONS_TR_TR } from "../languageKeys/vhc/tr-TR/translations";

const I18nProvider = ({ children }: { children: ReactNode }) => {
  const [isLoadingTranslations, setLoadingTranslations] = useState(
    I18N_INIT_STATE.isLoadingTranslations
  );
  const [currentLcid, setCurrentLcid] = useState(I18N_INIT_STATE.currentLcid);
  const [defaultTranslations, setDefaultTranslations] =
    useState<LanguageTranslationType>(I18N_INIT_STATE.defaultTranslations);
  const [localizedTranslations, setLocalizedTranslations] =
    useState<LanguageTranslationType>(I18N_INIT_STATE.localizedTranslations);

  useEffect(() => {
    initReactI18next("");
  }, []);

  const dispatch = useAppDispatch();

  const loadDefaultTranslations = (): LanguageTranslationType => {
    return { ...MAB_VHC_TRANSLATIONS_EN, ...DEFAULT_TRANSLATIONS };
  };

  const convertString = (str: string) => {
    // Check if the string already contains a dash
    if (!str.includes('-')) {
      // If no dash, make the string uppercase and append it after a dash
      return str + '-' + str.toUpperCase();
    } else {
      // If it contains a dash, just return the string as is
      return str;
    }
  }

  const loadLocalizedLanguages = async (
    localizedLanguageLcid?: string
  ): Promise<LanguageTranslationType> => {
    if (!localizedLanguageLcid) {
      return {};
    }

    const normalizedLocalizedLanguageLcid = localizedLanguageLcid.trim();

    if (!normalizedLocalizedLanguageLcid) {
      return { ...MAB_VHC_TRANSLATIONS_EN, ...DEFAULT_TRANSLATIONS };
    }

    //Convert lcid singel solitary;
    let convertedLcid = convertString(normalizedLocalizedLanguageLcid);


    setCurrentLcid(convertedLcid);

    try {
      const getLanguageTranslationByLcidResponse = await dispatch(
        getLanguageTranslationByLcid({
          appId: TRANSLATIONS_APPID.RDS,
          lcid: convertedLcid,
        })
      );

      if (getLanguageTranslationByLcid.fulfilled.match(getLanguageTranslationByLcidResponse)) {
        if (!!getLanguageTranslationByLcidResponse.payload && !!getLanguageTranslationByLcidResponse.payload.dataObject) {
          const translateValues = getLanguageTranslationByLcidResponse.payload?.dataObject?.translations;
          const isEmpty = Object.keys(translateValues).length === 0;
          let VHCTranslation = MAB_VHC_TRANSLATIONS_EN;
          //Check VHC Lcid
          switch (convertedLcid) {
            case "de":
            case "de-DE":
              VHCTranslation = MAB_VHC_TRANSLATIONS_DE_DE
              break;
            case "es-ES":
            case "es":
              VHCTranslation = MAB_VHC_TRANSLATIONS_ES_ES;
              break;
            case "nl-NL":
            case "nl":
              VHCTranslation = MAB_VHC_TRANSLATIONS_NL_NL;
              break;
            case "nb-NO":
            case "nb":
              VHCTranslation = MAB_VHC_TRANSLATIONS_NB_NO;
              break;
            case "sv-SE":
            case "sv":
              VHCTranslation = MAB_VHC_TRANSLATIONS_SV_SE;
              break;
            case "fr-FR":
            case "fr":
              VHCTranslation = MAB_VHC_TRANSLATIONS_FR_FR;
              break;
            case "cs-CZ":
            case "cs":
              VHCTranslation = MAB_VHC_TRANSLATIONS_CS_CZ;
              break;
            case "it-IT":
            case "it":
              VHCTranslation = MAB_VHC_TRANSLATIONS_IT_IT;
              break;
            case "pl-PL":
            case "pl":
              VHCTranslation = MAB_VHC_TRANSLATIONS_PL_PL;
              break;
            case "pt-PT":
            case "pt":
              VHCTranslation = MAB_VHC_TRANSLATIONS_PT_PT;
              break;
            case "bg-BG":
            case "bg":
              VHCTranslation = MAB_VHC_TRANSLATIONS_BG_BG;
              break;
            case "da-DK":
            case "da":
              VHCTranslation = MAB_VHC_TRANSLATIONS_DA_DK;
              break;
            case "fi-FI":
            case "fi":
              VHCTranslation = MAB_VHC_TRANSLATIONS_FI_FI;
              break;
            case "hr-HR":
            case "hr":
              VHCTranslation = MAB_VHC_TRANSLATIONS_HR_HR;
              break;
            case "hu-HU":
            case "hu":
              VHCTranslation = MAB_VHC_TRANSLATIONS_HU_HU;
              break;
            case "ro-RO":
            case "ro":
              VHCTranslation = MAB_VHC_TRANSLATIONS_RO_RO;
              break;
            case "tr-TR":
            case "tr":
              VHCTranslation = MAB_VHC_TRANSLATIONS_TR_TR;
              break;
            default:
              VHCTranslation = MAB_VHC_TRANSLATIONS_TR_TR;
              break;
          }

          return !isEmpty ? { ...VHCTranslation, ...translateValues } : { ...VHCTranslation, ...DEFAULT_TRANSLATIONS };
        }
      }

      return { ...MAB_VHC_TRANSLATIONS_EN, ...DEFAULT_TRANSLATIONS };
    } catch (error) {
      console.error("Get Translation Error", error);
      return { ...MAB_VHC_TRANSLATIONS_EN, ...DEFAULT_TRANSLATIONS }; // Return default translations on error
    }
  };

  const t = (
    translationKey: string,
    interpolation?: LanguageTranslationType
  ) => {
    const normalizedTranslationKey = translationKey.trim();

    if (!normalizedTranslationKey) return "";

    let translatedValue = localizedTranslations[normalizedTranslationKey] ||
      defaultTranslations[normalizedTranslationKey] ||
      translationKey;

    if (translatedValue === normalizedTranslationKey || !interpolation)
      return translatedValue;

    const interpolationKeys = Object.keys(interpolation);

    if (interpolationKeys.length === 0) return translatedValue;

    const interpolationValues = Object.values(interpolation);

    for (let i = 0; i < interpolationKeys.length; ++i)
      translatedValue = translatedValue.replace(
        `{{${interpolationKeys[i]}}}`,
        interpolationValues[i] || ""
      );

    return translatedValue;
  };

  const changeLanguage = async (localizedLanguageLcid: string) => {
    const normalizedLocalizedLanguageLcid = localizedLanguageLcid.trim();

    if (
      !normalizedLocalizedLanguageLcid ||
      normalizedLocalizedLanguageLcid === currentLcid
    )
      return;

    setLoadingTranslations(true);
    let updateData = await loadLocalizedLanguages(normalizedLocalizedLanguageLcid);

    setLocalizedTranslations(
      updateData
    );
    setLoadingTranslations(false);

  };

  const initReactI18next = async (localizedLanguageLcid?: string) => {
    setDefaultTranslations(loadDefaultTranslations());
    setLocalizedTranslations(await loadLocalizedLanguages(localizedLanguageLcid));
    setLoadingTranslations(false);
  };

  return (
    <I18nContext.Provider
      value={{
        isLoadingTranslations,
        currentLcid,
        defaultTranslations,
        localizedTranslations,
        t,
        changeLanguage,
        initReactI18next,
      }}
    >
      {children}
    </I18nContext.Provider>
  );
};

export default I18nProvider;
