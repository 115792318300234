import { FC, useEffect, useState } from "react";
import { useTranslation } from "@translationsContexts/i18nContext";
import { useSelector } from "react-redux";
import RedirectHelper from "../../utils/helpers/RedirectHelper";
import { getAccountSimpleAsync, selectAccount } from "../../redux/account";
import { authSetSignIn, selectAuthState } from "../../redux/auth";
import { clearAllCart } from "../../redux/shoppingCartSlide";
import { useAppDispatch } from "../../redux/store";
import { AuthService } from "../../services/AuthService";
import Styles from "./RedirectCountryModal.module.css";
import RDCustomAlert from "../RDComponents/RDCustomAlert";
import RDButton from "../RDComponents/RDButton";
import { clearCart } from "../../redux/shoppingCartV3Slice";

interface IRedirectCountryModalProps {
  loggedInCountry: string;
  userCountry: string;
  continueCallback?: Function;
}

export const RedirectCountryModal: FC<IRedirectCountryModalProps> = ({
  loggedInCountry,
  userCountry,
  continueCallback,
}) => {
  const { t } = useTranslation();
  const [isDisableContinueButton, setIsDisableContinueButton] = useState(false);
  const account = useSelector(selectAccount);
  const auth = useSelector(selectAuthState);
  const dispatchReduxToolkit = useAppDispatch();

  const messageContent = () => {
    const message = t("RedirectCountryModal_Message");
    return message
      .replace("[CountryA]", `<b>${loggedInCountry}</b>`)
      .replace("[CountryB]", `<b>${userCountry}</b>`);
  };

  const buttonText = () => {
    const text = t("RedirectCountryModal_ButtonText");
    return text.replace("[Country]", `<b>${userCountry}</b>`);
  };

  const redirectCountry = () => {
    const targetCountryToRedirect =
      account?.companyCountry || account?.countryCode;

    if (!targetCountryToRedirect) return;

    const regions = AuthService.getRegionsLanguages();
    const regionSelection = regions.find(
      (x) =>
        x.country.trim().toLowerCase() ===
        targetCountryToRedirect.trim().toLowerCase()
    );

    if (!regionSelection) {
      setIsDisableContinueButton(false);
      return;
    }

    const language = regionSelection.languages.find((l) => l.defaultLanguage);

    if (continueCallback) {
      continueCallback();
      setTimeout(() => {
        RedirectHelper.redirectToRightCountry(
          regionSelection.country,
          !!language ? language.lcid : ""
        );
      }, 1000);
    } else {
      RedirectHelper.redirectToRightCountry(
        regionSelection.country,
        !!language ? language.lcid : ""
      );
    }
  };

  const redirectToRightCountry = () => {
    setIsDisableContinueButton(true);
    dispatchReduxToolkit(authSetSignIn(true));
    dispatchReduxToolkit(clearAllCart());
    dispatchReduxToolkit(clearCart());

    const timeoutId = window.setTimeout(() => {
      redirectCountry();
      window.clearTimeout(timeoutId);
    }, 1000);
  };

  useEffect(() => {
    setIsDisableContinueButton(true);
    dispatchReduxToolkit(
      getAccountSimpleAsync({
        ciamId: account?.ciamId || "",
        language: auth.language,
        countryCode: account?.companyCountry || "",
      })
    ).finally(() => {
      setIsDisableContinueButton(false);
    });
  }, []);

  return (
    <RDCustomAlert
      isOpen
      type="warning"
      AcceptButton={
        <RDButton
          onClick={redirectToRightCountry}
          disabled={isDisableContinueButton}
        >
          <span dangerouslySetInnerHTML={{ __html: buttonText() }}></span>
        </RDButton>
      }
    >
      <div className={`${Styles.message} ${Styles.message_override}`}>
        <p
          dangerouslySetInnerHTML={{
            __html: messageContent(),
          }}
        ></p>
      </div>
    </RDCustomAlert>
  );
};
