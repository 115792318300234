import { useTranslation } from "@translationsContexts/i18nContext";
import RDSearchInput from "../../../../components/RDComponents/RDSearchInput";
import style from "./AdsVehicleIdentificationSearch.module.scss";
import { capitalizeString } from "../../../../utils/text.util";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  selectFormValues,
  updateFormValues,
} from "../../../../redux/newRequestSlice";
import { useSelector } from "react-redux";
import { ADS_VIN_SEARCH_QUERY_KEY } from "../../../constants/adsNewRequest.constant";
import {
  SEARCH_BY_REGISTRATION_NUMBER_METHOD_ID,
  SEARCH_BY_VIN_METHOD_ID,
} from "../../../../constants/automaticSearch.constants";
import AdsBlockTitle from "../../../components/adsBlockTitle/AdsBlockTitle";
import {
  AdsSearchVehicleMode,
  AdsVehicleIdentificationErrors,
} from "../../../types/adsNewRequest.type";
import useCaseInsensitiveSearchParams from "../../../../hooks/useCaseInsensitiveSearchParams";

type Props = {
  vinError: string;
  setErrors: Dispatch<SetStateAction<AdsVehicleIdentificationErrors>>;
  validateSearchKeyword: (searchMethodId: number, vin?: string) => string;
  onSearchVehicle: (searchMethodId: number, vin?: string) => Promise<void>;
};

const AdsVehicleIdentificationSearch = ({
  vinError,
  setErrors,
  validateSearchKeyword,
  onSearchVehicle,
}: Props) => {
  const { t } = useTranslation();
  const { getParam } = useCaseInsensitiveSearchParams();
  const dispatch = useAppDispatch();
  const formValues = useSelector(selectFormValues);
  const vinParam = getParam(ADS_VIN_SEARCH_QUERY_KEY);
  const [searchByVinErrorMessage, setSearchByVinErrorMessage] =
    useState(vinError);
  const [isEditingOnVin, setEditingOnVin] = useState(false);

  useEffect(() => {
    setSearchByVinErrorMessage(vinError);
  }, [vinError]);

  useEffect(() => {
    if (
      !formValues.searchCountry ||
      !formValues.searchCountryCode ||
      !formValues.searchMethodId ||
      !!formValues.isAutoSearchByVinExecuted
    )
      return;

    if (!vinParam || !!validateSearchKeyword(SEARCH_BY_VIN_METHOD_ID, vinParam))
      return;

    onSearchVehicle(SEARCH_BY_VIN_METHOD_ID, vinParam).finally(() =>
      dispatch(
        updateFormValues({
          isAutoSearchByVinExecuted: true,
        })
      )
    );
  }, [
    formValues.searchCountry,
    formValues.searchCountryCode,
    formValues.searchMethodId,
    formValues.isAutoSearchByVinExecuted,
  ]);

  useEffect(() => {
    if (
      !formValues.vin ||
      !formValues.searchCountry ||
      !formValues.searchCountryCode ||
      !formValues.searchMethodId ||
      !formValues.isTriggerSearchByVin
    ) {
      dispatch(
        updateFormValues({
          isTriggerSearchByVin: false,
        })
      );
      return;
    }

    onSearchVehicle(SEARCH_BY_VIN_METHOD_ID, formValues.vin).finally(() =>
      dispatch(
        updateFormValues({
          isTriggerSearchByVin: false,
        })
      )
    );
  }, [
    formValues.searchCountry,
    formValues.searchCountryCode,
    formValues.searchMethodId,
    formValues.isTriggerSearchByVin,
    formValues.vin,
  ]);

  useEffect(() => {
    if (
      formValues.searchMethodId !== SEARCH_BY_VIN_METHOD_ID ||
      !isEditingOnVin
    ) {
      setSearchByVinErrorMessage("");
      return;
    }

    setSearchByVinErrorMessage(validateSearchKeyword(SEARCH_BY_VIN_METHOD_ID));
  }, [
    formValues.vin,
    formValues.searchMethodId,
    formValues.searchCountry,
    isEditingOnVin,
  ]);

  const onKeyPress = (event: any) => {
    if (event.charCode === 13 && !searchByVinErrorMessage) {
      onSearchVehicle(SEARCH_BY_VIN_METHOD_ID);
    }
  };

  const onSearchInputChanged = (value: string, field: AdsSearchVehicleMode) => {
    setErrors((prev) => ({
      ...prev,
      [field === "vin" ? "vin" : "plate"]: "",
    }));

    if (field === "vin" && !isEditingOnVin) setEditingOnVin(true);

    dispatch(
      updateFormValues({
        [field]: value,
        searchMethodId:
          field === "vin"
            ? SEARCH_BY_VIN_METHOD_ID
            : SEARCH_BY_REGISTRATION_NUMBER_METHOD_ID,
      })
    );
  };

  const onClearAutomaticSearchField = (field: AdsSearchVehicleMode) => {
    dispatch(
      updateFormValues({
        [field]: "",
      })
    );
  };

  const getMaxLengthOfSearchInput = (
    searchMethodId: number,
    field: AdsSearchVehicleMode
  ) => {
    if (field === "vin" && !isEditingOnVin) return 0;

    const targetSearchMethod = formValues.searchCountry?.searchMethods.find(
      (method) => method.id === searchMethodId
    );

    return targetSearchMethod?.validation?.maxLength || 0;
  };

  return (
    <>
      <div className={style.container}>
        <div className={style.box}>
          <div className={style.block}>
            <AdsBlockTitle title={t("ADS_SearchByVin")} />
            <RDSearchInput
              type="search"
              title={`${capitalizeString(
                t("RequestDetailRemoteDiagnostic_FieldTitle_VIN")
              )} *`}
              onKeyPress={onKeyPress}
              onChange={(_: any, { value }: any) =>
                onSearchInputChanged(value, "vin")
              }
              value={formValues.vin}
              onClear={() => onClearAutomaticSearchField("vin")}
              errorMessage={vinError || searchByVinErrorMessage}
              maxLength={
                !!vinError
                  ? 0
                  : getMaxLengthOfSearchInput(SEARCH_BY_VIN_METHOD_ID, "vin")
              }
              onClick={() => setEditingOnVin(true)}
              onBlur={() => setEditingOnVin(false)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdsVehicleIdentificationSearch;
