import { User } from "oidc-client-ts";
import { FC, useState } from "react";
import { useTranslation } from "@translationsContexts/i18nContext";
import { Button, Grid, Modal, Popup } from "semantic-ui-react";
import Account from "../../models/Account";
import ContactPerson from "../../models/ContactPerson";
import "./ConfirmItsMeModal.css";

interface IConfirmItsMeModalProps {
  isOpen: boolean;
  contactPerson: ContactPerson | null;
  account: Account | null;
  ciamAccount: User | null;
  userConfirm: () => void;
}

export const ConfirmItsMeModal: FC<IConfirmItsMeModalProps> = ({
  isOpen,
  contactPerson,
  account,
  ciamAccount,
  userConfirm,
}) => {
  const { t } = useTranslation();
  const [confirmButtonLoader, setConfirmButtonLoader] = useState(false);
  return (
    <>
      <Modal
        style={{ borderRadius: "0" }}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        // onClose={() => { }}
        open={isOpen}
        size={"large"}
      >
        <Modal.Content>
          <section className="confirm-modal-intro">
            <h2>
              {t("ConfirmItsMeModal_Hi")} {contactPerson?.firstName}{" "}
              {contactPerson?.lastName}
            </h2>
            <p>{t("ConfirmItsMeModal_Description1")}</p>
            <p>{t("ConfirmItsMeModal_Description2")}</p>
          </section>
          <Grid columns={2} relaxed="very" stackable>
            <Grid.Column>
              <h4>{t("ConfirmItsMeModal_CompanyDetails")}</h4>
              <p>
                {t("ConfirmItsMeModal_CompanyDetails_Name")}:{"  "}
                {(ciamAccount?.profile.company as any)?.companyName}
              </p>
              <p>
                {t("ConfirmItsMeModal_CompanyDetails_Address")}:{"  "}
                {(ciamAccount?.profile.company as any)?.address1}
              </p>
              <p>
                {t("ConfirmItsMeModal_CompanyDetails_Postcode")}:{"  "}
                {(ciamAccount?.profile.company as any)?.zipCode}{" "}
                {(ciamAccount?.profile.company as any)?.city}
              </p>
              <p>
                {t("ConfirmItsMeModal_CompanyDetails_Phone")}:{"  "}
                {(ciamAccount?.profile.company as any)?.phone}
              </p>
              <p>
                {t("ConfirmItsMeModal_CompanyDetails_Email")}:{"  "}
                {ciamAccount?.profile.email}
              </p>
            </Grid.Column>
            <Grid.Column>
              <h4>{t("ConfirmItsMeModal_YourDetails")}</h4>
              <p>
                {t("ConfirmItsMeModal_YourDetails_Title")}:{"  "}
                {contactPerson?.title}
              </p>
              <p>
                {t("ConfirmItsMeModal_YourDetails_Role")}:{"  "}
                {contactPerson?.role?.role || ""}
              </p>
              <p>
                {t("ConfirmItsMeModal_YourDetails_Name")}:{"  "}
                {contactPerson?.firstName} {contactPerson?.lastName}
              </p>
              <p>
                {t("ConfirmItsMeModal_YourDetails_Mobile")}:{"  "}
                {contactPerson?.mobilePhone}
              </p>
              <p className="confirm-your-email">
                {t("ConfirmItsMeModal_YourDetails_Email")}:{"  "}
                <Popup
                  className="custom-popup"
                  trigger={<span>{contactPerson?.email}</span>}
                >
                  {contactPerson?.email}
                </Popup>
              </p>

              <p>
                {t("ConfirmItsMeModal_YourDetails_Language")}:{"  "}
                {contactPerson?.language?.language || ""}
              </p>
            </Grid.Column>
          </Grid>
          <p className="confirm-modal-description3">
            {t("ConfirmItsMeModal_Description3")}
          </p>
          <div className="button-confirm-container">
            <Button
              className="bwc-btn--primary button-confirm--responsive"
              disabled={confirmButtonLoader}
              loading={confirmButtonLoader}
              onClick={() => {
                setConfirmButtonLoader(true);
                userConfirm();
              }}
            >
              {t("ConfirmItsMeModal_ConfirmButton")}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};
