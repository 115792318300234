export const MAB_VHC_TRANSLATIONS_DE_DE = {
	MAB_VHC_ContactUs_Headline: "Kontaktieren Sie uns",
	MAB_VHC_ContactUs_HowCanWeHelp: "Wie können wir Ihnen helfen?",
	MAB_VHC_ContactUs_Email_Headline: "Schicken Sie uns eine E-Mail!",
	MAB_VHC_ContactUs_Email_Description: "Senden Sie uns Ihre Nachricht, indem Sie das untenstehende Formular ausfüllen. Einer unserer Berater wird sich gerne mit Ihnen in Verbindung setzen.",
	MAB_VHC_ContactUs_Email_InputName: "Name*",
	MAB_VHC_ContactUs_Email_InputName_Required_Message: "Name ist erforderlich.",
	MAB_VHC_ContactUs_Email_InputName_MaxLength_Message: "Der Name überschreitet die zulässige Anzahl von 100 Zeichen.",
	MAB_VHC_ContactUs_Email_InputEmail: "E-Mail-Adresse*",
	MAB_VHC_ContactUs_Email_InputEmail_Required_Message: "E-Mail-Adresse ist erforderlich.",
	MAB_VHC_ContactUs_Email_InputEmail_MaxLength_Message: "Die E-Mail-Adresse überschreitet die zulässige Anzahl von 100 Zeichen.",
	MAB_VHC_ContactUs_Email_InputEmail_Invalid_Message: "Ungültiges E-Mail-Format.",
	MAB_VHC_ContactUs_Email_InputCompany: "Firmeninformationen*",
	MAB_VHC_ContactUs_Email_InputCompany_Required_Message: "Firmenangaben sind erforderlich.",
	MAB_VHC_ContactUs_Email_InputCompany_MaxLength_Message: "Das Unternehmen überschreitet die zulässige Anzahl von 100 Zeichen.",
	MAB_VHC_ContactUs_Email_InputAddress: "Adresse*",
	MAB_VHC_ContactUs_Email_InputAddress_Required_Message: "Adresse ist erforderlich.",
	MAB_VHC_ContactUs_Email_InputAddress_MaxLength_Message: "Die Adresse überschreitet die zulässige Anzahl von 250 Zeichen.",
	MAB_VHC_ContactUs_Email_InputZipCode: "PLZ*",
	MAB_VHC_ContactUs_Email_InputZipCode_Required_Message: "Postleitzahl ist erforderlich.",
	MAB_VHC_ContactUs_Email_InputZipCode_MaxLength_Message: "Die Postleitzahl überschreitet die zulässige Anzahl von 25 Zeichen.",
	MAB_VHC_ContactUs_Email_InputCity: "Stadt*",
	MAB_VHC_ContactUs_Email_InputCity_Required_Message: "Stadt ist erforderlich.",
	MAB_VHC_ContactUs_Email_InputCity_MaxLength_Message: "Stadt überschreitet die zulässige Anzahl von 100 Zeichen.",
	MAB_VHC_ContactUs_Email_InputCountry: "Land*",
	MAB_VHC_ContactUs_Email_InputCountry_Required_Message: "Land ist erforderlich.",
	MAB_VHC_ContactUs_Email_InputCountry_MaxLength_Message: "Das Land überschreitet die zulässige Anzahl von 100 Zeichen.",
	MAB_VHC_ContactUs_Email_InputCertNo: "Zertifikats-/Rechnungsnr.*",
	MAB_VHC_ContactUs_Email_InputCertNo_Required_Message: "Zertifikats-/Rechnungsnr. ist erforderlich.",
	MAB_VHC_ContactUs_Email_InputCertNo_MaxLength_Message: "Zertifikats-/Rechnungsnr. überschreitet die zulässige Anzahl von 50 Zeichen.",
	MAB_VHC_ContactUs_Email_InputReason: "Grund der Kontaktaufnahme*",
	MAB_VHC_ContactUs_Email_InputShortDescription: "Kurzbeschreibung*",
	MAB_VHC_ContactUs_Email_InputShortDescription_Required_Message: "Eine kurze Beschreibung ist erforderlich.",
	MAB_VHC_ContactUs_Email_InputShortDescription_MaxLength_Message: "Die Kurzbeschreibung überschreitet die zulässige Anzahl von 100 Zeichen.",
	MAB_VHC_ContactUs_Email_InputDescription: "Beschreibung*",
	MAB_VHC_ContactUs_Email_InputDescription_Required_Message: "Beschreibung ist erforderlich.",
	MAB_VHC_ContactUs_Email_InputDescription_MaxLength_Message: "Die Beschreibung überschreitet die zulässige Anzahl von 1000 Zeichen.",
	MAB_VHC_ContactUs_Email_InputQuestion: "Frage*",
	MAB_VHC_ContactUs_Email_InputQuestion_Required_Message: "Die Frage ist erforderlich.",
	MAB_VHC_ContactUs_Email_InputQuestion_MaxLength_Message: "Die Frage überschreitet die zulässige Anzahl von 100 Zeichen.",
	MAB_VHC_ContactUs_Email_Urgency: "Dringlichkeit",
	MAB_VHC_ContactUs_Email_Urgency_Message: "Ich kann nicht weiterarbeiten.",
	MAB_VHC_ContactUs_Email_DataProtection: "Datenschutzhinweis*",
	MAB_VHC_ContactUs_Email_DataProtection_Description: "Ich habe die <a href='{{data_protection_link}}' target='_blank'>Datenschutzerklärung.*</a> gelesen",
	MAB_VHC_ContactUs_Email_SecurityCheck: "Sicherheitskontrolle",
	MAB_VHC_ContactUs_Email_SecurityCodeInvalid: "Die von Ihnen eingegebene Captcha-Textantwort ist falsch.",
	MAB_VHC_ContactUs_Email_SecurityCodeConfirm: "Bitte geben Sie die richtigen Zeichen ein, die Sie im Feld oben sehen.",
	MAB_VHC_ContactUs_Email_SuccessMessage: "Vielen Dank, dass Sie uns kontaktiert haben! Ihre Nachricht wurde gesendet und wir werden uns in Kürze bei Ihnen melden.",
	MAB_VHC_ContactUs_Submit_Error_Message: "Aufgrund eines Problems konnte die E-Mail nicht gesendet werden. Bitte stellen Sie sicher, dass die Angaben korrekt sind, und versuchen Sie es erneut.",
	MAB_VHC_ContactUs_Reasons_Incident: "Incident / Vorfall",
	MAB_VHC_ContactUs_Reasons_Bug: "Bug / Softwarefehler",
	MAB_VHC_ContactUs_Reasons_Question: "Frage",
	MAB_VHC_ContactUs_Reasons_FeedbackFeatureRequest: "Feedback und Anfrage neuer Funktionen",
	MAB_VHC_ContactUs_Reasons_Incident_Form_Text: "Haben Sie ein Problem? Oder hindert Sie etwas an der Weiterarbeit? Bitte zögern Sie nicht, uns zu kontaktieren.",
	MAB_VHC_ContactUs_Reason_Incident_Topic: "Durch den Vorfall betroffener Prozessschritt*",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleIdentification: "Fahrzeugidentifikation",
	VHC_CONTACT_INCIDENT_TOPIC_AccessToTheService: "Zugriff auf den Dienst",
	VHC_CONTACT_INCIDENT_TOPIC_StartingTheService: "Starten des Dienstes",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleDataReadout: "Auslesen der Fahrzeugdaten",
	VHC_CONTACT_INCIDENT_TOPIC_CheckoutAndInvoicing: "Zahlung und Rechnungsstellung",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentPreview: "Zertifikatsvorschau",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentDelivery: "Zertifikatsübermittlung",
	VHC_CONTACT_INCIDENT_TOPIC_Others: "Andere",
	MAB_VHC_ContactUs_Reasons_Bug_Form_Text: "Sie haben einen Fehler gefunden? Bitte teilen Sie ihn uns gerne mit.",
	MAB_VHC_ContactUs_Reason_Bug_Topic: "Durch den Vorfall betroffener Prozessschritt*",
	MAB_VHC_ContactUs_Reasons_Question_Form_Text: "Sie haben eine Frage zu unserem Service? Ihre Frage wurde in unseren <a href='{{faq_link}}' target='_blank'>FAQs</a> nicht behandelt? Fragen Sie unseren Support.",
	MAB_VHC_ContactUs_Reason_Question_Topic: "Thema*",
	MAB_VHC_ContactUs_Reason_Question_Topic_ServiceContent: "Serviceinhalte",
	MAB_VHC_ContactUs_Reason_Question_Topic_VehicleCoverage: "Fahrzeugabdeckung",
	MAB_VHC_ContactUs_Reason_Question_Topic_CountryCoverage: "Länderabdeckung",
	MAB_VHC_ContactUs_Reason_Question_Topic_CheckoutProcess: "Bestellvorgang",
	MAB_VHC_ContactUs_Reason_Question_Topic_Pricing: "Preise",
	MAB_VHC_ContactUs_Reason_Question_Topic_HardwareSoftware: "Hardware / software",
	MAB_VHC_ContactUs_Reason_Question_Topic_Others: "Andere",
	MAB_VHC_ContactUs_Reasons_Feature_Form_Text: "Ihnen fehlt eine bestimmte Funktion? Oder möchten Sie direkt Feedback geben? Melden Sie sich gerne bei uns.",
	MAB_VHC_SubmitButton: "Absenden",
	MAB_VHC_CloseButton: "Schließen",
};
