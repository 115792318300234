import {
  SideNavigationItem,
  SideNavigationProps,
  SideNavigation as FrokSideNavigation,
} from "@bosch/react-frok";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "@translationsContexts/i18nContext";
import style from "./SideNavigationLiveChat.module.scss";
import {
  MinimalSideNavItem,
  SideNavItem,
  SideNavItemKey,
} from "../../types/sideNavigation.type";
import { listChannelMembershipsForAppInstanceUser } from "../../utils/helpers/ChimeAPI";
import { selectOriginComp } from "../../redux/account";
import { useAppSelector } from "../../redux/store";
import { ChannelMembershipForAppInstanceUserSummary } from "aws-sdk/clients/chimesdkmessaging";
import { useCloseLiveBoxContext } from "../../components/NewChatWidget/context/CloseLiveBoxContext";
import { SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT } from "@constants/frok.constant";
import SideNavigationGroup from "@components/NewChatWidget/liveChatBox/SideNavigationGroup";

type Props = SideNavigationProps & {
  onOpenMenu: Function;
};

const SideNavigationLiveChat = (props: Props) => {
  const { t } = useTranslation();
  const [isOpenSideNav, setIsOpenSideNav] = useState<boolean>(false);
  const { channelActiveId, setChannelActiveId, deletedChannel } =
    useCloseLiveBoxContext();
  const [currentARN, setCurrentARN] = useState<string>(channelActiveId);
  const [sideNavItems, setSideNavItems] = useState<MinimalSideNavItem[]>([]);
  const account = useAppSelector(selectOriginComp);

  useEffect(() => {
    if (sideNavItems.length === 0) return;

    setCurrentARN(
      !!isOpenSideNav && !!channelActiveId
        ? channelActiveId
        : getParentSideNavItemPath(sideNavItems)
    );
  }, [isOpenSideNav, channelActiveId, sideNavItems]);

  useEffect(() => {
    const getListChannelNavList = async () => {
      let currentActiveChannels = await getCurrentActiveChannel();
      let sideNavItem: MinimalSideNavItem = {
        icon: "message",
        key: SideNavItemKey.CONVERSATIONS,
        label: t("LiveChat_Conversations_Translation"),
        path: "",
        childItems: currentActiveChannels,
        isHideOnSideNavigation: false,
      };
      setSideNavItems([sideNavItem]);
    };

    getListChannelNavList();
  }, [deletedChannel, t]);

  const getParentSideNavItemPath = (
    sideNavItems: MinimalSideNavItem[]
  ): string => {
    for (const item of sideNavItems) {
      if (!item.childItems || item.childItems.length === 0) continue;

      for (const subItem of item.childItems) {
        if (subItem.path === channelActiveId) return item.path;

        if (!!subItem.childItems) {
          let referencePathName = "";

          for (let i = 0; i < subItem.path.length; ++i)
            if (!!channelActiveId[i]) referencePathName += channelActiveId[i];

          if (referencePathName === subItem.path) return item.path;
        }
      }
    }

    return "";
  };

  const getCurrentActiveChannel = async () => {
    let userChannels = await listChannelMembershipsForAppInstanceUser(
      account?.ciamId
    );
    if (userChannels && userChannels.length > 0) {
      //sort
      let channelHasMessage = userChannels.sort(
        (
          firstChannel: ChannelMembershipForAppInstanceUserSummary,
          nextChannel: ChannelMembershipForAppInstanceUserSummary
        ) => {
          const timestampA = new Date(
            firstChannel.ChannelSummary!.LastMessageTimestamp!
          ).getTime();
          const timestampB = new Date(
            nextChannel.ChannelSummary!.LastMessageTimestamp!
          ).getTime();
          return timestampB - timestampA;
        }
      );

      let childItemList: SideNavItem[] = [];
      channelHasMessage.map(
        (item: ChannelMembershipForAppInstanceUserSummary) => {
          let metaData = item.ChannelSummary?.Metadata
            ? JSON.parse(item.ChannelSummary?.Metadata)
            : "";
          let childItem: SideNavItem = {
            icon: "",
            key: SideNavItemKey.CONVERSATIONS,
            label:
              `${item.ChannelSummary?.Name || "Channel #"}` +
              `-` +
              `${metaData.serviceType}`,
            path: item.ChannelSummary?.Name || "Channel #",
            childItems: [],
            isHideOnSideNavigation: false,
            isLoginRequired: true,
            supportedRoles: [],
            isActive: true,
          };
          childItemList.push(childItem);
        }
      );
      return childItemList;
    }
    return [];
  };

  const onChangePage = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    data: any
  ) => {
    if (data.value) {
      setChannelActiveId(data.value);
    }
  };

  const renderSingleMenuItem = (
    route: MinimalSideNavItem,
    isSubItem: boolean,
    index: number
  ) => {
    return (
      <SideNavigationItem
        icon={!!isSubItem ? "" : route.icon}
        value={route.path}
        label={t(route.label)}
        key={index}
      />
    );
  };

  const renderMultiLevelMenuItem = (
    route: MinimalSideNavItem,
    index: number
  ) => {
    if (!!route.isHideOnSideNavigation) return <></>;

    return (
      <SideNavigationGroup
        id={route.path}
        icon={route.icon}
        value={route.path}
        label={t(route.label)}
        key={index}
        subItemsCount={route.childItems ? route.childItems.length : 0}
        isOpenSideNav={isOpenSideNav}
        setOpenSideNav={setIsOpenSideNav}
      >
        {route.childItems?.map((childItem, index) =>
          renderSingleMenuItem(childItem, true, index)
        )}
      </SideNavigationGroup>
    );
  };

  const renderMenuItem = (route: MinimalSideNavItem, index: number) => {
    if (
      !route.childItems ||
      route.childItems.length === 0 ||
      route.childItems.every((item) => !!item.isHideOnSideNavigation)
    )
      return renderSingleMenuItem(route, false, index);

    return renderMultiLevelMenuItem(route, index);
  };

  const onOpenChange = (open: boolean, _?: React.SyntheticEvent) => {
    setIsOpenSideNav(open);
    props.onOpenMenu(open);
  };

  const autoTriggerSideNav = (value: boolean) => {
    if (
      window.screen.availWidth >= SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT &&
      isOpenSideNav !== value
    )
      setIsOpenSideNav(value);
  };

  return (
    <>
      <FrokSideNavigation
        {...props}
        header={{
          label: t("LiveChat_SideNavigation_Header"),
        }}
        open={!!isOpenSideNav}
        onOpenChange={onOpenChange}
        onSelectedItemChange={onChangePage}
        defaultSelectedItem={currentARN}
        className={`${style.container} ${!!isOpenSideNav ? style.open : ""}`}
        onMouseOver={() => autoTriggerSideNav(true)}
        onMouseLeave={() => autoTriggerSideNav(false)}
      >
        <div className={style.wrapper}>
          {sideNavItems.map((item, index) => (
            <Fragment key={index}>{renderMenuItem(item, index)}</Fragment>
          ))}
        </div>
      </FrokSideNavigation>
    </>
  );
};

export default SideNavigationLiveChat;
