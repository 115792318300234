import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "@translationsContexts/i18nContext";
import { Grid, Image } from "semantic-ui-react";
import MasterCard from "../../../../../assets/Mastercard.png";
import VisaCard from "../../../../../assets/VisaCard.png";
import SepaCard from "../../../../../assets/Sepa.png";
import BoschCard from "../../../../../assets/Bosch_symbol_logo_black_red.png";
import Styles from "./ShoppingCartDetailCheckoutInfo.module.css";
import { useCartSubscription } from "../../../../../hooks/useCartSubscription";
import { PaymentMethodTypeDto } from "../../../../../models/paymentAdyen/PaymentMethodTypeDto";
import { usePaymentMethods } from "../../../../../hooks/usePaymentMethods";
import { useAppDispatch } from "../../../../../redux/hooks";
import { GetPaymentMethodsForCountryAsync } from "../../../../../redux/paymentAdyenSlide";
import CustomLoader from "../../../../loading/CustomLoader";
interface CheckoutContentPaymentMethodsProps {
  hidePaymentMethods?: boolean;
}

const CheckoutContentPaymentMethods: FC<CheckoutContentPaymentMethodsProps> = ({
  hidePaymentMethods,
}) => {
  const { t } = useTranslation();
  const dispatchReduxToolkit = useAppDispatch();
  const { isPayAsYouGo } = useCartSubscription();
  const paymentMethods = usePaymentMethods();
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setShowLoader(true);
    dispatchReduxToolkit(GetPaymentMethodsForCountryAsync()).finally(() => {
      setShowLoader(false);
    });
  }, []);

  return (
    <Fragment>
      {!isPayAsYouGo() && !hidePaymentMethods && (
        <Grid.Row className={Styles.row}>
          <Grid.Column className={Styles.column} width={16}>
            <div className={`${Styles.order_summary_contents}`}>
              <p>
                {t(
                  "ShoppingCartDetailCheckoutInfo_AvailablePaymentMethods_Title"
                )}
              </p>
              <div className={Styles.card_images}>
                {paymentMethods.map((paymentMethod) => {
                  const paymentMethodType =
                    paymentMethod.paymentMethodTypes &&
                    paymentMethod.paymentMethodTypes.length > 0
                      ? paymentMethod.paymentMethodTypes[0]
                      : null;
                  if (!!paymentMethodType) {
                    switch (paymentMethodType) {
                      case PaymentMethodTypeDto.CreditCard:
                        return (
                          <Fragment>
                            <span>
                              <Image src={MasterCard} />
                            </span>
                            <span>
                              <Image src={VisaCard} />
                            </span>
                          </Fragment>
                        );
                      case PaymentMethodTypeDto.Sepadirectdebit:
                        return (
                          <span>
                            <Image src={SepaCard} />
                          </span>
                        );

                      case PaymentMethodTypeDto.BoschAccount:
                        return (
                          <span className={Styles.bosch_account_icon}>
                            <Image src={BoschCard} />
                          </span>
                        );
                      default:
                        return <Fragment />;
                    }
                  }
                  return <Fragment />;
                })}
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      )}
      {showLoader && <CustomLoader enable />}
    </Fragment>
  );
};

export default CheckoutContentPaymentMethods;
