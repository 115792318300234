import { Button, Icon, MenuItem } from "@bosch/react-frok";
import style from "./AccountButton.module.scss";
import { useSelector } from "react-redux";
import { loginAsync, logoutAsync, selectAccount } from "../../../redux/account";
import { useEffect, useRef, useState } from "react";
import {
  SIDE_NAV_MY_BOSCH_ITEM,
  SIDE_NAV_MY_REMOTE_ITEM,
  SIDE_NAV_WALLET_ITEM,
} from "../../../constants/sideNav.constant";
import { useTranslation } from "@translationsContexts/i18nContext";
import { handleClickedOutOfComponent } from "../../../utils/dom.util";
import { useUserSubscription } from "../../../hooks/useUserSubscription";
import { useHistory } from "react-router-dom";
import { useRouting } from "../../../hooks/useRouting";
import ChangeCompanyModal from "../../../components/CompanyModal/ChangeCompanyModal";
import { SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT } from "../../../constants/frok.constant";
import { triggerSideNavigation } from "../../../utils/frok.util";
import { useAppDispatch } from "@redux/hooks";
import { selectMainContentPinned, setOpenSideNav } from "@redux/auth";
import CustomLoader from "@components/loading/CustomLoader";
import RDMessageDialog from "@components/RDComponents/RDMessageDialog/RDMessageDialog";

const AccountButton = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const currentAccount = useSelector(selectAccount);
  const isMainContentPinned = useSelector(selectMainContentPinned);
  const { checkUserIsMapped, checkUserMultipleWorkshop } =
    useUserSubscription();
  const accountOptionsRef = useRef<HTMLDivElement | null>(null);
  const { isWalletAndMyProductsRouteAccessible } = useRouting();
  const [isShowAccountOptions, setShowAccountOptions] = useState(false);
  const [isShowChangeCompanyModal, setShowChangeCompanyModal] = useState(false);
  const [isShowConfirmLogoutModal, setShowConfirmLogoutModal] = useState(false);
  const [isShowLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const checkFunction = (e: MouseEvent) =>
      handleClickedOutOfComponent(e, accountOptionsRef, () =>
        setShowAccountOptions(false)
      );

    document.addEventListener("mousedown", checkFunction);

    return () => {
      document.removeEventListener("mousedown", checkFunction);
    };
  }, []);

  const getUserFullName = () => {
    if (!currentAccount) return "";

    return `${currentAccount.contactFirstName} ${currentAccount.contactLastName}`;
  };

  const onChangPage = (path: string) => {
    if (!isMainContentPinned) dispatch(setOpenSideNav(false));

    history.push(path);

    if (window.screen.availWidth < SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT)
      triggerSideNavigation("close");
  };

  const onClickLoginButton = () => {
    if (currentAccount) {
      setShowAccountOptions((prev) => !prev);
      return;
    }

    setShowLoader(true);
    dispatch(loginAsync({ redirectUrl: window.location.pathname }));
  };

  return (
    <>
      <div className={style.container}>
        <Button
          mode="integrated"
          icon={currentAccount ? "my-brand-frame" : "login"}
          onClick={onClickLoginButton}
        >
          <span>
            {currentAccount ? getUserFullName() : t("RegisterOrLogin")}
          </span>
        </Button>
        {currentAccount ? (
          <div
            ref={accountOptionsRef}
            className={`${style.account_options} ${
              !!isShowAccountOptions ? style.show : ""
            }`}
          >
            <div className={style.header}>
              <p className={style.title}>{getUserFullName()}</p>
              <Icon
                iconName="close"
                onClick={() => setShowAccountOptions(false)}
              />
            </div>
            <div className={style.options_list}>
              {checkUserIsMapped() ? (
                <MenuItem
                  icon={SIDE_NAV_MY_REMOTE_ITEM.icon}
                  label={t(SIDE_NAV_MY_REMOTE_ITEM.label)}
                  onClick={() => onChangPage(SIDE_NAV_MY_REMOTE_ITEM.path)}
                  className={style.option}
                />
              ) : (
                <></>
              )}
              <MenuItem
                icon={SIDE_NAV_MY_BOSCH_ITEM.icon}
                label={t(SIDE_NAV_MY_BOSCH_ITEM.label)}
                onClick={() => onChangPage(SIDE_NAV_MY_BOSCH_ITEM.path)}
                className={style.option}
              />
              {checkUserMultipleWorkshop() ? (
                <MenuItem
                  icon="garage"
                  label={t("ChangeCompanyModal_Title")}
                  onClick={() => {
                    setShowChangeCompanyModal(true);
                  }}
                  className={style.option}
                />
              ) : (
                <></>
              )}
              {isWalletAndMyProductsRouteAccessible() ? (
                <MenuItem
                  icon={SIDE_NAV_WALLET_ITEM.icon}
                  label={t(SIDE_NAV_WALLET_ITEM.label)}
                  onClick={() => onChangPage(SIDE_NAV_WALLET_ITEM.path)}
                  className={style.option}
                />
              ) : (
                <></>
              )}
              <MenuItem
                icon="logout"
                label={t("AccountButton_LogoutButton")}
                onClick={() => setShowConfirmLogoutModal(true)}
                className={style.option}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {isShowChangeCompanyModal ? (
        <ChangeCompanyModal
          setCloseModalTrigger={() => {
            setShowChangeCompanyModal(false);
          }}
        />
      ) : (
        <></>
      )}
      {isShowConfirmLogoutModal ? (
        <RDMessageDialog
          variant="warning"
          messages={[t("AccountButton_LogoutModal_Content")]}
          confirmLabel={t("Header_YesButton")}
          onConfirm={() => {
            dispatch(logoutAsync());
            setShowConfirmLogoutModal(false);
            setShowLoader(true);
          }}
          cancelLabel={t("Header_NoButton")}
          onCancel={() => {
            if (!isMainContentPinned) dispatch(setOpenSideNav(false));
            setShowConfirmLogoutModal(false);
          }}
          className={style.log_out_modal}
        />
      ) : (
        <></>
      )}
      {isShowLoader ? <CustomLoader enable /> : <></>}
    </>
  );
};

export default AccountButton;
