export const MAB_VHC_TRANSLATIONS_IT_IT = {
	MAB_VHC_ContactUs_Headline: "Contattaci",
	MAB_VHC_ContactUs_HowCanWeHelp: "Come possiamo aiutarti?",
	MAB_VHC_ContactUs_Email_Headline: "Invia una e-mail!",
	MAB_VHC_ContactUs_Email_Description: "Inviaci il tuo messaggio compilando il modulo sottostante. Uno dei nostri consulenti sarà felice di mettersi in contatto con te.",
	MAB_VHC_ContactUs_Email_InputName: "Nome*",
	MAB_VHC_ContactUs_Email_InputName_Required_Message: "Il nome è obbligatorio.",
	MAB_VHC_ContactUs_Email_InputName_MaxLength_Message: "Il nome supera il limite consentito di 100 caratteri.",
	MAB_VHC_ContactUs_Email_InputEmail: "Indirizzo e-mail*",
	MAB_VHC_ContactUs_Email_InputEmail_Required_Message: "L'indirizzo e-mail è obbligatorio.",
	MAB_VHC_ContactUs_Email_InputEmail_MaxLength_Message: "L'indirizzo e-mail supera il limite consentito di 100 caratteri.",
	MAB_VHC_ContactUs_Email_InputEmail_Invalid_Message: "Formato email non valido.",
	MAB_VHC_ContactUs_Email_InputCompany: "Informazioni aziendali*",
	MAB_VHC_ContactUs_Email_InputCompany_Required_Message: "Le informazioni sull'azienda sono obbligatorie.",
	MAB_VHC_ContactUs_Email_InputCompany_MaxLength_Message: "Le informazioni sull'azienda superano il limite consentito di 100 caratteri.",
	MAB_VHC_ContactUs_Email_InputAddress: "Indirizzo*",
	MAB_VHC_ContactUs_Email_InputAddress_Required_Message: "L'indirizzo è obbligatorio.",
	MAB_VHC_ContactUs_Email_InputAddress_MaxLength_Message: "L'indirizzo supera il limite consentito di 250 caratteri.",
	MAB_VHC_ContactUs_Email_InputZipCode: "Cap*",
	MAB_VHC_ContactUs_Email_InputZipCode_Required_Message: "Il codice postale è obbligatorio.",
	MAB_VHC_ContactUs_Email_InputZipCode_MaxLength_Message: "Il codice postale supera il limite consentito di 25 caratteri.",
	MAB_VHC_ContactUs_Email_InputCity: "Città*",
	MAB_VHC_ContactUs_Email_InputCity_Required_Message: "La città è obbligatoria.",
	MAB_VHC_ContactUs_Email_InputCity_MaxLength_Message: "La città supera il limite consentito di 100 caratteri.",
	MAB_VHC_ContactUs_Email_InputCountry: "Paese*",
	MAB_VHC_ContactUs_Email_InputCountry_Required_Message: "Il paese è obbligatorio.",
	MAB_VHC_ContactUs_Email_InputCountry_MaxLength_Message: "Il paese supera il limite consentito di 100 caratteri.",
	MAB_VHC_ContactUs_Email_InputCertNo: "N. Report/fattura*",
	MAB_VHC_ContactUs_Email_InputCertNo_Required_Message: "Report/fattura n. è obbligatorio.",
	MAB_VHC_ContactUs_Email_InputCertNo_MaxLength_Message: "Report/fattura n. supera il limite consentito di 50 caratteri.",
	MAB_VHC_ContactUs_Email_InputReason: "Motivo per contattarci*",
	MAB_VHC_ContactUs_Email_InputShortDescription: "Breve descrizione*",
	MAB_VHC_ContactUs_Email_InputShortDescription_Required_Message: "È richiesta una breve descrizione.",
	MAB_VHC_ContactUs_Email_InputShortDescription_MaxLength_Message: "La breve descrizione supera il limite consentito di 100 caratteri.",
	MAB_VHC_ContactUs_Email_InputDescription: "Descrizione*",
	MAB_VHC_ContactUs_Email_InputDescription_Required_Message: "La descrizione è obbligatoria.",
	MAB_VHC_ContactUs_Email_InputDescription_MaxLength_Message: "La descrizione supera il limite consentito di 1000 caratteri.",
	MAB_VHC_ContactUs_Email_InputQuestion: "Domanda*",
	MAB_VHC_ContactUs_Email_InputQuestion_Required_Message: "La domanda è obbligatoria.",
	MAB_VHC_ContactUs_Email_InputQuestion_MaxLength_Message: "La domanda supera il limite consentito di 100 caratteri.",
	MAB_VHC_ContactUs_Email_Urgency: "Urgenza",
	MAB_VHC_ContactUs_Email_Urgency_Message: "Non posso continuare a lavorare.",
	MAB_VHC_ContactUs_Email_DataProtection: "Informativa sulla protezione dei dati*",
	MAB_VHC_ContactUs_Email_DataProtection_Description: "Ho letto l'<a href='{{data_protection_link}}' target='_blank'>Informativa sulla privacy.*</a>",
	MAB_VHC_ContactUs_Email_SecurityCheck: "Controllo di sicurezza",
	MAB_VHC_ContactUs_Email_SecurityCodeInvalid: "La risposta al captcha inserita non è corretta.",
	MAB_VHC_ContactUs_Email_SecurityCodeConfirm: "Inserisci i caratteri corretti che vedi nella casella sopra.",
	MAB_VHC_ContactUs_Email_SuccessMessage: "Grazie per averci contattato! Il tuo messaggio è stato inviato e ti ricontatteremo a breve.",
	MAB_VHC_ContactUs_Submit_Error_Message: "Non è stato possibile inviare l'e-mail a causa di un problema. Assicurati che i dettagli siano corretti e riprova.",
	MAB_VHC_ContactUs_Reasons_Incident: "Evento",
	MAB_VHC_ContactUs_Reasons_Bug: "Bug",
	MAB_VHC_ContactUs_Reasons_Question: "Domanda",
	MAB_VHC_ContactUs_Reasons_FeedbackFeatureRequest: "Feedback e richiesta di funzionalità",
	MAB_VHC_ContactUs_Reasons_Incident_Form_Text: "Hai problemi? Oppure c'è qualcosa che ti impedisce di continuare a lavorare? Non esitate a contattarci.",
	MAB_VHC_ContactUs_Reason_Incident_Topic: "Fase del processo interessata dall'evento*",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleIdentification: "Identificazione del veicolo",
	VHC_CONTACT_INCIDENT_TOPIC_AccessToTheService: "Accesso al servizio",
	VHC_CONTACT_INCIDENT_TOPIC_StartingTheService: "Avvio del servizio",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleDataReadout: "Lettura dati del veicolo",
	VHC_CONTACT_INCIDENT_TOPIC_CheckoutAndInvoicing: "Checkout e fatturazione",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentPreview: "Anteprima del documento",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentDelivery: "Consegna documento",
	VHC_CONTACT_INCIDENT_TOPIC_Others: "Altri",
	MAB_VHC_ContactUs_Reasons_Bug_Form_Text: "Hai trovato un bug? Non esitate a condividerlo con noi.",
	MAB_VHC_ContactUs_Reason_Bug_Topic: "Fase del processo interessata dall'evento*",
	MAB_VHC_ContactUs_Reasons_Question_Form_Text: "Hai una domanda riguardante il nostro servizio? La tua domanda non era coperta dalle nostre <a href='{{faq_link}}' target='_blank'>FAQ</a>? Chiedi il nostro supporto.",
	MAB_VHC_ContactUs_Reason_Question_Topic: "Argomento*",
	MAB_VHC_ContactUs_Reason_Question_Topic_ServiceContent: "Contenuti del servizio",
	MAB_VHC_ContactUs_Reason_Question_Topic_VehicleCoverage: "Copertura veicolo",
	MAB_VHC_ContactUs_Reason_Question_Topic_CountryCoverage: "Copertura paese",
	MAB_VHC_ContactUs_Reason_Question_Topic_CheckoutProcess: "Procedura di pagamento",
	MAB_VHC_ContactUs_Reason_Question_Topic_Pricing: "Prezzi",
	MAB_VHC_ContactUs_Reason_Question_Topic_HardwareSoftware: "Hardware/software",
	MAB_VHC_ContactUs_Reason_Question_Topic_Others: "Altri",
	MAB_VHC_ContactUs_Reasons_Feature_Form_Text: "Ti manca una determinata funzionalità? Oppure vuoi fornire un feedback diretto? Sentiti libero di contattarci.",
	MAB_VHC_SubmitButton: "Invia",
	MAB_VHC_CloseButton: "Vicino",
};
