import { useTranslation } from "@translationsContexts/i18nContext";
import style from "./AdsNewRequestSummary.module.scss";
import AdsComponentTitle from "../../../components/adsComponentTitle/AdsComponentTitle";
import RDInput from "../../../../components/RDComponents/RDInput";
import { useSelector } from "react-redux";
import {
  NewRequestStep,
  selectFormValues,
  setCurrentStep,
  updateFormValues,
} from "../../../../redux/newRequestSlice";
import RDButton from "../../../../components/RDComponents/RDButton";
import { useAppDispatch } from "../../../../redux/hooks";
import { capitalizeString } from "../../../../utils/text.util";
import { useState } from "react";
import CustomLoader from "../../../../components/loading/CustomLoader";
import { useSubmitNewRequestOnUs } from "../../../../hooks/useSubmitNewRequestOnUs";
import RDAlert from "../../../../components/RDComponents/RDAlert";

const AdsNewRequestSummary = () => {
  const { t } = useTranslation();
  const formValues = useSelector(selectFormValues);
  const dispatch = useAppDispatch();
  const { submitNewRequestOnUs } = useSubmitNewRequestOnUs();
  const [isShowLoader, setShowLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onGoBack = () => {
    dispatch(setCurrentStep(NewRequestStep.Request_Detail));
  };

  const onSubmitRequest = async () => {
    setShowLoader(true);

    try {
      const response: any = await submitNewRequestOnUs();

      if (
        !response ||
        !response?.payload ||
        !response?.payload?.dataObject ||
        response.payload.errorMessages.length > 0
      ) {
        setErrorMessage(t("Error_Common"));
        return;
      }

      dispatch(
        updateFormValues({
          newRequestSessionId:
            Number(response.payload.dataObject.sessionId) || 0,
        })
      );

      const timeoutId = window.setTimeout(() => {
        dispatch(setCurrentStep(NewRequestStep.Request_Created_Successfully));
        window.clearTimeout(timeoutId);
      }, 200);
    } catch {
      setErrorMessage(t("Error_Common"));
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <>
      <div className={style.container}>
        <AdsComponentTitle title={t("Checkout_ProgressBar_Fourth")} />
        <div className={style.wrapper}>
          <RDInput
            disabled
            title={capitalizeString(
              t("MyRequests_First_SelectServiceModal_Choose")
            )}
            value={formValues.serviceTypeSelectedModel?.serviceTypeLocalized}
          />
          <div className={style.block}>
            <RDInput
              disabled
              title={capitalizeString(
                t("RequestDetailRemoteDiagnostic_FieldTitle_VIN")
              )}
              value={formValues.vin}
            />
            <RDInput
              disabled
              title={capitalizeString(t("ADS_NewRequest_RepairOrderNo"))}
              value={formValues.repairOrder}
            />
          </div>
          <div className={style.block}>
            <RDInput
              disabled
              title={t("MyRequests_First_SelectVehicleModal_Make")}
              value={formValues.brandSelected}
            />
            <RDInput
              disabled
              title={t("MyRequests_First_SelectVehicleModal_Model")}
              value={formValues.modelSelected}
            />
          </div>
          <div className={style.block}>
            <RDInput
              disabled
              title={t("MyRequests_First_SelectVehicleModal_Engine")}
              value={formValues.engineSelected}
            />
            <RDInput
              disabled
              title={t("MyRequests_First_SelectVehicleModal_Year")}
              value={formValues.yearSelected}
            />
          </div>
          <div className={style.block}>
            <RDInput
              disabled
              title={capitalizeString(
                t("CheckOutCDM3StepDelivery_Contact_Person_Input_Title")
              )}
              value={`${formValues.technicianSelectedModel?.firstName} ${formValues.technicianSelectedModel?.lastName}`}
            />
            <RDInput
              disabled
              title={t("ADS_NewRequest_Mileage")}
              value={formValues.mileage}
            />
          </div>
          <div className={style.bottom}>
            <RDButton variant="secondary" onClick={onGoBack}>
              {t("BackButton")}
            </RDButton>
            <RDButton onClick={onSubmitRequest}>{t("SubmitButton")}</RDButton>
          </div>
        </div>
      </div>
      {!!errorMessage ? (
        <RDAlert
          isOpen
          type="error"
          messages={[errorMessage]}
          acceptButtonText={t("CloseButton")}
          onAccept={() => setErrorMessage("")}
        />
      ) : (
        <></>
      )}
      {isShowLoader ? <CustomLoader enable /> : <></>}
    </>
  );
};

export default AdsNewRequestSummary;
