import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import RegionAndLanguage from "../components/LandingPage";
import {
  selectCdmVersion,
  selectCountryCode,
  selectIsSignIn,
  selectLanguage,
} from "../redux/auth";
import ScrollToTop from "../utils/ScrollToTop";
import NotFoundRouter from "./NotFoundRouter";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import { applicationRouters } from "./router.config";
import SignInCallback from "../pages/SignInCallback";
import SignOutCallback from "../pages/SignOutCallback";
import EditProfileCallback from "../pages/EditProfileCallback";
import LaunchLinkRedirect from "../components/LaunchLinkRedirect";
import SessionTimeout from "../pages/SessionTimeout";
import SignInCallbackPopup from "../pages/SignInCallback/SignInCallbackPopup";
import { AppDispatch, useAppDispatch } from "../redux/store";
import { getApiKeyChime, selectApiKey } from "../redux/chatWidget";
import "./index.scss";
import { RouteConfigure } from "../models/Route";
import { CdmVersion } from "../models/pageConfiguration/CdmVersion";
import CustomLoader from "../components/loading/CustomLoader";
import { useGTMAnalyticTracker } from "../hooks/useGTMAnalyticTracker";
import { useRouting } from "../hooks/useRouting";
import { getBrowserLanguage } from "../utils/browser.util";
import { selectAccount } from "../redux/account";
import { useProceedNegativeCreditBalance } from "../hooks/useProceedNegativeCreditBalance";
import NegativeCreditBalanceModal from "../components/modals/negativeCreditBalanceModal/NegativeCreditBalanceModal";
import { selectUserAvailableCredits } from "../redux/newRequestSlice";
import AppWrapper from "../layout/appWrapper/AppWrapper";
import NewChatWidget from "../components/NewChatWidget/NewChatWidget";
import { CloseLiveBoxContextProvider } from "../components/NewChatWidget/context/CloseLiveBoxContext";
import { useTranslation } from "@translationsContexts/i18nContext";

const Router = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const { addAnalyticEvent } = useGTMAnalyticTracker();
  const location = useLocation();
  const isSignIn = useSelector(selectIsSignIn);
  const countryCode = useSelector(selectCountryCode);
  const language = useSelector(selectLanguage);
  const apiKey = useSelector(selectApiKey);
  const currentAccount = useSelector(selectAccount);
  const isMobile =
    window.location.search !== "" &&
    !!urlParams.get("ismobile") &&
    urlParams.get("ismobile") === "1";
  const cdmVersion = useSelector(selectCdmVersion);
  const { isAbleToShowSwitchCountryModal, isAbleToShowRedirectCountryModal } =
    useRouting();
  const { isAbleToShowNegativeCreditBalanceModal } =
    useProceedNegativeCreditBalance();
  const currentCreditBalance = useSelector(selectUserAvailableCredits);
  const [routers, setRouters] = useState<RouteConfigure[] | null>(null);
  const [previousVirtualPage, setPreviousVirtualPage] = useState("");
  const [isShowNegativeBalanceModal, setShowNegativeBalanceModal] =
    useState(false);
  const { changeLanguage, currentLcid } = useTranslation();

  useEffect(() => {
    if (
      isSignIn &&
      apiKey === "" &&
      !window.location.pathname.startsWith("/signoutcallback")
    ) {
      dispatchReduxToolkit(getApiKeyChime());
    }
  }, [isSignIn]);

  useEffect(() => {
    if (window.location.href.includes("/regions")) {
      changeLanguage("en");
    } else if (
      isAbleToShowSwitchCountryModal() ||
      isAbleToShowRedirectCountryModal()
    ) {
      const browserLanguage = getBrowserLanguage();

      changeLanguage(browserLanguage.trim());
    } else if (currentLcid.toLowerCase() !== language.toLowerCase()) {
      changeLanguage(language);
    }
  }, [location, isSignIn, currentAccount]);

  useEffect(() => {
    if (isAbleToShowNegativeCreditBalanceModal()) {
      setShowNegativeBalanceModal(true);
      return;
    }

    if (isShowNegativeBalanceModal) setShowNegativeBalanceModal(false);
  }, [location, isSignIn, currentCreditBalance]);

  useEffect(() => {
    const targetRouters = applicationRouters[cdmVersion as CdmVersion];

    setRouters(
      targetRouters ? targetRouters : applicationRouters[CdmVersion.CDM3v0]
    );
  }, [cdmVersion]);

  if (!routers) return <CustomLoader />;

  return (
    <div className={`grid-responsive-width-CDM3`}>
      <ScrollToTop />
      {isShowNegativeBalanceModal ? (
        <NegativeCreditBalanceModal
          isOpen
          onClose={() => setShowNegativeBalanceModal(false)}
        />
      ) : (
        <></>
      )}
      <Switch>
        <Route path="/regions" exact component={RegionAndLanguage} />
        <Route
          path="/editprofilecallback"
          exact
          component={EditProfileCallback}
        />
        <Route path="/signincallback" exact component={SignInCallback} />
        <Route
          path="/signincallbackpopup"
          exact
          component={SignInCallbackPopup}
        />
        <Route path="/signoutcallback" exact component={SignOutCallback} />
        <Route path="/sessiontimeout" exact component={SessionTimeout} />
        <Route
          path="/launchlinkredirect"
          exact
          component={LaunchLinkRedirect}
        />
        <Route path="/de/" exact>
          <Redirect to="/" />
        </Route>
        {routers.map((route: any, index: any) => (
          <Route
            key={index} // DO NOT CHANGE THIS KEY (IGNORE SONAR QUEBE WARNING)
            path={route.path}
            exact={route.exact}
            render={(routeProps) => {
              if (route.path !== previousVirtualPage) {
                addAnalyticEvent("virtualPageview", {
                  page_path: route.path,
                  step_name: route.title,
                });
              }
              setPreviousVirtualPage(route.path);

              const renderedRoute = route.isProtected ? (
                <ProtectedRoute
                  key={index} // DO NOT CHANGE THIS KEY (IGNORE SONAR QUEBE WARNING)
                  {...routeProps}
                  exact={route.exact}
                  isSignIn={isSignIn}
                  countryCode={countryCode}
                  component={route.component}
                  permission={route.permission}
                  role={route.role}
                  isMapingRequired={route.isMapingRequired}
                  isTrialHidden={route.isTrialHidden}
                />
              ) : (
                <PublicRoute
                  key={index} // DO NOT CHANGE THIS KEY (IGNORE SONAR QUEBE WARNING)
                  {...routeProps}
                  exact={route.exact}
                  countryCode={countryCode}
                  path={route.path}
                  component={route.component}
                />
              );

              return <AppWrapper route={renderedRoute} />;
            }}
          />
        ))}
        <Route component={NotFoundRouter} />
      </Switch>
      {!isMobile &&
        !(
          window.location.href.includes("/signincallback") ||
          window.location.href.includes("/signoutcallback") ||
          window.location.href.includes("/editprofilecallback") ||
          window.location.href.includes("/launchlinkredirect") ||
          window.location.href.includes("/regions") ||
          window.location.href.includes("/meeting")
        ) && (
          <CloseLiveBoxContextProvider>
            <NewChatWidget />
          </CloseLiveBoxContextProvider>
        )}
    </div>
  );
};

export default Router;
