import { RequestLanguageTranslationModel, ResponseLanguageTranslationModel } from "@models/translationTool/translationTool.model";
import ResponseResultBase from "../models/ResponseResultBase";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { AppDispatch, RootState } from "@redux/store";
import httpNoneSecure from "./HttpServiceNoneSecure";

export const getLanguageTranslationByLcid = createAsyncThunk<
  ResponseResultBase<ResponseLanguageTranslationModel> | null,
  RequestLanguageTranslationModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "translationTool/GetLanguageTranslationByLcid",
  async (req: RequestLanguageTranslationModel, thunkApi) => {
    try {
      const response = await httpNoneSecure.get<ResponseResultBase<ResponseLanguageTranslationModel>>(
        `/api/v1/translationTool/GetAllLocalizedTranslationsByLcid?lcid=${req.lcid}&appId=${req.appId}`
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);