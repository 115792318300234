import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "@translationsContexts/i18nContext";
import { Grid, Modal } from "semantic-ui-react";
import Styles from "./RDAlert.module.scss";
import Boschicon from "../BoschIcon/Boschicon";
import RDButton from "./RDButton";
import CustomLoader from "../loading/CustomLoader";

interface RDAlertProps {
  type: "warning" | "success" | "error" | "info";
  isOpen: boolean;
  title?: string;
  messages: string[];
  cancelButtonText?: string;
  acceptButtonText: string;
  isAcceptLoading?: boolean;
  onCancel?: Function;
  onAccept: Function;
}

const RDAlert: FC<RDAlertProps> = ({
  type,
  isOpen,
  title,
  messages,
  isAcceptLoading,
  acceptButtonText,
  onAccept,
  cancelButtonText,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [displayMessages, setDisplayMessages] = useState<string[]>([]);

  useEffect(() => {
    if (!!messages) {
      if (typeof messages === "string") {
        setDisplayMessages([messages]);
      } else {
        setDisplayMessages([...messages]);
      }
    }
  }, [messages]);

  const handleAccept = () => {
    onAccept();
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Fragment>
      <Modal
        dimmer={{
          className: `${Styles.wrapper_modal} ${
            type === "warning" && Styles.warning
          } ${type === "error" && Styles.error} ${
            type === "success" && Styles.success
          } ${type === "info" && Styles.info}`,
        }}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={isOpen}
        size={"small"}
        className={Styles.modal}
      >
        <Modal.Header className={Styles.header}>
          {type === "warning" && (
            <>
              <Boschicon name="bosch-ic-alert-warning" />
              <span className={Styles.title}>{t("Popup_Title_Warning")}</span>
            </>
          )}
          {type === "error" && (
            <>
              <Boschicon name="bosch-ic-alert-error" />
              <span className={Styles.title}>{t("Popup_Title_Error")}</span>
            </>
          )}
          {type === "success" && (
            <>
              <Boschicon name="bosch-ic-alert-success" />
              <span className={Styles.title}>{t("AlertSuccess_Title")}</span>
            </>
          )}

          {type === "info" && (
            <>
              <Boschicon name="alert-info" />
              <span className={Styles.title}>{t("Popup_Title_Info")}</span>
            </>
          )}

          {!!onCancel && (
            <RDButton
              className={Styles.close_button_header}
              onClick={() => {
                if (onCancel) {
                  onCancel();
                }
              }}
            >
              <Boschicon name="bosch-close" />
            </RDButton>
          )}
        </Modal.Header>
        <Modal.Content className={Styles.content}>
          <Grid className={`${Styles.ui} ${Styles.grid}`}>
            {!!title && (
              <Grid.Row className={`${Styles.row} ${Styles.content_header}`}>
                {title}
              </Grid.Row>
            )}
            {displayMessages.map((message: string, index) => {
              return (
                <Grid.Row className={Styles.row} key={index}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: message,
                    }}
                  ></span>
                </Grid.Row>
              );
            })}
          </Grid>
        </Modal.Content>
        <Modal.Actions className={Styles.actions}>
          <RDButton onClick={handleAccept}>{acceptButtonText}</RDButton>
          {!!onCancel && (
            <RDButton variant="secondary" onClick={handleCancel}>
              {cancelButtonText}
            </RDButton>
          )}
        </Modal.Actions>
      </Modal>

      {isAcceptLoading && <CustomLoader enable />}
    </Fragment>
  );
};

export default RDAlert;
