import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import AdsApp from "./ads/AdsApp";
import { isAdsApp } from "./utils/routing.util";
import I18nProvider from "./translations/providers/I18n.provider";

let persistor = persistStore(store);

const getAppVersion = (): JSX.Element => {
  if (isAdsApp()) return <AdsApp />;

  return <App />;
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nProvider>
        <BrowserRouter>{getAppVersion()}</BrowserRouter>
      </I18nProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
