import { useTranslation } from "@translationsContexts/i18nContext";
import { useSelector } from "react-redux";
import { selectCountryCode } from "../../redux/auth";
import { Footer as FrokFooter, Link } from "@bosch/react-frok";
import style from "./Footer.module.scss";

const Footer = () => {
  const { t } = useTranslation();
  const countryCode = useSelector(selectCountryCode);

  return (
    <FrokFooter
      className={style.footer}
      bottomLinks={[
        <Link
          key="0"
          href="/corporate-information"
          label={t("FooterAdditionalLinks_Navigation_CorporateInformation")}
          level="integrated"
          target="_self"
        />,
        <Link
          key="1"
          href="/legal-notice"
          label={t("FooterAdditionalLinks_Navigation_LegalNotice")}
          level="integrated"
          target="_self"
        />,
        <Link
          key="2"
          href="/data-protection-notice"
          label={t("FooterAdditionalLinks_Navigation_DataProtectionNotice")}
          level="integrated"
          target="_self"
        />,
        <Link
          key="3"
          href={`/term-condition/${countryCode.trim().toLowerCase()}`}
          label={t("BreadScrumb_TermAndCondition")}
          level="integrated"
          target="_self"
        />,
        <div
          className="a-link a-link--integrated"
          dangerouslySetInnerHTML={{
            __html: `<a href="#" onclick="showDockPrivacySettings(); return false;">
                  ${t("FooterAdditionalLinks_Navigation_PrivacySettings")}
                </a>`,
          }}
        ></div>,
        <Link
          key="4"
          href="/software-licenses"
          label={t("FooterAdditionalLinks_Navigation_OSSLicenses")}
          level="integrated"
          target="_self"
        />,
        <div className="a-link a-link--integrated" style={{ color: "black" }}>
          {t("Common_Version_Text", {
            version: process.env.REACT_APP_VERSION || "",
          }).replace("{{version}}", process.env.REACT_APP_VERSION || "")}
        </div>,
      ]}
      copyright={t("FooterAdditionalLinks_Copyright")}
      variation="minimal"
    />
  );
};

export default Footer;
