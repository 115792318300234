import style from "./RDLanguageSelection.module.scss";
import { ReactComponent as GlobeLanguageIcon } from "../../../assets/svg/globalLanguage.svg";
import { ReactComponent as CloseIcon } from "../../../assets/svg/close.svg";
import { useEffect, useRef, useState } from "react";
import RDText from "../RDText";
import { useTranslation } from "@translationsContexts/i18nContext";
import RDDropdown from "../RDDropdown";
import { Link } from "react-router-dom";
import { LanguageOption } from "../../../models/pageConfiguration/LanguageModel";
import { Button, Icon } from "semantic-ui-react";
import Boschicon from "../../BoschIcon/Boschicon";

type Props = {
  currentLanguage: string;
  languages: LanguageOption[];
  onChangeLanguage: (newLangValue: string) => void;
  className?: string;
};

const RDLanguageSelection = ({
  currentLanguage,
  languages,
  onChangeLanguage,
  className,
}: Props) => {
  const { t } = useTranslation();
  const [isShowLangSelectionBox, setShowLangSelectionBox] = useState(false);
  const langSelectionBoxRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutOfLangSelectionBox);

    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutOfLangSelectionBox
      );
    };
  }, []);

  const handleClickOutOfLangSelectionBox = (event: MouseEvent) => {
    if (
      langSelectionBoxRef.current &&
      !langSelectionBoxRef.current.contains(event.target as Node)
    )
      setShowLangSelectionBox(false);
  };

  const toggleLangSelectionBox = (state?: boolean) => {
    setShowLangSelectionBox(state || !isShowLangSelectionBox);
  };

  const getAllLanguageSelectionClassNames = () => {
    const allClassNames: string[] = [style.rd_lang_selection_container];

    if (!!className) allClassNames.push(className);

    return allClassNames.join(" ");
  };

  return (
    <div className={getAllLanguageSelectionClassNames()}>
      <Button
        basic
        className={style.lang_button}
        onClick={() => toggleLangSelectionBox()}
        icon={
          <Icon>
            <GlobeLanguageIcon className={style.lang_icon} />
          </Icon>
        }
      />
      {isShowLangSelectionBox ? (
        <div ref={langSelectionBoxRef} className={style.lang_selection_box}>
          <div className={style.lang_selection_triangle}></div>
          <div className={style.lang_selection_wrapper}>
            <div className={style.lang_selection_toolbar}>
              <CloseIcon
                onClick={() => toggleLangSelectionBox(false)}
                className={style.close_lang_selection_icon}
              />
            </div>
            <div className={style.lang_selection_label}>
              <RDText
                size="s"
                isFitContent
                content={t("LanguageSelection_SelectALanguage")}
              />
            </div>
            <RDDropdown
              value={currentLanguage}
              defaultValue={currentLanguage}
              options={languages.map(({ key, text, value }) => {
                return {
                  key,
                  text,
                  value,
                };
              })}
              onChange={(_, data: any) => {
                toggleLangSelectionBox(false);
                onChangeLanguage((data as LanguageOption).value);
              }}
            />
            <Link
              to="/regions"
              className={style.lang_selection_find_local_website}
            >
              <span>{t("LanguageSelection_SelectYourCountry")}</span>
              <Boschicon name="bosch-forward-right" />
            </Link>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default RDLanguageSelection;
